import { Flex } from 'components/Flex';
import { Text, Link, Divider } from '../rateProStyles';
import { styled } from 'shamrock-clover-ui';
import { useFeatureFlags } from 'utils/featureFlagContext';

const LinksContainer = styled.div`
  margin-top: 22px;
`;

const FooterLinks = () => {
  const { isGreenscreenEnabled } = useFeatureFlags();
  return (
    <LinksContainer>
      <Flex>
        <Text margin="0px 10px 0px 0px">External Links:</Text>

        <Text alignItems="center">
          {isGreenscreenEnabled && (
            <>
              <Link
                href="https://app.greenscreens.ai/rates"
                target="_blank"
                rel="noopener noreferrer"
              >
                Greenscreens
              </Link>
              <Divider />
            </>
          )}
          <Link
            href="https://rateview.dat.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            DAT
          </Link>
          <Divider />
          <Link
            href="https://mpact.mcleodsoftware.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            MPACT
          </Link>
          <Divider />
          <Link
            href="https://app.truckstop.com/RateMate/Default.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Truckstop
          </Link>
        </Text>
      </Flex>
    </LinksContainer>
  );
};

export default FooterLinks;
