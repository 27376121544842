import RateProLegacy from './RateProLegacy';
import RateProWithGreenscreen from './RatePro';
import { FunctionComponent } from 'react';
import { useFeatureFlags } from '../../utils/featureFlagContext';

const RatePro: FunctionComponent = () => {
  const { isGreenscreenEnabled } = useFeatureFlags();
  return isGreenscreenEnabled ? <RateProWithGreenscreen /> : <RateProLegacy />;
};
export default RatePro;
