import { axiosBaseQuery } from 'utils/rtk-query';
import {
  CreditCheckRequestParams,
  DnbCompany,
  DNBCreditResponse,
} from './creditRequestTypes';
import { createApi } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';

const CREDIT_CHECK_HISTORY_LOCAL_STORAGE_KEY = 'creditCheckHistory';

export const dnbCreditApi = createApi({
  reducerPath: 'dnbCreditApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_CREDIT_SERVICE_BASE_URL!,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getCompanies: builder.query<
      DnbCompany[],
      CreditCheckRequestParams | undefined
    >({
      query: (requestParams) => {
        if (!requestParams) {
          throw new Error('requestParams is required');
        }
        return {
          url: `/creditRequest/getCompanies?q=${encodeURIComponent(requestParams.companyName)}&city=${requestParams.selectedCity}&state=${requestParams.selectedState}&country=${requestParams.countryCode}`,
          method: 'GET',
        };
      },
    }),
    getCredit: builder.query<DNBCreditResponse, string>({
      query: (dunsNumber) => {
        if (!dunsNumber) {
          throw new Error('dunsNumber is required');
        }

        return {
          url: `creditRequest/getCredit?dunsNumber=${dunsNumber}`,
          method: 'GET',
        };
      },
    }),
  }),
});

const initialCreditCheckHistoryState: {
  dunsNumbers: string[];
} = {
  dunsNumbers: JSON.parse(
    localStorage.getItem(CREDIT_CHECK_HISTORY_LOCAL_STORAGE_KEY) ?? '[]',
  ),
};

export const creditCheckHistorySlice = createSlice({
  name: 'creditCheckHistory',
  initialState: initialCreditCheckHistoryState,
  reducers: {
    addCompanyToCreditCheckHistory: (state, dunsNumber) => {
      if (state.dunsNumbers.includes(dunsNumber.payload)) {
        return;
      }
      const updatedCreditCheckHistory = [
        ...state.dunsNumbers,
        dunsNumber.payload,
      ];
      state.dunsNumbers = updatedCreditCheckHistory;
      localStorage.setItem(
        CREDIT_CHECK_HISTORY_LOCAL_STORAGE_KEY,
        JSON.stringify(updatedCreditCheckHistory),
      );
    },
  },
});

export const { useGetCompaniesQuery, useLazyGetCreditQuery } = dnbCreditApi;

export const { addCompanyToCreditCheckHistory } =
  creditCheckHistorySlice.actions;

export default dnbCreditApi;
