import { ABLY_AUTH_SERVICE_URL_V2 } from 'utils/constants';
import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';
import * as Ably from 'ably';

export class AblyAuthService extends Service {
  constructor() {
    super(ABLY_AUTH_SERVICE_URL_V2 as string);
  }

  async getAblyWildcardToken(
    channel: string,
  ): Promise<Ably.Types.TokenDetails> {
    const response: AxiosResponse<Ably.Types.TokenDetails> = await this.get(
      `/${channel}`,
    );
    return response.data;
  }
}

export const ablyAuthService = new AblyAuthService();
