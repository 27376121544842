import React, { useEffect, useState } from 'react';
import Autosuggest, { BlurEvent } from 'react-autosuggest';
import { getPlaceSuggestions } from '../../../utils/googleMaps';
import { Chip, withStyles } from '@material-ui/core';
import { styled } from 'shamrock-clover-ui';

export type LocationSearchResult = {
  city: string;
  state: string;
  postal_code: string;
  name: string;
  placeId?: string;
};

interface LocationPickerProps {
  placeHolderText: string;
  placeType: PlaceTypes;
  city?: string;
  state?: string;
  postalCode?: string;
  onChange: (city: string, state: string, postalCode?: string) => void;
  inputWidth?: string;
  isLocationSelected?: boolean;
  setNeedsToSelectLocation?: (value: boolean) => void;
  setNeedsToSelectCreditLocation?: (value: boolean) => void;
}

interface InputProps<T = string> {
  placeholder: string;
  value: T;
  onChange: (
    event: React.FormEvent, // Matching the given type signature
    params: { newValue: T },
  ) => void;
  onBlur: (
    event: React.FocusEvent,
    params?: BlurEvent<LocationSearchResult>,
  ) => void;
}

export enum PlaceTypes {
  POSTAL_CODE = 'postal_code',
  CITY_STATE = 'locality',
  ALL = 'all',
}

const LocationInputContainer = styled.div<{ inputWidth?: string }>`
  .react-autosuggest__container {
    width: 125px;
  }

  .react-autosuggest__input {
    border-width: 0px;
    outline: 0;
    width: ${(props) => props.inputWidth || '175px'};
    background-color: white;
    height: 34px;
    font-size: 14px;
    font-family: Proxima Nova;

    ::placeholder {
      color: #333333;
    }
  }

  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 145px;
    padding-left: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #d8d8d8;
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledChip = withStyles({
  root: {
    marginRight: '5px',
    zIndex: 1,
    fontFamily: 'Proxima Nova',
    fontSize: '14px',
    maxWidth: '175px',
  },
})(Chip);

export const LocationPicker: React.FC<
  React.PropsWithChildren<LocationPickerProps>
> = ({
  placeHolderText,
  placeType,
  city,
  state,
  onChange,
  postalCode,
  isLocationSelected,
  inputWidth,
  setNeedsToSelectLocation,
  setNeedsToSelectCreditLocation,
}) => {
  const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<LocationSearchResult[]>([]);
  const initialLocation: Partial<LocationSearchResult> = {
    ...((city && { city }) || {}),
    ...((state && { state }) || {}),
    ...((postalCode && { postal_code: postalCode }) || {}),
    ...((city && state && { name: `${city}, ${state}` }) || {}),
  };
  const [selectedLocation, setSelectedLocation] =
    useState<Partial<LocationSearchResult>>(initialLocation);

  useEffect(() => {
    if (!isLocationSelected) {
      onDelete();
    }
    //eslint-disable-next-line
  }, [isLocationSelected]);

  const getSuggestionValue = (suggestion: LocationSearchResult): string => {
    return suggestion.name;
  };

  const renderSuggestion = (suggestion: LocationSearchResult): JSX.Element => {
    return <div>{suggestion.name}</div>;
  };

  function getDesiredPlaceTypes(placeType: PlaceTypes): PlaceTypes[] {
    switch (placeType) {
      case PlaceTypes.ALL:
        return [PlaceTypes.CITY_STATE, PlaceTypes.POSTAL_CODE];
      case PlaceTypes.CITY_STATE:
      case PlaceTypes.POSTAL_CODE:
        return [placeType];
      default:
        return [];
    }
  }

  const convertPlacesToSearchResults = (
    places: google.maps.places.AutocompletePrediction[],
  ): LocationSearchResult[] => {
    const desiredPlaceTypes: PlaceTypes[] = getDesiredPlaceTypes(placeType);

    // This where the placetype filters some results.
    const searchResults = places.filter((place) => {
      // filter out places that do not have a type that matches one of the desired types
      return place.types.some((placeTypes) =>
        desiredPlaceTypes.includes(placeTypes as PlaceTypes),
      );
    });

    return searchResults.map((place) => {
      let currentPlaceType;

      if (place.types.includes(PlaceTypes.CITY_STATE)) {
        currentPlaceType = PlaceTypes.CITY_STATE;
      } else if (place.types.includes(PlaceTypes.POSTAL_CODE)) {
        currentPlaceType = PlaceTypes.POSTAL_CODE;
      } else {
        currentPlaceType = place.types[0] as PlaceTypes;
      }

      const currentCity = place.terms[0]?.value;
      const currentState = place.terms[1]?.value;
      const currentPostalCode = place.terms[2]?.value;

      switch (currentPlaceType) {
        case PlaceTypes.CITY_STATE:
          return {
            city: currentCity,
            state: currentState,
            postal_code: '',
            name: `${currentCity}, ${currentState}`,
            placeId: place.place_id,
          };
        case PlaceTypes.POSTAL_CODE:
          return {
            city: currentCity,
            state: currentState,
            postal_code: place.terms[2]?.value,
            name: `${currentCity}, ${currentState}, ${currentPostalCode}`,
            placeId: place.place_id,
          };
        default:
          throw new Error(
            `Unexpected place type: ${currentPlaceType} for place: ${place}`,
          );
      }
    });
  };

  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    const places: google.maps.places.AutocompletePrediction[] =
      await getPlaceSuggestions(value);
    if (places.length === 0) {
      return;
    }

    setSuggestions(convertPlacesToSearchResults(places));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleInputChange = (
    event: React.FormEvent,
    params: { newValue: string },
  ) => {
    setValue(params.newValue);
  };

  const onBlur = (
    event: React.FocusEvent,
    params: { highlightedSuggestion: LocationSearchResult } | undefined,
  ) => {
    if (params?.highlightedSuggestion) {
      const { highlightedSuggestion } = params;
      setSelectedLocation(highlightedSuggestion);
      onChange(
        highlightedSuggestion.city,
        highlightedSuggestion.state,
        highlightedSuggestion.postal_code,
      );
    } else {
      setNeedsToSelectLocation?.(true);
      setNeedsToSelectCreditLocation?.(true);
    }
  };

  const inputProps: InputProps = {
    placeholder: placeHolderText || '',
    value: value,
    onBlur: onBlur,
    onChange: handleInputChange,
    ...(placeType === PlaceTypes.POSTAL_CODE
      ? { maxLength: 5, type: 'number' }
      : {}),
  };

  const onSuggestionSelected = (
    _event: any,
    { suggestion }: { suggestion: LocationSearchResult },
  ) => {
    setSelectedLocation(suggestion);
    setNeedsToSelectLocation?.(false);
    setNeedsToSelectCreditLocation?.(false);
    // call method passed down here
    onChange(suggestion.city, suggestion.state, suggestion.postal_code);
  };

  const onDelete = () => {
    // Set the raw input value to empty string
    setValue('');
    // Clear out any selected location
    setSelectedLocation({});
    onChange('', '', '');
  };

  const renderInputComponent = (inputProps: any) => {
    if (selectedLocation && selectedLocation.city && selectedLocation.state) {
      return (
        <StyledChip
          label={selectedLocation.name}
          key={selectedLocation.name}
          onDelete={() => {
            onDelete();
            setNeedsToSelectCreditLocation?.(true);
          }}
          size="small"
        />
      );
    } else {
      return (
        <div>
          <input {...inputProps} />
        </div>
      );
    }
  };

  return (
    <LocationInputContainer inputWidth={inputWidth}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={renderInputComponent}
        highlightFirstSuggestion={true}
      />
    </LocationInputContainer>
  );
};
