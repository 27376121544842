import {
  LabelText,
  StyledCreditComponentRow,
  StyledCreditToolbarContainer,
  StyledInput,
  StyledInputPillContainer,
  StyledButton,
  StyledSearchButton,
} from '../creditRequestStyles';
import { LocationPicker } from 'features/search/location/LocationPicker';
import {
  StyledInputContainer,
  StyledFormControl,
} from 'features/search/location/PickerStyles';
import { PlaceTypes } from 'features/search/location/LocationPicker';
import CompanyNotFound from './CompanyNotFoundButton';
import { StyledIcon } from 'features/carrierCapacity/CarrierCapacityStyles';
import { FC, useState } from 'react';
import { Flex } from 'components/Flex';
import { CreditCheckRequestParams } from '../creditRequestTypes';
import { Icon } from 'shamrock-clover-ui';
import {
  getCountryCodeForStateOrProvince,
  getStateOrProvinceAbbreviation,
} from 'utils/locations/abbreviationUtils';

interface CreditCheckToolbarProps {
  requestParams: CreditCheckRequestParams;
  handleSearch: () => void;
  setRequestParams: (params: CreditCheckRequestParams) => void;
}

const CreditCheckToolbar: FC<CreditCheckToolbarProps> = ({
  requestParams,
  setRequestParams,
  handleSearch,
}) => {
  const [needsToEnterCompanyName, setNeedsToEnterCompanyName] = useState(false);
  const [needsToSelectLocation, setNeedsToSelectLocation] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const { companyName } = requestParams;

  const companyValue = companyName;

  const updateLocation = (city: string, state: string) => {
    const { selectedCity, selectedState, countryCode, ...restRequestParams } =
      requestParams;

    const stateOrProvince =
      state.length > 2 ? getStateOrProvinceAbbreviation(state) : state;
    const country = getCountryCodeForStateOrProvince(stateOrProvince);

    const newParams: CreditCheckRequestParams = {
      ...restRequestParams,
      selectedCity: city,
      selectedState: stateOrProvince,
      countryCode: country,
    };
    setRequestParams(newParams);
    setIsLocationSelected(true);
    setNeedsToSelectLocation(false);
  };

  const updateCompany = (businessName: string) => {
    const { companyName, ...restRequestParams } = requestParams;

    const newParams = {
      ...restRequestParams,
      companyName: businessName,
    };
    setRequestParams(newParams);
    setNeedsToEnterCompanyName(false);
  };

  const handleClearSearch = () => {
    updateCompany('');
    setNeedsToEnterCompanyName(true);
  };

  const handleOnBlur = () => {
    if (companyValue === '') {
      setNeedsToEnterCompanyName(true);
    }
  };

  const handleReset = () => {
    setRequestParams({
      selectedCity: '',
      selectedState: '',
      countryCode: '',
      companyName: '',
    });
    setIsLocationSelected(false);
  };

  const isSearchDisabled = !(
    requestParams.selectedCity &&
    requestParams.selectedState &&
    requestParams.companyName
  );

  const isResetDisabled = !(
    requestParams.selectedCity ||
    requestParams.selectedState ||
    requestParams.companyName
  );

  return (
    <StyledCreditToolbarContainer
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !isSearchDisabled) {
          handleSearch();
        }
      }}
    >
      <StyledCreditComponentRow>
        <Flex
          width="350px"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LabelText>Company Name</LabelText>
          <StyledInputPillContainer
            border={needsToEnterCompanyName}
            width="320px"
          >
            <StyledInput
              type="text"
              placeholder="Name or Url"
              value={companyValue}
              onChange={(e) => updateCompany(e.target.value)}
              onBlur={handleOnBlur}
            />
            {companyValue.length > 0 && (
              <Icon icon="close" size="16" onClick={handleClearSearch} />
            )}
          </StyledInputPillContainer>
        </Flex>
        <Flex
          width="350px"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LabelText>Location</LabelText>
          <StyledInputContainer border={needsToSelectLocation} width="320px">
            <StyledFormControl size="small">
              <LocationPicker
                placeHolderText={'City, State'}
                city={requestParams.selectedCity}
                state={requestParams.selectedState}
                onChange={updateLocation}
                placeType={PlaceTypes.ALL}
                inputWidth="150px"
                isLocationSelected={isLocationSelected}
                setNeedsToSelectCreditLocation={setNeedsToSelectLocation}
              />
            </StyledFormControl>
          </StyledInputContainer>
        </Flex>
        <div>
          <StyledSearchButton
            width="64px"
            height="36px"
            isRounded={true}
            disabled={isSearchDisabled}
            onClick={handleSearch}
          >
            <StyledIcon
              icon="search"
              color="white"
              disabled={isSearchDisabled}
            />
          </StyledSearchButton>
        </div>
        <div>
          <StyledButton
            width="86px"
            height="36px"
            isRounded={true}
            buttonStyle="outlined"
            disabled={isResetDisabled}
            onClick={handleReset}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            textTransform="sentenceCase"
            border={isResetDisabled ? '1px solid #999999' : '1px solid #0091EA'}
          >
            Reset all
          </StyledButton>
        </div>
      </StyledCreditComponentRow>
      <CompanyNotFound />
    </StyledCreditToolbarContainer>
  );
};

export default CreditCheckToolbar;
