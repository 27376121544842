import {
  StyledCheckmark,
  StyledResultText,
  StyledTableContainer,
  StyledTextContainer,
  StyledTableCell,
} from '../creditRequestStyles';
import { SmrTable } from 'shamrock-clover-ui';
import { DnbCompany } from '../creditRequestTypes';
import { Column, Row } from 'components/cloverTable/TableTypes';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../configureStore';

interface CompanyTableProps {
  results: DnbCompany[] | undefined;
  isLoading: boolean;
  setSelectedCreditCheckCompany: Function;
}

const CompanyTable: FC<CompanyTableProps> = ({
  results,
  isLoading,
  setSelectedCreditCheckCompany,
}) => {
  const creditCheckHistory = useSelector(
    (state: RootState) => state.creditCheckHistory.dunsNumbers,
  );

  const handleRowClick = (index: number) => {
    if (!results) {
      return;
    }
    setSelectedCreditCheckCompany(results[index]);
  };

  const tableRows: Row[] =
    isLoading || !results
      ? []
      : results?.map((company: DnbCompany, index: number) => {
          const isHighlighted = creditCheckHistory.includes(
            company.bureauIdentifierNumber,
          );
          return {
            cells: [
              {
                content: (
                  <StyledTableCell
                    isHighlighted={isHighlighted}
                    isFirstTableCellInRow={true}
                  >
                    {company.businessName}
                  </StyledTableCell>
                ),
                value: company.businessName,
              },
              {
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.address.street}
                  </StyledTableCell>
                ),
                value: company.address.street,
              },
              {
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.address.city}
                  </StyledTableCell>
                ),
                value: company.address.city,
              },
              {
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.address.state}
                  </StyledTableCell>
                ),
                value: company.address.state,
              },
              {
                // Only show the first 5 digits of the zip code
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.address.zipCode.slice(0, 5)}
                  </StyledTableCell>
                ),
                value: company.address.zipCode,
              },
              {
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.bureauIdentifierNumber}
                  </StyledTableCell>
                ),
                value: company.bureauIdentifierNumber,
              },
              {
                content: (
                  <StyledTableCell isHighlighted={isHighlighted}>
                    {company.locationIndicator === 'HQ' ? (
                      <StyledCheckmark icon="checkCircleGreen" color="gray3" />
                    ) : (
                      <></>
                    )}
                  </StyledTableCell>
                ),
                value: company.locationIndicator,
              },
            ],
            onClick: () => handleRowClick(index),
          };
        });

  const tableColumns: Column[] = [
    {
      key: 'company',
      label: 'Company',
      isSortable: false,
      width: 32,
    },
    {
      key: 'address',
      label: 'Address',
      isSortable: false,
      width: 17,
    },
    {
      key: 'city',
      label: 'City',
      isSortable: false,
      width: 17,
    },
    {
      key: 'state',
      label: 'State',
      isSortable: false,
      width: 9,
    },
    {
      key: 'zip',
      label: 'Zip',
      isSortable: false,
      width: 7,
    },
    {
      key: 'dunsNumber',
      label: 'DUNS #',
      isSortable: false,
      width: 13,
    },
    {
      key: 'headquarters',
      label: 'HQ',
      isSortable: false,
      alignment: 'left',
      width: 5,
    },
  ];

  return (
    <>
      {(isLoading || (results?.length ?? 0) > 0) && (
        <StyledTextContainer>
          <StyledResultText>{`${isLoading ? '-' : `${results?.length}`} Matching Companies`}</StyledResultText>
        </StyledTextContainer>
      )}
      <StyledTableContainer
        marginTop={`${isLoading || (results?.length ?? 0) > 0 ? '0px' : '10px'}`}
      >
        <SmrTable
          loading={isLoading}
          columns={tableColumns}
          rows={tableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [5, 10, 25, 50, 75, 100],
          }}
          emptyMessage="0 Matching companies"
        />
      </StyledTableContainer>
    </>
  );
};

export default CompanyTable;
