import * as React from 'react';
import {
  CardContainer,
  SplitCardText,
  SplitCardTextLine,
} from './rateAdjustmentStyles';

import {
  StyledMainCard,
  StyledChangeLogHeaderText,
  SplitCardBackgroundContainer,
  SplitCardBackground,
} from './rateChangeLogStyles';
import { AccessorialChangeLog } from './rateAdminTypes';
import { numberToCurrencyString } from 'utils/numberUtils';

interface Props {
  accessorialAdjustment: AccessorialChangeLog;
}

const AccessorialsChangeLogCard: React.FC<React.PropsWithChildren<Props>> = ({
  accessorialAdjustment,
}) => {
  const date = new Date(accessorialAdjustment?.createdAt);

  const dateString =
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear();

  const getStopsText = (stopKey: string | undefined) => {
    switch (stopKey) {
      case 'oneStop':
        return '1 additional stop: ';
      case 'twoStops':
        return '2 additional stops: ';
      case 'threeStops':
        return '3 additional stops: ';
      case 'fourPlusStops':
        return '4+ additional stops: ';
      default:
        return '';
    }
  };

  const getSentenceCase = (value: string) => {
    const spacedStr = value.replace(/([A-Z])/g, ' $1');
    const titleCaseStr = spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);

    return titleCaseStr;
  };

  return (
    <StyledMainCard>
      <CardContainer flexDirection="column">
        <StyledChangeLogHeaderText>
          {accessorialAdjustment.email} <span>{dateString}</span>
        </StyledChangeLogHeaderText>
        {accessorialAdjustment.change.map((change) => {
          if (change.accessorial === 'additionalStops')
            return (
              <SplitCardBackgroundContainer>
                <SplitCardBackground>
                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      ACCESSORIAL:&nbsp;
                    </span>
                    {getSentenceCase(change.accessorial)}
                  </SplitCardText>

                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      PREVIOUS FEE:&nbsp;
                    </span>
                    {typeof change.previousValue !== 'number' &&
                      change.previousValue.map((stopChange) => {
                        return (
                          <SplitCardTextLine>
                            <span style={{ fontWeight: '600' }}>
                              {getStopsText(stopChange.key)}
                            </span>
                            {numberToCurrencyString(stopChange.value)}
                          </SplitCardTextLine>
                        );
                      })}
                  </SplitCardText>
                </SplitCardBackground>
                <SplitCardBackground>
                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      UPDATED FEE:&nbsp;
                    </span>
                    {typeof change.updatedValue !== 'number' &&
                      change.updatedValue.map((stopChange) => {
                        return (
                          <SplitCardTextLine>
                            <span style={{ fontWeight: '600' }}>
                              {getStopsText(stopChange.key)}
                            </span>{' '}
                            {numberToCurrencyString(stopChange.value)}
                          </SplitCardTextLine>
                        );
                      })}
                  </SplitCardText>
                </SplitCardBackground>
              </SplitCardBackgroundContainer>
            );
          else
            return (
              <SplitCardBackgroundContainer>
                <SplitCardBackground>
                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      ACCESSORIAL:&nbsp;
                    </span>
                    {getSentenceCase(change.accessorial)}
                  </SplitCardText>

                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      PREVIOUS FEE:&nbsp;
                    </span>
                    {typeof change.previousValue === 'number' &&
                      numberToCurrencyString(change.previousValue)}
                  </SplitCardText>
                </SplitCardBackground>
                <SplitCardBackground>
                  <SplitCardText>
                    <span style={{ fontWeight: '600' }}>
                      UPDATED FEE:&nbsp;
                    </span>
                    {typeof change.updatedValue === 'number' &&
                      numberToCurrencyString(change.updatedValue)}
                  </SplitCardText>
                </SplitCardBackground>
              </SplitCardBackgroundContainer>
            );
        })}
      </CardContainer>
    </StyledMainCard>
  );
};
export default AccessorialsChangeLogCard;
