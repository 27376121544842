import React from 'react';
import { styled } from 'shamrock-clover-ui';
import { Flex } from 'components/Flex';

interface Props {
  primaryTitle?: string;
  secondaryTitle?: string;
  rightComponent?: React.ReactNode;
  children?: React.ReactNode;
  contentHeight: string;
  contentPadding?: string;
  legacyCard?: boolean;
}

const Container = styled.div`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
`;

const TitleContainer = styled(Flex)<{ isLegacyCard?: boolean }>`
  height: 16px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  padding: ${({ isLegacyCard }) => (isLegacyCard ? '16px' : '6px')};
`;

const PrimaryText = styled.span`
  color: ${({ theme }) => theme.colors.gray[70]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
`;

const SecondaryText = styled.span`
  color: ${({ theme }) => theme.colors.gray[70]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const ContentContainer = styled.div<{ height: string; padding?: string }>`
  height: ${({ height }) => height};
  padding: ${({ padding }) => (padding ? padding : '16px')};
`;

const Card = ({
  primaryTitle,
  secondaryTitle,
  rightComponent,
  children,
  contentHeight,
  contentPadding,
  legacyCard,
}: Props) => {
  return (
    <Container>
      <TitleContainer
        alignItems="center"
        justifyContent="space-between"
        isLegacyCard={legacyCard}
      >
        <span>
          {primaryTitle && <PrimaryText>{primaryTitle}</PrimaryText>}{' '}
          {secondaryTitle && <SecondaryText>{secondaryTitle}</SecondaryText>}
        </span>
        <div>{rightComponent}</div>
      </TitleContainer>
      <ContentContainer height={contentHeight} padding={contentPadding}>
        {children}
      </ContentContainer>
    </Container>
  );
};

export default Card;
