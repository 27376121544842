import styled from 'styled-components';

export const StyledDialogContainer = styled.div`
  div[role='document'] {
    margin-bottom: 0px;
  }
`;

export const StyledConfirmCreditCheckContainer = styled.div`
  width: 550px;
`;

export const StyledConfirmCreditCheckButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledConfirmCreditCheckTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
`;

export const StyledConfirmCreditCheckMainText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  padding: 32px 0;
`;

export const StyledLoadingContainer = styled.div`
  width: 550px;
  height: 315px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const StyledLoadingText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
`;

export const StyledCreditCheckContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
`;

export const StyledCreditCheckTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0em;
`;

export const StyledCreditCheckCompanyName = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0em;
  margin-top: 20px;
`;

export const StyledCreditCheckStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const StyledCreditCheckStatus = styled.div<{ status?: string }>`
  font-weight: 600;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0em;
  color: ${(props) => {
    switch (props?.status) {
      case 'Approved':
        return '#457528';
      case 'Tentative':
        return '#C28100';
      case 'Declined':
        return '#EF3824';
      default:
        return '#EF3824';
    }
  }};
`;

export const StyledCreditCheckRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  line-height: 100%;
`;

export const StyledCreditCheckLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  width: 150px;
`;

export const StyledCreditCheckValue = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const StyledCreditCheckStatusText = styled.div<{ status?: string }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0em;
  color: ${(props) => {
    switch (props?.status) {
      case 'Approved':
        return '#457528';
      case 'Tentative':
        return '#C28100';
      case 'Declined':
        return '#EF3824';
      default:
        return '#EF3824';
    }
  }};
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const StyledCreditCheckHr = styled.hr`
  width: 100%;
  color: #bbbbbb;
  margin-bottom: 15px;
`;
