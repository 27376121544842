import { CurrentUser } from './authUtils';
import Service from 'utils/Service';
import { AxiosResponse } from 'axios';
const RTSPRO_USERS_SERVICE_URL =
  process.env.REACT_APP_RTSPRO_USERS_SERVICE_URL || '';

export interface User {
  _id: string;
  firstname: string;
  lastname: string;
  permissions: { name: string; status: string }[];
  user_type: string;
  status: string;
}
export class RtsProUsersService extends Service {
  constructor() {
    super(RTSPRO_USERS_SERVICE_URL);
  }

  async getUser(email: string): Promise<CurrentUser> {
    let url = '/';
    const body = {
      query: `query user($email: String) {
      user(email: $email) {
        _id
        firstname
        lastname
        email
        company
        user_type
        status
        permissions { name, status }
        roles
        user_type
        mcleod_customer_id
        bridge_id
        mc_number
        }
      }`,
      variables: {
        email,
      },
    };

    const response: AxiosResponse = await this.post(url, body);
    return response.data.user;
  }
}

export const usersService = new RtsProUsersService();
