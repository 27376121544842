import {
  RateAdjustmentBody,
  RateAdjustmentApiResult,
  RateAdjustmentReasonBody,
  RateAdjustmentReasonApiResult,
  AccessorialFeesApiResult,
  AccessorialFeesBody,
  AccessorialFeesPostResponse,
} from './rateAdminTypes';
import {
  API_BASE_URL_V2,
  REACT_APP_RATE_SERVICE_BASE_URL,
} from 'utils/constants';
import { AxiosResponse } from 'axios';
import Service from 'utils/Service';

class RateAdminService extends Service {
  constructor() {
    super(API_BASE_URL_V2 as string);
  }
  async getRateAdminAdjustment() {
    const response: AxiosResponse<RateAdjustmentApiResult[]> =
      await this.get(`/rate/adjustment`);
    return response.data;
  }

  async createRateAdminAdjustment(body: RateAdjustmentBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<RateAdjustmentApiResult> = await this.put(
      `/rate/adjustment`,
      body,
    );
    return response.data;
  }

  async getRateAdminAdjustmentReasons() {
    const response: AxiosResponse<RateAdjustmentReasonApiResult[]> =
      await this.get(`/rate/reason`);
    return response.data;
  }

  async createRateAdminAdjustmentReason(
    body: RateAdjustmentReasonBody | undefined,
  ) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<RateAdjustmentReasonApiResult> =
      await this.put(`/rate/reason`, body);
    return response.data;
  }
}

class RateService extends Service {
  constructor() {
    super(REACT_APP_RATE_SERVICE_BASE_URL as string);
  }

  async getAccessorialFees() {
    const response: AxiosResponse<AccessorialFeesApiResult[]> = await this.get(
      `/rates/accessorialFees`,
    );
    return response.data;
  }

  async createAccessorialFees(body: AccessorialFeesBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<AccessorialFeesPostResponse> =
      await this.post(`/rates/accessorialFees`, body);
    return response.data;
  }
}

export const rateAdminService = new RateAdminService();
export const rateService = new RateService();
