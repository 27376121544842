import React, { FC } from 'react';
// Import Table Styles
import {
  StyledTableHeaderContainer,
  StyledTableHeaderColumn,
  StyledTableHeaderColumnLabel,
  StyledTableHeaderColumnLabelIcon,
} from './TableHeader.styles';
// Import Table Types
import { Column, SortOrder } from './TableTypes';
// Imp0ort Components
import { Icon } from 'shamrock-clover-ui';

interface HeaderProps {
  columns: Column[];
  sortColumn?: (columnIndex: number, direction: SortOrder) => void;
  sortedColumnIndex?: number;
  sortDirection?: SortOrder;
  isStatic?: boolean;
}

const StyledTableHeader: FC<React.PropsWithChildren<HeaderProps>> = ({
  columns,
  sortColumn,
  sortedColumnIndex,
  sortDirection,
  isStatic,
}) => {
  const handleColumnClick = (columnIndex: number) => {
    const direction =
      sortedColumnIndex !== columnIndex
        ? 'desc'
        : sortDirection === 'desc'
          ? 'asc'
          : 'desc';
    sortColumn?.(columnIndex, direction);
  };
  return (
    <StyledTableHeaderContainer isStatic={isStatic}>
      {columns.map((column, index) => (
        <StyledTableHeaderColumn key={index} width={column.width}>
          <StyledTableHeaderColumnLabel
            isStatic={isStatic}
            columnIndex={index}
            alignment={column.alignment}
            isSortable={column.isSortable}
            sortedColumnIndex={sortedColumnIndex}
            onClick={() => column.isSortable && handleColumnClick(index)}
          >
            {column.label}
            {column.isSortable && (
              <StyledTableHeaderColumnLabelIcon
                isActive={Boolean(sortedColumnIndex === index)}
                sortDirection={sortDirection}
              >
                <Icon
                  key={`TableHeaderColumnLabel${index}_arrow_icon`}
                  size={'10'}
                  data-testid={`TableHeaderColumnLabel${index}_arrow_icon`}
                  color={index === sortedColumnIndex ? 'blue' : 'gray'}
                  icon={'arrowDown'}
                />
              </StyledTableHeaderColumnLabelIcon>
            )}
          </StyledTableHeaderColumnLabel>
        </StyledTableHeaderColumn>
      ))}
    </StyledTableHeaderContainer>
  );
};

export default StyledTableHeader;
