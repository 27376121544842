import { Flex } from 'components/Flex';
import {
  LargeText,
  MediumText,
  SmallText,
  ConfidenceScore,
} from './rateProStyles';

type Props = {
  targetBuyRate: number;
  lowBuyRate: number;
  highBuyRate: number;
  distance: number;
  confidenceLevel: number;
};

export const formatCurrency = (num: number, fractionDigits = 2): string => {
  try {
    if ((!num || isNaN(num)) && num !== 0) {
      return '';
    } else if (num === 0) {
      return '$0.00';
    }

    const parsedString = num.toFixed(fractionDigits);

    if (fractionDigits === 0) {
      return `$${parsedString.replace(/(\d)(?=(\d{3}))/g, '$1,')}`;
    }
    return `$${parsedString.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
  } catch (e) {
    return '';
  }
};
export const GreenscreenDisplay = ({
  targetBuyRate,
  lowBuyRate,
  highBuyRate,
  distance,
  confidenceLevel,
}: Props) => {
  return (
    <div>
      <Flex width="100%" justifyContent="space-between">
        <Flex>
          <LargeText>{formatCurrency(targetBuyRate * distance, 0)}</LargeText>
          <MediumText margin="18px 0px 0px 5px" bold={true}>
            BUY
          </MediumText>
        </Flex>
        <MediumText margin="18px 0px 0px 0px" bold={true}>
          {formatCurrency(targetBuyRate)} RPM
        </MediumText>
      </Flex>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        margin="-5px 0px 0px 0px"
      >
        <MediumText bold={false}>
          {formatCurrency(lowBuyRate * distance, 0)}
          {' - '}
          {formatCurrency(highBuyRate * distance, 0)}
        </MediumText>
        <SmallText>
          {formatCurrency(lowBuyRate)}
          {' - '}
          {formatCurrency(highBuyRate)}
        </SmallText>
      </Flex>
      <Flex margin="14px 0px 0px 0px">
        <ConfidenceScore>
          <div>
            Confidence score: {confidenceLevel}
            <span style={{ color: '#999999' }}> of 100</span>
          </div>
        </ConfidenceScore>
      </Flex>
    </div>
  );
};

export default GreenscreenDisplay;
