import { RATE_SERVICE_BASE_URL } from 'utils/constants';
import { MpactApiRequest, MpactApiResult } from './mpactTypes';
import Service from 'utils/Service';
import { AxiosResponse } from 'axios';

class MpactService extends Service {
  constructor() {
    super(RATE_SERVICE_BASE_URL as string);
  }

  async getMpactAPIData(requestData: MpactApiRequest): Promise<MpactApiResult> {
    const response: AxiosResponse<MpactApiResult> = await this.get(
      '/mpact/rates',
      { params: requestData },
    );
    return response.data;
  }
}

export const mpactService = new MpactService();
