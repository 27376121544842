import { Quote, QuoteData, QuoteHistoryItem } from './quoteTypes';
import { API_BASE_URL } from 'utils/constants';

import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

export class QuoteService extends Service {
  constructor() {
    super(API_BASE_URL as string);
  }
  async getQuotes(queryParams: {
    status?: string | {};
    adminAssigned?: string;
  }): Promise<Quote[]> {
    const response: AxiosResponse<Quote[]> = await this.get(
      `/admin/v2/quotes`,
      { params: queryParams },
    );
    return response.data;
  }

  async cancelQuote(body: {
    orderId: string;
    userId: string;
    comment?: string;
  }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/admin/cancelQuote',
      body,
    );
    return response.data;
  }

  async acceptQuote(body: {
    userId: string;
    orderId: string;
  }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/admin/acceptQuote',
      body,
    );
    return response.data;
  }

  async counterQuote(body: {
    userId: string;
    orderId: string;
    amount: number;
    comment?: string;
  }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/admin/counterQuote',
      body,
    );
    return response.data;
  }

  async declineQuote(body: {
    userId: string;
    orderId: string;
    doNotSendNotification: boolean;
    comment?: string;
  }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/admin/declineQuote',
      body,
    );
    return response.data;
  }

  async markAsComplete(body: {
    userId: string;
    orderId: string;
  }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/admin/v2/complete-quote',
      body,
    );
    return response.data;
  }

  async discardQuote(body: { orderId: string }): Promise<QuoteHistoryItem> {
    const response: AxiosResponse<QuoteHistoryItem> = await this.post(
      '/discardQuote',
      body,
    );
    return response.data;
  }

  async setAdminQuote(body: { orderId: string }): Promise<QuoteData[]> {
    const response: AxiosResponse<QuoteData[]> = await this.put(
      '/admin/v2/assing-admin-user',
      body,
    );
    return response.data;
  }
}

export const quoteService = new QuoteService();
