import { useQuery } from 'react-query';
import { rateProService, rateService } from './ratesHttp';
import {
  RateCastApiResultsToDatRate,
  RateQueryToRateBody,
  RateViewApiResultToDatRate,
  SonarScoreApiResultToSonarData,
  TrailerType,
  NewRateQuery,
  HubtekRequestBody,
  HubtekRequestQuery,
  RateQueryToGreenscreenBody,
} from './ratesTypes';
import * as FirebaseUtils from '../../utils/firebaseUtils';
import { mpactService } from './mpact/mpactHttp';
import { MpactApiRequest } from './mpact/mpactTypes';
import { getStateOrProvinceAbbreviation } from 'utils/locations/abbreviationUtils';

let isRateViewFirebaseReady: boolean;
let isGreenscreenNetworkFirebaseReady: boolean;
let isGreenscreenRTFirebaseReady: boolean;

export const useMpactData = (searchQuery: NewRateQuery | undefined) => {
  const MpactDataResults = useQuery(
    ['MpactData', JSON.stringify(searchQuery)],
    async () => {
      const mpactRequestData: MpactApiRequest = {
        pickupCity: searchQuery?.pickupCity,
        pickupState: searchQuery?.pickupState,
        pickupPostalCode: searchQuery?.pickupPostalCode,
        deliveryCity: searchQuery?.deliveryCity,
        deliveryState: searchQuery?.deliveryState,
        deliveryPostalCode: searchQuery?.deliveryPostalCode,
        trailerType: searchQuery?.trailerType,
      };

      return await mpactService.getMpactAPIData(mpactRequestData);
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );
  return {
    MpactDataResults: {
      ...MpactDataResults,
      data: MpactDataResults.data,
      error: MpactDataResults.error as Error,
    },
  };
};

export const useHubtekData = (
  searchQuery: NewRateQuery | undefined,
  sellRateSearchQuery: HubtekRequestQuery | undefined,
) => {
  const HubtekDataResults = useQuery(
    ['HubtekData', JSON.stringify({ ...searchQuery, ...sellRateSearchQuery })],
    async () => {
      if (searchQuery) {
        if (searchQuery.pickupState && searchQuery.pickupState.length > 2) {
          searchQuery.pickupState = getStateOrProvinceAbbreviation(
            searchQuery.pickupState,
          );
        }

        if (searchQuery.deliveryState && searchQuery.deliveryState.length > 2) {
          searchQuery.deliveryState = getStateOrProvinceAbbreviation(
            searchQuery.deliveryState,
          );
        }
      }

      const hubtekRequestData: HubtekRequestBody = {
        origin_city: searchQuery?.pickupCity,
        origin_state: searchQuery?.pickupState,
        delivery_city: searchQuery?.deliveryCity,
        delivery_state: searchQuery?.deliveryState,
        trailer_type: searchQuery?.trailerType || 'Van',
        identifiers: [
          sellRateSearchQuery?.tolerance
            ? sellRateSearchQuery.tolerance
            : 'rateProModerate',
        ],
        extra_charges_and_restrictions: sellRateSearchQuery?.accessorials
          ? sellRateSearchQuery?.accessorials
          : [],
        stops_quantity: sellRateSearchQuery?.stops
          ? sellRateSearchQuery?.stops
          : 0,
      };

      return await rateService.getHubtekRate(hubtekRequestData);
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );
  return {
    HubtekDataResults: {
      ...HubtekDataResults,
      data: HubtekDataResults.data,
      error: HubtekDataResults.error as Error,
    },
  };
};

export const useRateData = (
  searchQuery: NewRateQuery | undefined,
  isLegacy?: boolean,
) => {
  const RateViewResults = useQuery(
    ['RateView', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        isRateViewFirebaseReady = false;

        const result = await rateProService.getDATRateview(
          RateQueryToRateBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        isRateViewFirebaseReady = true;
        if (isLegacy) {
          FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.SEARCH,
            FirebaseUtils.FirebaseModules.RATE_PRO,
            FirebaseUtils.FirebasePages.DAT_RATEVIEW,
            {
              pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
              deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
              trailerType: searchQuery.trailerType,
              customerCode: searchQuery.customerCode,
              mileage: result.rate.mileage,
              reports: result.rate.reports,
              companies: result.rate.companies,
              averageFuelSurchargePerMileUsd:
                result.rate.averageFuelSurchargePerMileUsd,
              perMile: `highUsd = ${result.rate.perMile?.highUsd}, lowUsd = ${result.rate.perMile?.lowUsd}, rateUsd = ${result.rate.perMile?.rateUsd}`,
              averageFuelSurchargePerTripUsd:
                result.rate.averageFuelSurchargePerTripUsd,
              standardDeviation: result.rate.standardDeviation,
              perTrip: `highUsd = ${result.rate.perTrip?.highUsd}, lowUsd = ${result.rate.perTrip?.lowUsd}, rateUsd = ${result.rate.perTrip?.rateUsd}`,
              escalation: `timeframe = ${result.escalation.timeframe}, destination ${result.escalation.destination.name}  ${result.escalation.destination.type}, origin = ${result.escalation.origin.name} ${result.escalation.origin.type}`,
              searchDate: new Date(),
              description: 'Check Rates - DAT Rateview Results',
            },
          );
        }

        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const SonarScoreResults = useQuery(
    ['SonarScore', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        if (searchQuery.pickupState && searchQuery.pickupState.length > 2) {
          searchQuery.pickupState = getStateOrProvinceAbbreviation(
            searchQuery.pickupState,
          );
        }

        if (searchQuery.deliveryState && searchQuery.deliveryState.length > 2) {
          searchQuery.deliveryState = getStateOrProvinceAbbreviation(
            searchQuery.deliveryState,
          );
        }

        const result = await rateProService.getRateSonarScore(
          RateQueryToRateBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RATE_SONAR_SCORES,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            equip_type: result.equip_type,
            origin_market: result.origin_market,
            destination_market: result.destination_market,
            origin_kma: result.origin_kma,
            dest_kma: result.dest_kma,
            lane_score_summary: result.lane_score_summary,
            origin_city: result.origin_city,
            origin_state: result.origin_state,
            dest_City: result.dest_City,
            dest_state: result.dest_state,
            searchDate: new Date(),
            description: 'Check Rates - Sonar Scores',
          },
        );

        return SonarScoreApiResultToSonarData(result);
      }
    },
    {
      enabled:
        searchQuery !== undefined &&
        searchQuery.trailerType?.toUpperCase() !== TrailerType.FLATBED,
      refetchOnWindowFocus: false,
    },
  );

  const GreenscreenRTResults = useQuery(
    ['GreenscreenRyanTrans', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        isGreenscreenRTFirebaseReady = false;
        const result = await rateService.getGreenscreenRTPrediction(
          RateQueryToGreenscreenBody(searchQuery),
        );
        if (Object.keys(result).length === 0) {
          throw new Error('Prediction not found.');
        }

        isGreenscreenRTFirebaseReady = true;

        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const GreenscreenNetworkResults = useQuery(
    ['GreenscreenNetwork', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery) {
        isGreenscreenNetworkFirebaseReady = false;
        const result = await rateService.getGreenscreenNetworkPrediction(
          RateQueryToGreenscreenBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Prediction not found.');
        }
        isGreenscreenNetworkFirebaseReady = true;

        return result;
      }
    },
    {
      enabled: searchQuery !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  let mileage =
    RateViewResults.data?.rate.mileage ??
    GreenscreenNetworkResults.data?.distance;
  if (mileage) {
    mileage = Math.ceil(Number(mileage));
  }

  // Rate Cast needs either data from RateView or RTSMiles
  const RateCastResults = useQuery(
    ['RateCast', JSON.stringify(searchQuery)],
    async () => {
      if (searchQuery && mileage) {
        if (searchQuery.pickupState && searchQuery.pickupState.length > 2) {
          searchQuery.pickupState = getStateOrProvinceAbbreviation(
            searchQuery.pickupState,
          );
        }

        if (searchQuery.deliveryState && searchQuery.deliveryState.length > 2) {
          searchQuery.deliveryState = getStateOrProvinceAbbreviation(
            searchQuery.deliveryState,
          );
        }

        const result = await rateProService.getRateCast(
          RateQueryToRateBody(searchQuery),
        );

        if (Object.keys(result).length === 0) {
          throw new Error('Rate not found.');
        }

        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.SEARCH,
          FirebaseUtils.FirebaseModules.RATE_PRO,
          FirebaseUtils.FirebasePages.RTS_HISTORY,
          {
            pickupLocation: `${searchQuery.pickupCity}, ${searchQuery.pickupState}`,
            deliveryLocation: `${searchQuery.deliveryCity}, ${searchQuery.deliveryState}`,
            trailerType: searchQuery.trailerType,
            customerCode: searchQuery.customerCode,
            perMile: result
              .map(
                (e) =>
                  `highUsd = ${e.rate.perMile?.highUsd}, lowUsd = ${e.rate.perMile?.lowUsd}, rateUsd = ${e.rate.perMile?.rateUsd}`,
              )
              .join(', '),
            escalation: result
              .map(
                (e) =>
                  `timeframe = ${e.escalation.timeframe}, totalGranularity = ${e.escalation.totalGranularity}, destination ${e.escalation.destination.name}  ${e.escalation.destination.type}, origin = ${e.escalation.origin.name} ${e.escalation.origin.type}`,
              )
              .join(', '),
            searchDate: new Date(),
            description: 'Check Rates - DAT Ratecast Results',
          },
        );
        return result;
      }
    },
    {
      enabled: searchQuery !== undefined && mileage !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  const rateViewAverageWithoutFuel = RateViewResults.data?.rate.perTrip.rateUsd;
  const rateViewHighWithoutFuel = RateViewResults.data?.rate.perTrip.highUsd;
  const rateViewLowWithoutFuel = RateViewResults.data?.rate.perTrip.lowUsd;

  const rateViewFuelCostPerTrip =
    RateViewResults.data?.rate.averageFuelSurchargePerTripUsd || 0;
  const rateViewAverage =
    (rateViewAverageWithoutFuel ?? 0) + rateViewFuelCostPerTrip;
  const rateViewHigh = (rateViewHighWithoutFuel ?? 0) + rateViewFuelCostPerTrip;
  const rateViewLow = (rateViewLowWithoutFuel ?? 0) + rateViewFuelCostPerTrip;

  const rateViewFuelCostPerMile =
    RateViewResults.data?.rate.averageFuelSurchargePerMileUsd;

  return {
    RateViewResults: {
      ...RateViewResults,
      data: RateViewResults.data
        ? RateViewApiResultToDatRate(RateViewResults.data)
        : undefined,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      error: RateViewResults.error as Error,
      rateViewFuelCostPerTrip,
    },
    RateCastResults: {
      ...RateCastResults,
      data:
        RateCastResults.data && mileage
          ? RateCastApiResultsToDatRate(
              RateCastResults.data,
              mileage,
              rateViewFuelCostPerTrip,
              rateViewFuelCostPerMile,
            )
          : undefined,
      error: RateCastResults.error as Error,
    },
    SonarScoreResults: {
      ...SonarScoreResults,
      error: SonarScoreResults.error as Error,
    },
    GreenscreenRTResults: {
      ...GreenscreenRTResults,
      error: GreenscreenRTResults.error as Error,
    },
    GreenscreenNetworkResults: {
      ...GreenscreenNetworkResults,
      error: GreenscreenNetworkResults.error as Error,
    },
    mileage,
    FirebaseLogsStatus: {
      isRateViewFirebaseReady: isRateViewFirebaseReady,
      isGreenscreenNetworkFirebaseReady: isGreenscreenNetworkFirebaseReady,
      isGreenscreenRTFirebaseReady: isGreenscreenRTFirebaseReady,
    },
  };
};

export const useGetCustomerCodes = () => {
  return useQuery(['getCustomerCodes'], () => rateService.getCustomerCodes());
};

export const useGetCustomerCodesData = () => {
  let results = useGetCustomerCodes();
  return { ...results, customerCodesResult: results.data };
};
