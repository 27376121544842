import {
  CompanyText,
  StyledCheckmark,
  TableContainer,
} from '../CarrierCapacityStyles';
import { SmrTable } from 'shamrock-clover-ui';
import { Column, Row } from 'components/cloverTable/TableTypes';
import { useContext, useState } from 'react';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import CompanyInfoDrawer from './Drawer/CompanyInfoDrawer';
import { sortLanesByMostRecent } from '../utils/capacityUtils';
import { laneService } from '../utils/lanesCapacityHttp';
import { stopBodyScrolling } from '../utils/capacityUtils';
import * as FirebaseUtils from 'utils/firebaseUtils';

const CarrierSearchResults = () => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);

  const {
    carriers,
    openDetails,
    carrierSearchResultsState,
    setIsLoading,
    setOpenDetails,
    setCarrierIndexTaken,
    setLanesAndCapacity,
    lanesAndCapacity,
  } = useContext(CarrierCapacityContext);

  const handleRowClick = async (mcNumber: string, rowIndex: number) => {
    setIsLoading(true);
    setOpenDetails(true);
    stopBodyScrolling(true);
    setSelectedRowIndex(rowIndex);
    setCarrierIndexTaken(rowIndex);
    try {
      const carrierLanes = await laneService.getCarrierLanes(mcNumber);
      setLanesAndCapacity((prevState) =>
        prevState.map((item, index) =>
          index === rowIndex
            ? {
                ...item,
                lanes: sortLanesByMostRecent(carrierLanes),
              }
            : item,
        ),
      );
      setIsLoading(false);
    } catch (error) {
      console.error('Error getting carrier lanes', error);
      setIsLoading(false);
      setOpenDetails(false);
      stopBodyScrolling(false);
    }
  };

  const handleRowClickWrapper = async (mcNumber: string, rowIndex: number) => {
    await handleRowClick(mcNumber, rowIndex);
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.CARRIERS,
        {
          description: 'View Carrier Profile',
          mcleodCarrierId: lanesAndCapacity[rowIndex]?.carrier?.mcleodCarrierId,
          MC: lanesAndCapacity[rowIndex].carrier.mcNumber,
        },
      );
    } catch (e) {
      console.log('Error logging firebase event:', e);
    }
  };

  const carrierSearchTableColumns: Column[] = [
    {
      key: 'company',
      label: 'Company',
      isSortable: false,
      alignment: 'left',
      width: 19,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'right',
      width: 5,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: false,
      alignment: 'right',
      width: 8,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: false,
      alignment: 'left',
      width: 9,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 12,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 18,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 19,
    },
  ];

  const carrierSearchTableRows: Row[] = carriers.map((data, index: number) => {
    const carrier = data;
    const { mcNumber } = carrier;
    return {
      cells: [
        {
          content: <CompanyText>{carrier.companyName}</CompanyText>,
          value: carrier.companyName,
        },
        {
          content: carrier.headquartersStateAbbr,
          value: carrier.headquartersStateAbbr,
        },
        {
          content: carrier.trucks.toString(),
          value: carrier.trucks,
        },
        {
          content: (
            <>
              {carrier.isRtsProUser ? (
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              ) : (
                <></>
              )}
            </>
          ),
          value: carrier.isRtsProUser.toString(),
        },
        {
          content: carrier.mcleodCarrierId,
          value: carrier.mcleodCarrierId,
        },
        {
          content: (
            <>
              MC: {carrier.mcNumber}
              <br />
              DOT: {carrier.dotNumber}
            </>
          ),
          value: `${carrier.mcNumber} ${carrier.dotNumber}`,
        },
        {
          content: carrier.contactName,
          value: carrier.contactName,
        },
        {
          content: (
            <>
              {carrier.contactPhone}
              <br />
              {carrier.contactEmail}
            </>
          ),
          value: `${carrier.contactPhone} ${carrier.contactEmail}`,
        },
      ],
      onClick: () => handleRowClickWrapper(mcNumber, index),
    };
  });
  return (
    <>
      {carriers.length > 0 && (
        // Drawer assumes >= 1 carrier to render
        <CompanyInfoDrawer
          isOpen={openDetails}
          setIsOpen={setOpenDetails}
          selectedRowIndex={selectedRowIndex}
        />
      )}
      <TableContainer marginTop={'56px'}>
        <SmrTable
          loading={carrierSearchResultsState === 'isLoading'}
          columns={carrierSearchTableColumns}
          rows={carrierSearchTableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
        />
      </TableContainer>
    </>
  );
};

export default CarrierSearchResults;
