import { useQuery } from 'react-query';
import { rateAdminService, rateService } from './rateAdminHttp';

export const useGetRateAdjustment = () => {
  return useQuery(
    ['getRateAdminAdjustment'],
    () => rateAdminService.getRateAdminAdjustment(),
    {
      refetchOnMount: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetRateAdjustmentData = () => {
  let results = useGetRateAdjustment();
  return { ...results, rateAdjustmenResult: results.data };
};

export const useGetRateAdjustmentReason = () => {
  return useQuery(['getRateAdminAdjustmentReason'], () =>
    rateAdminService.getRateAdminAdjustmentReasons(),
  );
};

export const useGetRateAdjustmentReasonData = () => {
  let results = useGetRateAdjustmentReason();
  return { ...results, rateAdjustmenReasonsResult: results.data };
};

export const useGetAccessorialFees = () => {
  return useQuery(['getAccessorialFees'], () =>
    rateService.getAccessorialFees(),
  );
};

export const useGetAccessorialFeesData = () => {
  let results = useGetAccessorialFees();
  return { ...results, accessorialFeesResult: results.data };
};
