import {
  StyledGridContainer,
  StyledDiscardButton,
  StyledSaveButton,
} from '../rateAdjustmentStyles';
import { Grid } from '@material-ui/core';

interface Props {
  isDisabled: boolean;
  saveClick: () => void;
  discardClick: () => void;
}

const AccessorialFeesButtons: React.FC<React.PropsWithChildren<Props>> = ({
  isDisabled,
  saveClick,
  discardClick,
}) => {
  return (
    <StyledGridContainer
      container
      direction="column"
      spacing={2}
      paddingLeft="24px"
      paddingBottom="16px"
      paddingTop="0px"
    >
      <Grid item>
        <StyledDiscardButton
          buttonStyle="outlined"
          color="red"
          disabled={isDisabled}
          onClick={discardClick}
        >
          DISCARD CHANGES
        </StyledDiscardButton>
      </Grid>
      <Grid item>
        <StyledSaveButton
          buttonStyle="solid"
          color="blue"
          disabled={isDisabled}
          onClick={saveClick}
        >
          SAVE CHANGES
        </StyledSaveButton>
      </Grid>
    </StyledGridContainer>
  );
};

export default AccessorialFeesButtons;
