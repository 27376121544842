import { Button, Icon, styled } from 'shamrock-clover-ui';

export const CardsCapacityContainer = styled.div`
  background: #eeeeee;
  padding: 8px 16px 24px 16px;
  box-shadow:
    0px 1px 3px 0px #00000033,
    0px 2px 1px 0px #0000001f,
    0px 1px 1px 0px #00000024;
`;

export const Card = styled.div`
  padding: 8px 16px 8px 16px;
  background: #ffffff;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 2px 2px 0px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1.2fr repeat(4, 1fr) 50px;
  margin-bottom: 10px;
`;

export const CardHeader = styled.div`
  padding: 8px 6px 8px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 10px;
  p {
    font-size: 30px;
    color: #333333;
    margin: 0;
    font-weight: 600;
  }
  span {
    font-size: 24px;
    color: #333333;
    font-weight: 400;
  }
`;
export const CardHeaderTitles = styled.div`
  padding: 8px 16px 8px 16px;
  display: grid;
  grid-template-columns: 1.2fr repeat(4, 1fr) 50px;
  span {
    font-size: 17px;
    padding-left: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #666666;
  }
`;

export const CardContent = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const CustomButton = styled(Button)`
  span {
    font-size: 14px;
    color: #ffffff;
  }
`;

export const StyledLanesDropdownContainer = styled.div`
  width: 171px;
  height: 30px;
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #bbbbbb;
  .SingleSelectMenuContainer {
    width: 178px;
    left: 88px;
    top: 0px
    margin-top: 0px;
  }
  div {
    border-bottom: none;
    padding-top: 0px;
    div {
      div {
        font-size: 14px;
        top: 10px;
        div {
          padding-top: 2px;
        }
      }
    }
  }
`;

export const StyledMenuItemText = styled.div`
  font-family: proxima-nova;
  font-size: 14px;
  color: #333333;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledLanesFilterContainer = styled.div`
  width: 244px;
  height: 30px;
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid #bbbbbb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const StyledMultiSelectContainer = styled.div`
  .checkBoxLabelContainer {
    div {
      height: 16px;
      width: 16px;
    }
    span {
      font-size: 14px;
    }
  }
  .MultiSelectMenu {
    padding-right: 8px;
  }
  .MultiSelectMenuContainer {
    width: 246px;
    left: 122px;
    top: 0px
    margin-top: 0px;
  }
`;

export const StyledFilterText = styled.div`
  font-size: 14px;
  color: #666666;
  padding-left: 10px;
  padding-top: 4px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
  margin-top: 4px;
  cursor: pointer;
`;
