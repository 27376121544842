import { isEqual } from 'lodash';
import {
  createContext,
  FC,
  FormEvent,
  ChangeEvent as IChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as FirebaseUtils from 'utils/firebaseUtils';
import { ChangeEvent } from 'react-autosuggest';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { useSearchCapacityQueryParams } from '../../../hooks/useQueryParams';
import {
  Capacity,
  Carrier,
  CarrierPaginationProps,
  CarrierProfileTab,
  EquipmentTypes,
  FilterOptions,
  FilterType,
  ICarrierCapacityContext,
  LaneAndCapacityForm,
  LaneObj,
  LanesAndCapacity,
  LoadDetailsType,
  ResultsState,
  ShowDialogOnDeleteLane,
  SnackbarProps,
} from '../CapacityTypes';
import { City } from '../components/SearchLocationsInputs';
import {
  additionalServicesOptions,
  capitalizeWords,
  sortLanesByMostRecent,
  parseRateAmount,
  trailers,
  stopBodyScrolling,
} from '../utils/capacityUtils';
import { laneService } from '../utils/lanesCapacityHttp';

export const CarrierCapacityContext = createContext<ICarrierCapacityContext>(
  {} as ICarrierCapacityContext,
);

const CarrierCapacityWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const defaultSortOption: MenuOption = { optionName: 'Most Recent' };
  const defaultFilterOptions: FilterOptions[] = [
    { text: 'Ryan Trans Admin', checked: true },
    { text: 'Carrier (RTS Pro)', checked: true },
  ];
  // initial State for table
  const [lanesAndCapacity, setLanesAndCapacity] = useState<LanesAndCapacity[]>(
    [],
  );

  const [carrierIndexTaken, setCarrierIndexTaken] = useState<number | null>(
    null,
  );

  const [snackbarNotification, setSnackbarNotification] =
    useState<SnackbarProps>({} as SnackbarProps);

  const [laneIndexTaken, setLaneIndexTaken] = useState<number | null>(null);
  const [carriers, setCarriers] = useState<Carrier[]>([]);

  const [showDialog, setShowDialog] = useState(false);

  const editFormCopy = useRef<LaneAndCapacityForm | null>(null);
  const rateAmountRef = useRef<boolean>(false);

  // handle Drawer States
  const [openDetails, setOpenDetails] = useState(false);
  const [openAddLane, setOpenAddLane] = useState(false);
  const [openEditLane, setOpenEditLane] = useState(false);
  const [addCapacity, setAddCapacity] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [laneHasCapacity, setLaneHasCapacity] = useState(false);
  const [deletingLaneAndCapacityType, setDeletingLaneAndCapacityType] =
    useState<ShowDialogOnDeleteLane | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successfullState, setSuccessfullState] = useState(false);
  const [drawerTabClicked, setDrawerTabClicked] =
    useState<CarrierProfileTab>('Details');
  const [selectedSortMethod, setSelectedSortMethod] =
    useState<MenuOption>(defaultSortOption);
  const [filterOptions, setFilterOptions] =
    useState<FilterOptions[]>(defaultFilterOptions);
  const [selectedFilterMethod, setSelectedFilterMethod] = useState<
    FilterType | 'All'
  >('All');
  const [carrierPagination, setCarrierPagination] =
    useState<CarrierPaginationProps>({
      type: 'next',
      carrierIndex: 0,
    });

  const [inputsError, setInputsError] = useState<{ [key: string]: boolean }>(
    {},
  );

  const [laneAndCapacityForm, setLaneAndCapacityForm] =
    useState<LaneAndCapacityForm>({} as LaneAndCapacityForm);

  const [
    carrierCapacitySearchResultsState,
    setCarrierCapacitySearchResultsState,
  ] = useState<ResultsState>('initialView');

  const [carrierSearchResultsState, setCarrierSearchResultsState] =
    useState<ResultsState>('initialView');

  const { queryParams, setQueryParams, clearQueryParams } =
    useSearchCapacityQueryParams();

  const searchLanesAndCapacity = async (): Promise<void> => {
    if (lanesAndCapacity.length !== 0) {
      setLanesAndCapacity([]);
    }
    if (!queryParams.pickupDeadheadMiles) {
      queryParams.pickupDeadheadMiles = '75';
    }

    if (!queryParams.deliveryDeadheadMiles) {
      queryParams.deliveryDeadheadMiles = '75';
    }

    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.SEARCH_CAPACITY,
        {
          description: 'Lane Capacity Search',
          originCity: queryParams.pickupCity,
          originState: queryParams.pickupState,
          originDeadhead: queryParams.pickupDeadheadMiles,
          destinCity: queryParams.deliveryCity,
          destinState: queryParams.deliveryState,
          destinDeadhead: queryParams.deliveryDeadheadMiles,
          equipment: queryParams.equipmentType,
        },
      );

      setCarrierCapacitySearchResultsState('isLoading');
      const getSearchLanesCarrier =
        await laneService.searchLanesCarrier(queryParams);
      setLanesAndCapacity(getSearchLanesCarrier);
      setCarrierCapacitySearchResultsState('resultsAvailable');
    } catch (error) {
      setLanesAndCapacity([]);
      setCarrierCapacitySearchResultsState('resultsAvailable');
    }
  };

  const searchCarriers = async (searchString: string): Promise<void> => {
    if (carriers.length !== 0) {
      setCarriers([]);
    }

    try {
      setCarrierSearchResultsState('isLoading');
      const getCarrierSearchResults =
        await laneService.carrierSearch(searchString);
      setCarriers(getCarrierSearchResults);
      setLanesAndCapacity(
        getCarrierSearchResults.map((carrier) => ({
          carrier: carrier,
          lanes: [],
        })),
      );
      setCarrierSearchResultsState('resultsAvailable');
    } catch (error) {
      setCarriers([]);
      setCarrierSearchResultsState('error');
    }
  };

  const resetCarrierSearch = () => {
    setCarriers([]);
    setLanesAndCapacity([]);
    setSnackbarNotification({} as SnackbarProps);
    setCarrierSearchResultsState('initialView');
  };

  const resetCarrierCapacitySearch = () => {
    setLanesAndCapacity([]);
    clearQueryParams();
    setSnackbarNotification({} as SnackbarProps);
    setCarrierCapacitySearchResultsState('initialView');
  };

  //Pickup and delivery option handler
  const handleOptionSelected = (
    name: string,
    required: boolean,
    option: MenuOption,
  ) => {
    if (option.optionName === '') return;

    if (name === 'pickup') {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        locationPickup: { required: true, optionSelected: { optionName: '' } },
      });
    } else if (name === 'delivery') {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        locationDelivery: {
          required: true,
          optionSelected: { optionName: '' },
        },
      });
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
      });
    }
  };

  // Init Pickup and Delivery locations handlers
  const handleSuggestLocation = (
    e: FormEvent<HTMLElement>,
    { newValue }: ChangeEvent,
  ): void => {
    const target = e.target as HTMLInputElement;
    if (!target.name) return;

    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      [target.name]: {
        ...laneAndCapacityForm[target.name as keyof LaneAndCapacityForm],
        optionSelected: { optionName: newValue, suggestion: null },
      },
    });
  };

  const handleSuggestionSelected = (
    _: SyntheticEvent,
    { suggestion }: { suggestion: City },
    name: string,
  ) => {
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      [name]: {
        ...laneAndCapacityForm[name as keyof LaneAndCapacityForm],
        optionSelected: {
          optionName: suggestion.description,
          suggestion: suggestion.description,
        },
      },
    });
  };

  // MultiSelect Trialer handler
  const handleSelectedTrailers = (
    optionText: string,
    result: 'added' | 'removed',
  ) => {
    const updatedOptions = laneAndCapacityForm.trailers.multiSelect?.map(
      (option) => {
        if (option.text === optionText) {
          option.checked = result === 'added';
        }
        return option;
      },
    );

    const findIfNoOneSelected = updatedOptions?.filter((x) => x.checked);
    const findTrailerTypeSelected = updatedOptions?.filter(
      (x) =>
        x.checked &&
        x.text === laneAndCapacityForm.trailerType?.optionSelected?.optionName,
    );

    if (
      (addCapacity && findIfNoOneSelected?.length === 0) ||
      (addCapacity && findTrailerTypeSelected?.length === 0)
    ) {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        trailers: {
          ...laneAndCapacityForm.trailers,
          multiSelect: updatedOptions,
        },
        trailerType: {
          required: true,
          optionSelected: { optionName: '' },
        },
      });
      if (addCapacity) {
        setInputsError({
          ...inputsError,
          trailerType: true,
        });
      }
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        trailers: {
          ...laneAndCapacityForm.trailers,
          multiSelect: updatedOptions,
        },
      });
    }
  };

  //Drawers Handlers
  // - Add Lane
  const handleAddLaneDrawer = () => {
    setOpenDetails(false);
    setOpenAddLane(true);
    setSnackbarNotification({} as SnackbarProps);
    // Add lane Form Init
    setLaneAndCapacityForm({
      pickup: {
        optionSelected: { option: 'City', optionName: 'City or Zip' },
        required: true,
      },
      delivery: {
        optionSelected: { option: 'City', optionName: 'City or Zip' },
        required: true,
      },
      locationPickup: {
        optionSelected: { optionName: '' },
        required: true,
      },
      locationDelivery: {
        optionSelected: { optionName: '' },
        required: true,
      },
      trailers: {
        required: true,
        multiSelect: trailers,
      },
    });
  };

  // - Edit Lane Drawer
  const handleEditLaneDrawer = (
    dataToEdit: LaneObj,
    laneIndex: number,
    source?: 'addCapacity',
  ) => {
    setLaneIndexTaken(laneIndex);
    const {
      pickupType,
      pickupCity,
      pickupState,
      deliveryType,
      deliveryCity,
      deliveryState,
      trailerTypes,
      capacity,
    } = dataToEdit;

    setOpenDetails(false);
    setOpenEditLane(true);

    //Add info for existing lane to Form
    if (capacity) {
      setAddCapacity(true);
      setLaneHasCapacity(true);
      const {
        trucks,
        loadsPerTimeFrame,
        frequencyTimeFrame,
        loadDetailNotes,
        rate,
        loadDetailServices,
      } = capacity;
      const trailerType = rate !== undefined ? rate[0].trailerType : undefined;
      const rateType =
        rate !== undefined ? capitalizeWords(rate[0].rateType) : 'Flat rate';
      const amount = rate !== undefined ? rate[0].amount : 0;

      setIsEdited(true);
      setLaneAndCapacityForm({
        pickup: {
          optionSelected: {
            option: pickupType === 'City' ? 'City' : 'State',
            optionName: pickupType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        delivery: {
          optionSelected: {
            option: deliveryType === 'City' ? 'City' : 'State',
            optionName: deliveryType === 'City' ? 'City or Zip' : 'State',
          },
          required: true,
        },
        locationPickup: {
          optionSelected: {
            optionName:
              pickupType === 'City'
                ? (pickupCity?.cityName ?? '')
                : (pickupState ?? ''),
          },
          required: true,
        },
        locationDelivery: {
          optionSelected: {
            optionName:
              deliveryType === 'City'
                ? (deliveryCity?.cityName ?? '')
                : (deliveryState ?? ''),
          },
          required: true,
        },
        trailers: {
          required: true,
          multiSelect: trailers.map((x) => {
            if (
              trailerTypes &&
              trailerTypes.includes(x.text as EquipmentTypes)
            ) {
              return { ...x, checked: true };
            }
            return x;
          }),
        },
        truckQuantity: {
          optionSelected: { optionName: trucks.toString() },
          required: true,
        },
        loadsPerTimeFrame: {
          optionSelected: { optionName: loadsPerTimeFrame.toString() },
          required: true,
        },
        timeFrame: {
          optionSelected: { optionName: capitalizeWords(frequencyTimeFrame) },
          required: true,
        },
        trailerType: {
          optionSelected: trailerType && {
            optionName: trailerType as string,
          },
          required: true,
        },
        rateAmount: {
          optionSelected: {
            optionName:
              rateType === 'Flat rate'
                ? amount !== undefined
                  ? `$${parseRateAmount(amount.toString(), 'Flat rate')}`
                  : ''
                : amount !== undefined
                  ? `$${parseRateAmount(amount.toString(), 'Per mile')}`
                  : '',
          },
          required: false,
        },
        selectedRateType: {
          optionSelected: { optionName: rateType || 'Flat rate' },
          required: true,
        },
        additionalServices: {
          multiSelect: additionalServicesOptions.map((x) => {
            if (loadDetailServices?.includes(x.text as LoadDetailsType)) {
              return { ...x, checked: true };
            }
            return x;
          }),
          required: false,
        },
        commentValue: {
          optionSelected: { optionName: loadDetailNotes || '' },
          required: false,
        },
      });
    } else {
      setIsEdited(true);
      if (source === 'addCapacity') {
        setAddCapacity(true);
        setLaneAndCapacityForm({
          pickup: {
            optionSelected: {
              option: pickupType === 'City' ? 'City' : 'State',
              optionName: pickupType === 'City' ? 'City or Zip' : 'State',
            },
            required: true,
          },
          delivery: {
            optionSelected: {
              option: deliveryType === 'City' ? 'City' : 'State',
              optionName: deliveryType === 'City' ? 'City or Zip' : 'State',
            },
            required: true,
          },
          locationPickup: {
            optionSelected: {
              optionName:
                pickupType === 'City'
                  ? (pickupCity?.cityName ?? '')
                  : (pickupState ?? ''),
            },
            required: true,
          },
          locationDelivery: {
            optionSelected: {
              optionName:
                deliveryType === 'City'
                  ? (deliveryCity?.cityName ?? '')
                  : (deliveryState ?? ''),
            },
            required: true,
          },
          trailers: {
            required: true,
            multiSelect: trailers.map((x) => {
              if (trailerTypes?.includes(x.text as EquipmentTypes)) {
                return { ...x, checked: true };
              }
              return x;
            }),
          },
          truckQuantity: {
            optionSelected: { optionName: '' },
            required: true,
          },
          loadsPerTimeFrame: {
            optionSelected: { optionName: '' },
            required: true,
          },
          timeFrame: {
            optionSelected: { optionName: '' },
            required: true,
          },
          trailerType: {
            optionSelected: { optionName: '' },
            required: true,
          },
          rateAmount: {
            optionSelected: { optionName: '' },
            required: false,
          },
          selectedRateType: {
            optionSelected: { optionName: 'Flat rate' },
            required: true,
          },
          additionalServices: {
            multiSelect: additionalServicesOptions,
            required: false,
          },
          commentValue: {
            optionSelected: { optionName: '' },
            required: false,
          },
        });
      } else {
        setLaneAndCapacityForm({
          pickup: {
            optionSelected: {
              option: pickupType === 'City' ? 'City' : 'State',
              optionName: pickupType === 'City' ? 'City or Zip' : 'State',
            },
            required: true,
          },
          delivery: {
            optionSelected: {
              option: deliveryType === 'City' ? 'City' : 'State',
              optionName: deliveryType === 'City' ? 'City or Zip' : 'State',
            },
            required: true,
          },
          locationPickup: {
            optionSelected: {
              optionName:
                pickupType === 'City'
                  ? (pickupCity?.cityName ?? '')
                  : (pickupState ?? ''),
            },
            required: true,
          },
          locationDelivery: {
            optionSelected: {
              optionName:
                deliveryType === 'City'
                  ? (deliveryCity?.cityName ?? '')
                  : (deliveryState ?? ''),
            },
            required: true,
          },
          trailers: {
            required: true,
            multiSelect: trailers.map((x) => {
              if (trailerTypes?.includes(x.text as EquipmentTypes)) {
                return { ...x, checked: true };
              }
              return x;
            }),
          },
          ...(source === 'addCapacity' && {
            selectedRateType: {
              optionSelected: { optionName: 'Flat rate' },
              required: true,
            },
          }),
        });
      }
    }
  };

  const handleOnCloseAddLane = () => {
    setOpenAddLane(false);
    setAddCapacity(false);

    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );
    if (addCapacity) {
      laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
        (x) => (x.checked = false),
      );
    }
    setInputsError({});
    setIsLoading(false);
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    setSnackbarNotification({} as SnackbarProps);
    setCarrierIndexTaken(null);
    setSnackbarNotification({} as SnackbarProps);
    setCarrierPagination({} as CarrierPaginationProps);
    // When the user closes the add lane drawer, we want to reenable scrolling on the body
    stopBodyScrolling(false);
  };

  const handleOnCloseEditLane = () => {
    // if the delete dialog is open, we don't want to close the drawer
    if (showDialog) {
      return;
    }
    setOpenEditLane(false);
    setAddCapacity(false);
    setIsLoading(false);
    setIsEdited(false);
    setLaneHasCapacity(false);
    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );

    editFormCopy.current = null;
    rateAmountRef.current = false;
    setLaneIndexTaken(null);
    setInputsError({});
    setCarrierIndexTaken(null);
    setSnackbarNotification({} as SnackbarProps);
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    setCarrierPagination({} as CarrierPaginationProps);
    // When the user closes the edit lane drawer, we want to reenable scrolling on the body
    stopBodyScrolling(false);
  };

  //Capacity from handlers
  //  Init Capacity inputs
  const handleAddCapacity = () => {
    setAddCapacity(true);
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      truckQuantity: {
        optionSelected: { optionName: '' },
        required: true,
      },
      loadsPerTimeFrame: {
        optionSelected: { optionName: '' },
        required: true,
      },
      timeFrame: {
        optionSelected: { optionName: '' },
        required: true,
      },
      trailerType: {
        optionSelected: { optionName: '' },
        required: true,
      },
      rateAmount: {
        optionSelected: { optionName: '' },
        required: false,
      },
      selectedRateType: {
        optionSelected: { optionName: 'Flat rate' },
        required: true,
      },
      additionalServices: {
        multiSelect: additionalServicesOptions,
        required: false,
      },
      commentValue: {
        optionSelected: { optionName: '' },
        required: false,
      },
    });
  };

  const handleCapacityInputsOnBlur = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'truckQuantity') {
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          truckQuantity: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          truckQuantity: false,
        });
      }
    }

    if (e.target.name === 'loadsPerTimeFrame') {
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: false,
        });
      }
    }
  };

  const handleCapacityInputsOnChange = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'truckQuantity') {
      let value = e.currentTarget.value.replace(/^0+/g, '');
      value = value.replace(/[^0-9]/g, '').slice(0, 4);
      if (value.length === 4) {
        value = value.replace(/^(\d)(\d{3})$/, '$1,$2');
      }
      e.currentTarget.value = value;
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        truckQuantity: {
          required: true,
          optionSelected: { optionName: e.target.value },
        },
      });
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          truckQuantity: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          truckQuantity: false,
        });
      }
    }

    if (e.target.name === 'loadsPerTimeFrame') {
      e.currentTarget.value = e.currentTarget.value.replace(/^0+/g, '');
      e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');

      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        loadsPerTimeFrame: {
          required: true,
          optionSelected: { optionName: e.currentTarget.value },
        },
      });
      if (e.currentTarget.value === '') {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: true,
        });
      } else {
        setInputsError({
          ...inputsError,
          loadsPerTimeFrame: false,
        });
      }
    }
  };

  const handleRateAmount = (e: IChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      rateAmountRef.current = true;
    }
    if (
      laneAndCapacityForm.selectedRateType?.optionSelected?.optionName ===
      'Flat rate'
    ) {
      const value = parseRateAmount(e.target.value, 'Flat rate');

      if (value === '') {
        rateAmountRef.current = false;
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: '' },
          },
        });
      } else {
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: `$${value}` },
          },
        });
      }
    }

    if (
      laneAndCapacityForm.selectedRateType?.optionSelected?.optionName ===
      'Per mile'
    ) {
      const value = parseRateAmount(e.target.value, 'Per mile');
      if (value === '') {
        rateAmountRef.current = false;
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: '' },
          },
        });
      } else {
        setLaneAndCapacityForm({
          ...laneAndCapacityForm,
          rateAmount: {
            required: false,
            optionSelected: { optionName: `$${value}` },
          },
        });
      }
    }
  };

  const handleCapacityOptionsSelected = (
    name: string,
    required: boolean,
    option: MenuOption,
  ) => {
    if (option.optionName === '') return;

    if (option.optionName === 'Flat rate' || option.optionName === 'Per mile') {
      if (
        isEdited &&
        laneAndCapacityForm.rateAmount?.optionSelected?.optionName !== ''
      ) {
        rateAmountRef.current = true;
      }
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
        rateAmount: {
          optionSelected: { optionName: '' },
          required: false,
        },
      });
    } else {
      setLaneAndCapacityForm({
        ...laneAndCapacityForm,
        [name]: { optionSelected: option, required },
      });
    }

    if (name === 'timeFrame' && option.optionName === '') {
      setInputsError({
        ...inputsError,
        timeFrame: true,
      });
    } else {
      setInputsError({
        ...inputsError,
        timeFrame: false,
      });
    }
    if (name === 'trailerType' && option.optionName === '') {
      setInputsError({
        ...inputsError,
        trailerType: true,
      });
    } else {
      setInputsError({
        ...inputsError,
        trailerType: false,
      });
    }
  };

  const handleAdditionalServices = (
    optionText: string,
    result: 'added' | 'removed',
  ) => {
    const updatedOptions =
      laneAndCapacityForm.additionalServices?.multiSelect?.map((option) => {
        if (option.text === optionText) {
          option.checked = result === 'added';
        }
        return option;
      });

    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      additionalServices: {
        multiSelect: updatedOptions,
        required: false,
      },
    });
  };

  const handleComments = (value: string) => {
    setLaneAndCapacityForm({
      ...laneAndCapacityForm,
      commentValue: {
        optionSelected: { optionName: value },
        required: false,
      },
    });
  };

  const handleDeleteCapacityForm = () => {
    // We have to update the reference of the form
    const updatedLaneAndCapacityRef = {
      ...editFormCopy.current,
      truckQuantity: {},
      loadsPerTimeFrame: {},
      timeFrame: {},
      trailerType: {},
      rateAmount: {},
      selectedRateType: {},
      additionalServices: {},
      commentValue: {},
    } as LaneAndCapacityForm;
    editFormCopy.current = updatedLaneAndCapacityRef;
    // We have to update the state of the form
    const updatedLaneAndCapacityForm = {
      ...laneAndCapacityForm,
      truckQuantity: {},
      loadsPerTimeFrame: {},
      timeFrame: {},
      trailerType: {},
      rateAmount: {},
      selectedRateType: {},
      additionalServices: {},
      commentValue: {},
    } as LaneAndCapacityForm;
    setLaneAndCapacityForm(updatedLaneAndCapacityForm);
    // Close the capacity form
    setAddCapacity(false);
  };

  console.log(`isEdited :>> `, isEdited);
  const handleDeleteLaneAndCapacity = async () => {
    setIsLoading(true);
    setShowDialog(false);
    const id = lanesAndCapacity[carrierIndexTaken!].lanes[laneIndexTaken!]._id;

    try {
      if (deletingLaneAndCapacityType === 'capacity') {
        const capacityId =
          lanesAndCapacity[carrierIndexTaken!].lanes[laneIndexTaken!].capacity
            ?._id;
        try {
          if (openAddLane) {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.CLICK,
              FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
              FirebaseUtils.FirebasePages.ADDING_LANE,
              {
                description: 'Confirm Delete Capacity',
                mcleodCarrierId:
                  lanesAndCapacity[carrierIndexTaken!].carrier.mcleodCarrierId,
                MC: lanesAndCapacity[carrierIndexTaken!].carrier.mcNumber,
              },
            );
          } else if (openEditLane) {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.CLICK,
              FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
              FirebaseUtils.FirebasePages.EDITING_LANE,
              {
                description: 'Confirm Delete Capacity',
                mcleodCarrierId:
                  lanesAndCapacity[carrierIndexTaken!].carrier.mcleodCarrierId,
                MC: lanesAndCapacity[carrierIndexTaken!].carrier.mcNumber,
              },
            );
          }
        } catch (error) {
          console.log('Error adding firebase event');
        }

        await laneService.editLaneAndCapacity(
          `/capacity/${capacityId}`,
          'DELETE',
        );
        editFormCopy.current = null;
        rateAmountRef.current = false;
        const updatedLanes: LaneObj[] = lanesAndCapacity[
          carrierIndexTaken!
        ].lanes.map((lane) => {
          if (lane._id === id) {
            delete lane.capacity;
          }
          return lane;
        });
        const sortedLanes = sortLanes(updatedLanes, selectedSortMethod);
        setLanesAndCapacity((prevState) =>
          prevState.map((item, index) =>
            index === carrierIndexTaken
              ? { ...item, lanes: sortedLanes }
              : item,
          ),
        );
      } else {
        try {
          if (openAddLane) {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.CLICK,
              FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
              FirebaseUtils.FirebasePages.ADDING_LANE,
              {
                description: 'Confirm Delete Lane',
                mcleodCarrierId:
                  lanesAndCapacity[carrierIndexTaken!].carrier?.mcleodCarrierId,
                MC: lanesAndCapacity[carrierIndexTaken!].carrier.mcNumber,
              },
            );
          } else if (openEditLane) {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.CLICK,
              FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
              FirebaseUtils.FirebasePages.EDITING_LANE,
              {
                description: 'Confirm Delete Lane',
                mcleodCarrierId:
                  lanesAndCapacity[carrierIndexTaken!].carrier?.mcleodCarrierId,
                MC: lanesAndCapacity[carrierIndexTaken!].carrier.mcNumber,
              },
            );
          }
        } catch (error) {
          console.log('Error logging firebase event');
        }
        await laneService.editLaneAndCapacity(`/lanes/${id}`, 'DELETE');
        editFormCopy.current = null;
        rateAmountRef.current = false;
        const updatedLanes: LaneObj[] = lanesAndCapacity[
          carrierIndexTaken!
        ].lanes.filter((lane) => lane._id !== id);
        const sortedLanes = sortLanes(updatedLanes, selectedSortMethod);
        setLanesAndCapacity((prevState) =>
          prevState.map((item, index) =>
            index === carrierIndexTaken
              ? { ...item, lanes: sortedLanes }
              : item,
          ),
        );
      }
    } catch (error) {
      console.log('Error Deleting Lane or Capacity :>> ', error);
      setIsLoading(false);
      return;
    }

    laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
      (x) => (x.checked = false),
    );

    setLaneAndCapacityForm({} as LaneAndCapacityForm);

    setOpenEditLane(false);
    setLaneHasCapacity(false);
    setInputsError({});
    setAddCapacity(false);
    setIsLoading(false);
    const snackBarMsg =
      deletingLaneAndCapacityType === 'capacity'
        ? 'Capacity deleted'
        : 'Lane deleted.';
    setSnackbarNotification({
      open: true,
      message: snackBarMsg,
      type: 'info',
    });
    setOpenDetails(true);
  };

  useEffect(() => {
    const disableForm = (form: LaneAndCapacityForm): boolean => {
      let disable: boolean = false;
      for (const key in form) {
        const field = form[key as keyof LaneAndCapacityForm];
        if (field?.required) {
          if (field.optionSelected?.suggestion === null) {
            disable = true;
            break;
          }

          if (field.optionSelected && field.optionSelected.optionName === '') {
            disable = true;
            break;
          }

          if (field?.multiSelect) {
            const hasChecked = field.multiSelect.some((x) => x.checked);
            if (!hasChecked) {
              disable = true;
              break;
            }
          }
        }
      }
      return disable;
    };
    if (
      isEdited &&
      (editFormCopy.current === null ||
        (editFormCopy.current &&
          Object.keys(editFormCopy.current!).length === 0))
    ) {
      editFormCopy.current = JSON.parse(JSON.stringify(laneAndCapacityForm));
    }

    const isFormEdited = (form: LaneAndCapacityForm): boolean => {
      if (!editFormCopy.current) return false;
      return isEqual(form, editFormCopy.current);
    };

    setDisable(
      disableForm(laneAndCapacityForm) ||
        (isEdited && isFormEdited(laneAndCapacityForm)),
    );
    // eslint-disable-next-line
  }, [laneAndCapacityForm]);

  //Dialog Handlers

  const handleShowDialogOnDelete = (dialogType: ShowDialogOnDeleteLane) => {
    setDeletingLaneAndCapacityType(dialogType);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setDeletingLaneAndCapacityType(null);
  };

  const handleOnCancelDrawer = () => {
    setOpenAddLane(false);
    setOpenEditLane(false);
    setAddCapacity(false);
    setIsEdited(false);
    //Reset the form if Drawer is closed
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );

    editFormCopy.current = null;
    rateAmountRef.current = false;
    setInputsError({});
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    setSnackbarNotification({} as SnackbarProps);
    setOpenDetails(true);
  };

  const handleOnAddLaneAndCapacity = async (companyInfo: Carrier) => {
    setIsLoading(true);
    const { mcNumber, mcleodCarrierId } = companyInfo;
    const request = await laneService.buildLaneAndCapacityRequest(
      laneAndCapacityForm,
      mcNumber,
      mcleodCarrierId,
      addCapacity,
    );

    try {
      const addLane = await laneService.addLaneAndCapacity(request);

      const lanes: LaneObj[] = [
        ...lanesAndCapacity[carrierIndexTaken!].lanes,
        addLane,
      ];
      const sortedLanes = sortLanes(lanes, selectedSortMethod);
      setLanesAndCapacity((prevState) => {
        const updatedLanesAndCapacity = [...prevState];
        updatedLanesAndCapacity[carrierIndexTaken!] = {
          ...updatedLanesAndCapacity[carrierIndexTaken!],
          lanes: sortedLanes,
        };
        return updatedLanesAndCapacity;
      });
      setIsLoading(false);

      setSuccessfullState(true);

      await new Promise((resolve) => setTimeout(resolve, 2500));
      setSuccessfullState(false);

      setOpenAddLane(false);
      setAddCapacity(false);
      // Reset the form and return to company details
      laneAndCapacityForm.trailers.multiSelect?.forEach(
        (x) => (x.checked = false),
      );
      if (addCapacity) {
        laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
          (x) => (x.checked = false),
        );
      }
      setLaneAndCapacityForm({} as LaneAndCapacityForm);
      setOpenDetails(true);
    } catch (error) {
      console.log('error :>> ', error);
      setIsLoading(false);
      setSnackbarNotification({
        type: 'error',
        message: 'Something went wrong. Try again.',
        open: true,
      });
    }
  };

  const isLaneEdited = (
    lane: LaneObj,
    laneAndCapacityForm: LaneAndCapacityForm,
  ): boolean => {
    const formTrailers = laneAndCapacityForm.trailers.multiSelect?.reduce(
      (acc, curr) => (curr.checked ? [...acc, curr.text] : acc),
      [] as string[],
    );
    if (
      laneAndCapacityForm.delivery.optionSelected?.option !== lane.deliveryType
    )
      return true;
    if (laneAndCapacityForm.pickup.optionSelected?.option !== lane.pickupType)
      return true;
    if (
      laneAndCapacityForm.delivery.optionSelected?.option === 'City' &&
      laneAndCapacityForm.locationDelivery.optionSelected?.optionName !==
        lane.deliveryCity?.cityName
    )
      return true;
    if (
      laneAndCapacityForm.delivery.optionSelected?.option === 'State' &&
      laneAndCapacityForm.locationDelivery.optionSelected?.optionName !==
        lane.deliveryState
    )
      return true;
    if (
      laneAndCapacityForm.pickup.optionSelected?.option === 'City' &&
      laneAndCapacityForm.locationPickup.optionSelected?.optionName !==
        lane.pickupCity?.cityName
    )
      return true;
    if (
      laneAndCapacityForm.pickup.optionSelected?.option === 'State' &&
      laneAndCapacityForm.locationPickup.optionSelected?.optionName !==
        lane.pickupState
    )
      return true;
    if (formTrailers?.length !== lane.trailerTypes.length) return true;
    if (JSON.stringify(formTrailers) !== JSON.stringify(lane.trailerTypes))
      return true;
    return false;
  };

  const updateLanesResetFormAndNotify = (updatedLanes: LaneObj[]) => {
    const sortedLanes = sortLanes(updatedLanes, selectedSortMethod);
    setLanesAndCapacity((prevState) =>
      prevState.map((item, index) =>
        index === carrierIndexTaken ? { ...item, lanes: sortedLanes } : item,
      ),
    );
    editFormCopy.current = null;
    rateAmountRef.current = false;
    laneAndCapacityForm.trailers.multiSelect?.forEach(
      (x) => (x.checked = false),
    );
    if (addCapacity) {
      laneAndCapacityForm.additionalServices?.multiSelect?.forEach(
        (x) => (x.checked = false),
      );
    }
    setLaneAndCapacityForm({} as LaneAndCapacityForm);
    setSnackbarNotification({
      open: true,
      message: 'Changes saved.',
      type: 'info',
    });
    setOpenEditLane(false);
    setAddCapacity(false);
    setOpenDetails(true);
  };

  const updateLanesAndCapacity = async (companyInfo: Carrier) => {
    try {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.CARRIER_CAPACITY,
        FirebaseUtils.FirebasePages.EDITING_LANE,
        {
          description: 'Save Edit Lane',
          mcleodCarrierId: companyInfo.mcleodCarrierId,
          MC: companyInfo.mcNumber,
        },
      );
    } catch (error) {
      console.log('Error logging firebase event');
    }
    setIsLoading(true);
    const { mcNumber, mcleodCarrierId } = companyInfo;
    const id = lanesAndCapacity[carrierIndexTaken!].lanes[laneIndexTaken!]._id;
    const laneToEdit =
      lanesAndCapacity[carrierIndexTaken!].lanes[laneIndexTaken!];

    const request = await laneService.buildLaneAndCapacityRequest(
      laneAndCapacityForm,
      mcNumber,
      mcleodCarrierId,
      addCapacity,
    );
    const isTheLaneEdited = isLaneEdited(laneToEdit, laneAndCapacityForm);

    try {
      if (!laneHasCapacity && addCapacity && !isTheLaneEdited) {
        // Add capacity without editing lane
        const addCapacityToLane = (await laneService.editLaneAndCapacity(
          `/lanes/${id}/capacity`,
          'POST',
          request,
        )) as Partial<Capacity>;
        const updatedLanes = lanesAndCapacity[carrierIndexTaken!].lanes.map(
          (lane) =>
            lane._id === id
              ? {
                  ...lane,
                  capacity: addCapacityToLane as Capacity,
                }
              : lane,
        );
        updateLanesResetFormAndNotify(updatedLanes);
      } else if (!laneHasCapacity && addCapacity && isTheLaneEdited) {
        // Add capacity and edit lane
        const addedCapacity = (await laneService.editLaneAndCapacity(
          `/lanes/${id}/capacity`,
          'POST',
          request,
        )) as Partial<Capacity>;
        const updateLane = (await laneService.editLaneAndCapacity(
          `/lanes/${id}`,
          'PATCH',
          request,
        )) as Partial<LaneObj>;

        const updatedLanes = lanesAndCapacity[carrierIndexTaken!].lanes.map(
          (lane) => {
            if (lane._id === id) {
              // When the location type changes, we need to remove the other location type
              const updatedLane = { ...lane, ...updateLane };
              if (updateLane.pickupCity) delete updatedLane.pickupState;
              if (updateLane.pickupState) delete updatedLane.pickupCity;
              if (updateLane.deliveryCity) delete updatedLane.deliveryState;
              if (updateLane.deliveryState) delete updatedLane.deliveryCity;
              return { ...updatedLane, capacity: addedCapacity as Capacity };
            }
            return lane;
          },
        );
        updateLanesResetFormAndNotify(updatedLanes);
      } else {
        // Edit lane and/or capacity
        const updateLaneAndCapacity = (await laneService.editLaneAndCapacity(
          `/lanes/${id}`,
          'PATCH',
          request,
        )) as LaneObj;
        const updatedLanes = lanesAndCapacity[carrierIndexTaken!].lanes.map(
          (lane) => {
            if (lane._id === id) {
              // When the location type changes, we need to remove the other location type
              const updatedLane = { ...lane, ...updateLaneAndCapacity };
              if (updateLaneAndCapacity.pickupCity)
                delete updatedLane.pickupState;
              if (updateLaneAndCapacity.pickupState)
                delete updatedLane.pickupCity;
              if (updateLaneAndCapacity.deliveryCity)
                delete updatedLane.deliveryState;
              if (updateLaneAndCapacity.deliveryState)
                delete updatedLane.deliveryCity;
              return updatedLane;
            }
            return lane;
          },
        );
        updateLanesResetFormAndNotify(updatedLanes);
      }
    } catch (error) {
      console.log('error :>> ', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const paginationHandler = ({
    type,
    carrierIndex,
  }: CarrierPaginationProps) => {
    let newIndex: number = 0;

    if (type === 'next') {
      newIndex =
        carrierPagination.carrierIndex + 1 > lanesAndCapacity.length - 1
          ? 0
          : carrierPagination.carrierIndex + 1;
      setCarrierPagination({
        type: 'next',
        carrierIndex: newIndex,
      });
      setCarrierIndexTaken(newIndex);
      setLanesAndCapacity((prevState) =>
        prevState.map((item, index) =>
          index === newIndex
            ? {
                ...item,
                lanes: sortLanesByMostRecent(lanesAndCapacity[newIndex].lanes),
              }
            : item,
        ),
      );
    } else {
      newIndex =
        carrierPagination.carrierIndex - 1 < 0
          ? lanesAndCapacity.length - 1
          : carrierPagination.carrierIndex - 1;
      setCarrierPagination({
        type: 'previous',
        carrierIndex: newIndex,
      });
      setCarrierIndexTaken(newIndex);
      setLanesAndCapacity((prevState) =>
        prevState.map((item, index) =>
          index === newIndex
            ? {
                ...item,
                lanes: sortLanesByMostRecent(lanesAndCapacity[newIndex].lanes),
              }
            : item,
        ),
      );
    }

    if (drawerTabClicked === 'Load History') {
      setDrawerTabClicked('Details');
    }
    setSelectedSortMethod(defaultSortOption);
    setFilterOptions(defaultFilterOptions);
    setSelectedFilterMethod('All');
  };

  const handleSelectedSortMethod = (option?: MenuOption) => {
    setSelectedSortMethod(option || { optionName: '' });
    const lanes = lanesAndCapacity[carrierIndexTaken!].lanes;
    const sortedLanes = sortLanes(lanes, option);
    setLanesAndCapacity((prevState) =>
      prevState.map((item, index) =>
        index === carrierIndexTaken ? { ...item, lanes: sortedLanes } : item,
      ),
    );
  };

  const sortLanes = (lanes: LaneObj[], option?: MenuOption) => {
    let sortedLanes = [...lanes];

    switch (option ? option.optionName : selectedSortMethod) {
      case 'Most Recent':
        sortedLanes = sortLanesByMostRecent(lanes);
        break;
      case 'Capacity':
        sortedLanes = [...lanes].sort((a, b) => {
          if (a.capacity && !b.capacity) return -1;
          if (!a.capacity && b.capacity) return 1;
          if (a.capacity && b.capacity) return 0;
          return 0;
        });
        break;
      case 'Pickup':
        sortedLanes = [...lanes].sort((a, b) => {
          const aPickup = a.pickupCity?.cityName ?? a.pickupState ?? '';
          const bPickup = b.pickupCity?.cityName ?? b.pickupState ?? '';
          return aPickup.localeCompare(bPickup);
        });
        break;
      case 'Delivery':
        sortedLanes = [...lanes].sort((a, b) => {
          const aDelivery = a.deliveryCity?.cityName ?? a.deliveryState ?? '';
          const bDelivery = b.deliveryCity?.cityName ?? b.deliveryState ?? '';
          return aDelivery.localeCompare(bDelivery);
        });
        break;
      default:
        break;
    }
    return sortedLanes;
  };

  const handleSelectedFilterMethod = (
    optionText: string,
    result: 'added' | 'removed',
  ) => {
    const updatedOptions: FilterOptions[] = filterOptions.map((option) => {
      if (option.text === optionText) {
        option.checked = result === 'added';
      }
      return option;
    });
    setFilterOptions(updatedOptions);
    const filterMethods = updatedOptions
      .filter((method) => method.checked)
      .map((method) => method.text);
    const displayFilterMethods =
      filterMethods.length === 1 ? filterMethods[0] : 'All';
    setSelectedFilterMethod(displayFilterMethods);
  };

  return (
    <CarrierCapacityContext.Provider
      value={{
        defaultFilterOptions,
        lanesAndCapacity,
        carriers,
        queryParams,
        carrierCapacitySearchResultsState,
        carrierSearchResultsState,
        laneAndCapacityForm,
        openDetails,
        openAddLane,
        openEditLane,
        addCapacity,
        disable,
        showDialog,
        isEdited,
        laneHasCapacity,
        deletingLaneAndCapacityType,
        isLoading,
        inputsError,
        rateAmountRef,
        carrierIndexTaken,
        laneIndexTaken,
        snackbarNotification,
        successfullState,
        carrierPagination,
        drawerTabClicked,
        selectedSortMethod,
        filterOptions,
        selectedFilterMethod,
        setLanesAndCapacity,
        searchLanesAndCapacity,
        searchCarriers,
        setOpenDetails,
        setIsLoading,
        setSnackbarNotification,
        setQueryParams,
        setDrawerTabClicked,
        setSelectedSortMethod,
        setFilterOptions,
        setSelectedFilterMethod,
        setCarrierIndexTaken,
        setCarrierPagination,
        resetCarrierCapacitySearch,
        resetCarrierSearch,
        updateLanesAndCapacity,
        handleAddLaneDrawer,
        handleEditLaneDrawer,
        handleOnCloseAddLane,
        handleOnCloseEditLane,
        handleShowDialogOnDelete,
        handleCloseDialog,
        handleOnCancelDrawer,
        handleOnAddLaneAndCapacity,
        handleOptionSelected,
        handleCapacityOptionsSelected,
        handleSuggestLocation,
        handleSuggestionSelected,
        handleSelectedTrailers,
        handleAddCapacity,
        handleDeleteCapacityForm,
        handleDeleteLaneAndCapacity,
        handleCapacityInputsOnChange,
        handleCapacityInputsOnBlur,
        handleAdditionalServices,
        handleRateAmount,
        handleComments,
        paginationHandler,
        handleSelectedSortMethod,
        handleSelectedFilterMethod,
      }}
    >
      {children}
    </CarrierCapacityContext.Provider>
  );
};

export default CarrierCapacityWrapper;
