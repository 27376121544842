import {
  RegionTitleText,
  StyledCard,
  CardContainer,
} from './rateAdjustmentStyles';
import EquipmentRow from './components/EquipmentRow';
import FlatbedRow from './components/FlatbedRow';

const RateAdjustmentStep2 = () => {
  return (
    <StyledCard height="auto" topBorder="1px solid #CCCCCC" paddingLeft="24px">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          2. Select equipment type to change rates
        </RegionTitleText>
        <EquipmentRow title="Van" />
        <EquipmentRow title="Reefer" />
        <FlatbedRow />
      </CardContainer>
    </StyledCard>
  );
};

export default RateAdjustmentStep2;
