import { useAuth0 } from '@auth0/auth0-react';
import Quoting from 'features/quoting/components/Quoting';
import { AblyProvider } from 'hooks/useAbly';
import { useCallback, useEffect, useState } from 'react';
import { FeatureFlagProvider, useFeatureFlags } from 'utils/featureFlagContext';
import Service from 'utils/Service';
import LoadingSpinner from './components/LoadingSpinner';
import { useAuthQueryParams } from './hooks/useQueryParams';
import {
  authenticateAndSaveUser,
  authenticateAuth0AndSaveUser,
  clearLocalStorageAuth,
  getToken,
  getUserLocalStorage,
  isAuthenticated,
  redirectToLogin,
  redirectToLogout,
} from './utils/authUtils';
import { LicenseInfo } from '@mui/x-license';
import { Provider } from 'react-redux';
import store from 'configureStore';

// TODO: Update ably to use 2.X version?
const AblyProviderWithChildren: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = AblyProvider;

const MUI_LICENSE_KEY =
  'aaa270291792cd8f87570a6a415170b3Tz05NjgxNyxFPTE3NTYwNDQzNjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    queryParams: { email, token },
  } = useAuthQueryParams();

  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

  const { isAuth0Enabled } = useFeatureFlags();
  const {
    isAuthenticated: isAuth0Auth,
    isLoading: isAuth0Loading,
    logout,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (!isAuth0Loading && isAuth0Auth && isAuth0Enabled === false) {
      logout();
    }
  }, [isAuth0Loading, isAuth0Auth, isAuth0Enabled, logout]);

  const loginLegacy = useCallback(
    async (email: string, token: string, isAuth0Auth: boolean) => {
      // if we have an email and token we always need to go revalidate
      // a new token could have been generated when core-web routes to our site
      if (email && token) {
        try {
          await authenticateAndSaveUser(token, email);
          await Service.addInterceptors(isAuth0Auth, logout);
          setIsLoading(false);
          return;
        } catch (error) {
          redirectToLogout(isAuth0Auth, logout);
        }
      }

      if (isAuthenticated()) {
        try {
          const currentUser = getUserLocalStorage();
          const currentUserToken = getToken();
          await Service.addInterceptors(isAuth0Auth, logout);
          await authenticateAndSaveUser(currentUserToken!, currentUser?.email!);
          setIsLoading(false);
          return;
        } catch (error) {
          console.log('error :>> ', error);
          redirectToLogout(isAuth0Auth, logout);
        }
      }
      // when we logout core-web will redirect here without the token and email
      // and expects us to redirect back to the proper login url
      redirectToLogin();
    },
    [logout],
  );

  const loginAuth0 = useCallback(async () => {
    if (!isAuth0Auth) {
      clearLocalStorageAuth();
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    } else {
      await Service.addInterceptors(
        isAuth0Auth,
        logout,
        getAccessTokenSilently,
      );
      const claims = await getIdTokenClaims();
      const email = claims?.upn || claims?.email;
      await authenticateAuth0AndSaveUser(email);
      setIsLoading(false);
    }
  }, [
    isAuth0Auth,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    getIdTokenClaims,
  ]);

  useEffect(() => {
    const startAuth = async () => {
      if (isAuth0Enabled && !isAuthenticated()) {
        await loginAuth0();
      } else {
        await loginLegacy(email, token, isAuth0Auth);
      }
    };
    if (!isAuth0Loading && isAuth0Enabled !== undefined) startAuth();
  }, [
    isAuth0Loading,
    isAuth0Enabled,
    loginAuth0,
    loginLegacy,
    email,
    token,
    isAuth0Auth,
  ]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Provider store={store}>
      <FeatureFlagProvider>
        <AblyProviderWithChildren>
          <Quoting />
        </AblyProviderWithChildren>
      </FeatureFlagProvider>
    </Provider>
  );
};

export default App;
