import * as contentful from 'contentful';

export const fetchContentfulData = async (): Promise<{
  title: string;
  description: string;
}> => {
  // We shouldn't have this long lived token in the codebase.  We should wrap this.
  const client = contentful.createClient({
    accessToken:
      '762cf82bd264a71609040370471510e67436ccb627251c23e3a3fe80a6dc7052',
    space: 'r83p0mxwzi9g',
    environment: 'master',
  });
  const entry = await client.getEntry('3QJhP1vXr3sM46iJNpR04J');
  const title = entry.fields?.title as string;
  const description = entry.fields?.description as string;
  return { title, description };
};
