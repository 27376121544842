import { Auth0ContextInterface, LogoutOptions } from '@auth0/auth0-react';
import { logFirebaseUser } from './firebaseUtils';
import { usersService } from './userUtils';
const USER_URL = process.env.REACT_APP_USER_URL || '';
const LOADBOARD_ADMIN_URL = process.env.REACT_APP_LOADBOARD_ADMIN_URL || '';
const RTSPRO_URL = process.env.REACT_APP_RTSPRO_URL || '';
const LOADBOARD_ADMIN_PERMISSION = 'ryantrans.LoadboardInternalAdmin';
const INTERNAL_TESTING_PERMISSION = 'ryantrans.LoadAdminQuotingInternalTesting';
const BOOK_IT_TOGGLE_PERMISSION = 'ryantrans.LoadboardBookItNowOverride';
const RATEPRO_PERMISSION = 'ryantrans.RatePro';
const RATEPRO_ADMIN_PERMISSION = 'ryantrans.RateProAdmin';
const RTSPRO_CAM_USERS_PERMISSION = 'ryantrans.CAMLoadboardInternalAdmin';
const RTSPRO_CUSTOMER_USERS_PERMISSION =
  'ryantrans.Customer.LoadboardInternalAdmin';
const TOKEN = 'TOKEN';
const USER = 'USER';

export interface UserInfo {
  token: string;
  user: string;
  id: string;
}

export interface CurrentUser {
  company: string;
  createdOn: string;
  crmId: string;
  deviceIds: string[];
  email: string;
  factoring_status: string;
  firstname?: string;
  lastname: string;
  note: string;
  permissions?: { name: string; status: string }[];
  pin: string;
  roles: string[];
  status: string;
  user_type: string;
  _id: string;
  password: string;
  phone?: string;
  mcleod_customer_id: string[];
  bridge_id: string[];
  device_ids: string[];
  mc_number?: string;
}

interface Auth0Context {
  getAccessTokenSilently?: Auth0ContextInterface['getAccessTokenSilently'];
  isAuthenticated?: boolean;
  logout?: Auth0ContextInterface['logout'];
  user?: Auth0ContextInterface['user'];
}

export const auth0Context: Auth0Context = {
  getAccessTokenSilently: undefined,
  isAuthenticated: false,
  logout: undefined,
  user: undefined,
};

export const addAuth0Context = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  isAuthenticated: boolean,
  logout: Auth0ContextInterface['logout'],
  user: Auth0ContextInterface['user'],
) => {
  auth0Context.getAccessTokenSilently = getAccessTokenSilently;
  auth0Context.isAuthenticated = isAuthenticated;
  auth0Context.logout = logout;
  auth0Context.user = user;
};

export const getUserHttp = async (token: string, userName: string) => {
  const response = await fetch(`${USER_URL}user/user/` + userName, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()) as CurrentUser;
};

const setUser = (user: CurrentUser) => {
  localStorage.setItem(USER, JSON.stringify(user));
};

export const getUserLocalStorage = () => {
  const user = localStorage.getItem(USER);
  return user ? (JSON.parse(user) as CurrentUser) : null;
};

export const clearLocalStorageAuth = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);
};

const setToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN);
};

export const getUserEmail = () => {
  let user = getUserLocalStorage();
  return user && user.email ? user.email : '';
};

export const authenticateAuth0AndSaveUser = async (email: string) => {
  const user = await usersService.getUser(email);
  logFirebaseUser(user._id, user.user_type);
  setUser(user as CurrentUser);
};

export const authenticateAndSaveUser = async (
  token: string,
  userName: string,
) => {
  setToken(token);

  const user = await getUserHttp(token, userName);

  logFirebaseUser(user._id, user.user_type);

  setUser(user as any);
};

export const isAuthenticated = () => {
  const user = getUserLocalStorage();
  const token = getToken();

  if (user && token) {
    const isValid =
      user.permissions &&
      user.permissions.some(
        (x) =>
          x.name === LOADBOARD_ADMIN_PERMISSION ||
          x.name === RATEPRO_PERMISSION ||
          x.name === RTSPRO_CAM_USERS_PERMISSION,
      );

    if (!isValid) {
      return false;
    }
    logFirebaseUser(user._id, user.user_type);

    return true;
  }
  return false;
};

export const redirectToLogin = () => {
  clearLocalStorageAuth();
  const url = `${RTSPRO_URL}/auth?finaldestination=${encodeURIComponent(
    LOADBOARD_ADMIN_URL,
  )}`;
  window.location.replace(url);
};

export const redirectToLogout = (
  isAuth0Auth: boolean,
  logout: (options?: LogoutOptions) => Promise<void>,
) => {
  clearLocalStorageAuth();
  const redirect = encodeURIComponent(LOADBOARD_ADMIN_URL);
  if (isAuth0Auth) {
    logout({
      logoutParams: { returnTo: LOADBOARD_ADMIN_URL },
    });
  } else {
    window.location.assign(`${RTSPRO_URL}/logout?finaldestination=${redirect}`);
  }
};

export const isCAMUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === RTSPRO_CAM_USERS_PERMISSION)
  );
};

export const isCustomerBoardInternalAdminUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === RTSPRO_CUSTOMER_USERS_PERMISSION)
  );
};

export const isLoadBoardInternalAdminUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === LOADBOARD_ADMIN_PERMISSION)
  );
};

export const isInternalTestingUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === INTERNAL_TESTING_PERMISSION)
  );
};

export const isRateProUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === RATEPRO_PERMISSION)
  );
};

export const isBookItNowToggleUser = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === BOOK_IT_TOGGLE_PERMISSION)
  );
};

export const isRateProAdmin = () => {
  const user = getUserLocalStorage();

  return (
    user?.permissions &&
    user.permissions.some((x) => x.name === RATEPRO_ADMIN_PERMISSION)
  );
};
