// import { UnknownAction, ThunkDispatch } from '@reduxjs/toolkit';
import {
  dnbCreditApi,
  creditCheckHistorySlice,
} from 'features/creditRequest/state';
import { combineReducers } from 'redux';

// Use this when we have more things in state besides endpoints
// export type Dispatch = ThunkDispatch<StoreState, unknown, UnknownAction>;

// export interface StoreState {
// }

export default combineReducers({
  [dnbCreditApi.reducerPath]: dnbCreditApi.reducer,
  [creditCheckHistorySlice.reducerPath]: creditCheckHistorySlice.reducer,
});
