import * as React from 'react';
import {
  Container,
  SelectReasonContainer,
  StyledRateAdjustmentGridItem,
} from './rateAdjustmentStyles';
import Grid from '@material-ui/core/Grid';
import RateAdjustmentStep2 from './RateAdjustmentStep2';
import RateAdjustmentStep1 from './RateAdjustmentStep1';
import RateAdjustmentButtons from './RateAdjustmentButtons';
import { useRateAdjustmentQueryParams } from '../../hooks/useQueryParams';
import { rateAdminService } from './rateAdminHttp';
import { Flex } from 'components/Flex';
import {
  styled,
  SelectInput,
  Dialog,
  TextInput,
  Button,
} from 'shamrock-clover-ui';
import {
  clearValuesDefaultsParams,
  RateAdjustmentApiResult,
} from './rateAdminTypes';
import SpinnerWithWait from 'components/Spinner';
import useSnackbar from 'hooks/useSnackbar';
import {
  useGetRateAdjustmentData,
  useGetRateAdjustmentReasonData,
} from './useRateAdminData';
import _ from 'lodash';
import AccessorialFees from './AccessorialFees';
const HelperText = styled.div`
  font-family: proxima-nova, sans-serif;
  margin: 10px 0px 0px 0px;
  font-size: 0.75em;
  color: #666666;
  background-color: transparent;
  margin-bottom: 1em;
`;

const StyleTextInput = styled(TextInput)`
  width: 270px;
`;

const ADD_REASON = 'Add new reason';

const RateAdjustment = () => {
  const { showSnackbar } = useSnackbar();

  const { rateAdjustmenReasonsResult } = useGetRateAdjustmentReasonData();
  const { refetch } = useGetRateAdjustmentData();

  const getReasonItems = () => {
    const items = [];
    rateAdjustmenReasonsResult?.forEach((e) => {
      items.push(e.reason);
    });
    items.push(ADD_REASON);
    return items.map((optionName) => ({
      optionName,
    }));
  };

  const { queryParams, setQueryParams } = useRateAdjustmentQueryParams();
  const [defaultValues, setDefaultValue] = React.useState(queryParams);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [selectedReason, setSelectedReason] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);

  const isDisabled = (): boolean =>
    !queryParams.region ||
    !queryParams.lowVanSonarBeginning ||
    !queryParams.lowVanSonarEnd ||
    !queryParams.averageVanSonarBeginning ||
    !queryParams.averageVanSonarEnd ||
    !queryParams.highVanSonarBeginning ||
    !queryParams.highVanSonarEnd ||
    !queryParams.lowReeferSonarBeginning ||
    !queryParams.lowReeferSonarEnd ||
    !queryParams.averageReeferSonarBeginning ||
    !queryParams.averageReeferSonarEnd ||
    !queryParams.highReeferSonarBeginning ||
    !queryParams.highReeferSonarEnd ||
    !queryParams.flatbedDatRate ||
    !queryParams.flatbedNumberOfLoads ||
    !queryParams.flatbedRtsRate ||
    !queryParams.vanNumberOfLoads ||
    !queryParams.vanRtsRate ||
    !queryParams.reeferNumberOfLoads ||
    !queryParams.reeferRtsRate ||
    _.isEqual(defaultValues, queryParams);

  const getReasons = async () => {
    if (
      selectedReason === ADD_REASON &&
      searchValue &&
      searchValue.length > 0
    ) {
      await rateAdminService.createRateAdminAdjustmentReason({
        reason: searchValue,
      });
      return searchValue;
    }
    return selectedReason;
  };

  const clearResults = () => {
    setDefaultValue(clearValuesDefaultsParams);
    setQueryParams(clearValuesDefaultsParams);
  };

  const discardClick = () => {
    clearResults();
  };

  const saveClick = async () => {
    setShowSpinner(true);
    const reason = await getReasons();
    await rateAdminService.createRateAdminAdjustment({
      ...queryParams,
      reason: reason,
    });
    clearResults();
    setQueryParams({ region: undefined });
    setShowDialog(false);
    setShowSpinner(false);
    showSnackbar({
      message: `Changes saved successfully`,
      type: 'success',
    });
    await refetch();
  };

  const handleOnChangeSearchInput = ({
    target: { value },
  }: React.ChangeEvent<{ value: string }>) => {
    setSearchValue(value);
  };

  return (
    <Container paddingLeft="30px" paddingTop="32px" paddingRight="32px">
      <Grid container>
        <StyledRateAdjustmentGridItem item sm={12} md={12} lg={10}>
          <RateAdjustmentStep1
            setDefaultValue={(value: RateAdjustmentApiResult) => {
              setDefaultValue(value);
            }}
          />
          {queryParams.region && <RateAdjustmentStep2 />}
          <RateAdjustmentButtons
            isDisabled={isDisabled()}
            saveClick={() => setShowDialog(true)}
            discardClick={discardClick}
          />
        </StyledRateAdjustmentGridItem>
        <StyledRateAdjustmentGridItem item sm={12} md={12} lg={2}>
          <AccessorialFees />
        </StyledRateAdjustmentGridItem>
      </Grid>
      {showDialog && (
        <Dialog
          open={showDialog}
          title="Confirm changes"
          content={
            <SelectReasonContainer>
              <SelectInput
                label="Response"
                onOptionSelected={(option) => {
                  // issue with ddl in dialog hack
                  (
                    document?.querySelector('.inputContainer') as HTMLDivElement
                  ).click();
                  setSelectedReason(option ? option.optionName.toString() : '');
                }}
                options={getReasonItems()}
                value={{ optionName: selectedReason }}
                width={250}
              />
              <HelperText>Specify a reason for the modification</HelperText>
              {selectedReason === ADD_REASON && (
                <StyleTextInput
                  label={'Add new reason'}
                  onChange={handleOnChangeSearchInput}
                  value={searchValue}
                />
              )}
            </SelectReasonContainer>
          }
          onClose={() => setShowDialog(false)}
          width="505px"
          actions={
            <>
              {showSpinner && (
                <SpinnerWithWait
                  height="unset"
                  width="unset"
                  size="15"
                  useWait={false}
                />
              )}
              <Flex justifyContent="space-between" margin="25px 0px 0px 0px">
                <Button
                  width="86px"
                  height="40px"
                  buttonStyle="outlined"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  height="40px"
                  width="86px"
                  onClick={saveClick}
                  disabled={
                    selectedReason.length === 0 ||
                    (selectedReason === ADD_REASON && searchValue.length === 0)
                  }
                >
                  Confirm
                </Button>
              </Flex>
            </>
          }
        />
      )}
    </Container>
  );
};

export default RateAdjustment;
