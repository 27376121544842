import * as React from 'react';
import RateProToolbar from './RateProToolbar';
import Footer from './Footer/Footer';
import Card from 'components/Card';
import { useHubtekData, useMpactData, useRateData } from './useRateData';
import {
  HubtekRequestQuery,
  NewRateQuery,
  TrailerType,
  Rate,
  AccessorialsType,
  AccessorialFeesValuesKeys,
  AccessorialFeesValues,
} from './ratesTypes';
import { Flex } from 'components/Flex';
import SpinnerWithWait from 'components/Spinner';
import RateDisplay from './RateDisplay';
import { getSuggestedBuyRateNew } from './suggestedBuyRates/suggestedBuyRate';

import {
  GridContainer,
  Container,
  GridItem,
  ErrrorText,
  NoRateText,
  LargeText,
  MediumText,
  Line,
} from './rateProStyles';
import {
  useGetAccessorialFeesData,
  useGetRateAdjustmentData,
} from '../rateProAdmin/useRateAdminData';
import { MpactCard } from './cards/MpactCard';
import { fetchContentfulData } from './contentfulApi';
import { RtsLoadHistoryRateDisplay } from './loadHistory/RtsLoadHistoryRateDisplay';
import { RateProErrorBoundary } from './loadHistory/RTSHistoryErrorBoundary';
import { useLoadHistoryData } from './loadHistory/hooks/useLoadHistoryData';
import { useState } from 'react';
import {
  SuggestedbuyRateCard,
  getSuggestedBuyRateStatus,
} from './cards/SuggestedBuyRateCard';
import { getRegionByState } from 'features/rateProAdmin/rateProAdminRegions';
import Grid from '@material-ui/core/Grid';
import { SuggestedSellRateCard } from './cards/SuggestedSellRateCard';
import {
  AccessorialFeesApiResult,
  RateAdjustmentApiResult,
} from 'features/rateProAdmin/rateAdminTypes';

export const ErrorText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <ErrrorText>{text}</ErrrorText>
  </Flex>
);

export const NoDataText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <NoRateText>{text}</NoRateText>
  </Flex>
);

const RateProLegacy = () => {
  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const [newBuyRate, setNewBuyRate] = useState<number>();
  const [searchQuery, setSearchQuery] = useState<NewRateQuery>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [dialogDescription, setDialogDescription] = useState<string>();

  const [sellRateQuery, setSellRateQuery] = useState<HubtekRequestQuery>();
  const [sellRateRecalculating, setSellRateRecalculating] =
    useState<boolean>(false);
  const [accessorialsValue, setAccessorialsValue] = useState<AccessorialsType>({
    driverAssist: false,
    hazmat: false,
    palletJack: false,
    ramps: false,
    sameDay: false,
    tankerEndorsed: false,
    tarps: false,
    team: false,
  });
  const [stops, setStops] = useState<number>(0);
  const [buyRateAccessorialAdjustment, setBuyRateAccessorialAdjustment] =
    useState<number>(0);

  React.useEffect(() => {
    const fetchData = async () => {
      const { title, description } = await fetchContentfulData();
      if (title) {
        setDialogTitle(title);
      }
      if (description) {
        setDialogDescription(description);
      }
    };

    fetchData();
  }, []);

  const {
    LoadHistoryResults: {
      isLoading: LoadHistoryIsLoading,
      data: loadHistoryData,
      error: LoadHistoryError,
    },
  } = useLoadHistoryData(searchQuery);

  const {
    RateViewResults: {
      isError: RateViewIsError,
      isLoading: RateViewIsLoading,
      data: RateViewData,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      error: RateViewError,
      rateViewFuelCostPerTrip,
    },
    RateCastResults: {
      isError: RateCastIsError,
      isLoading: RateCastIsLoading,
      data: RateCastData,
      error: RateCastError,
    },
    SonarScoreResults: {
      isError: SonarScoreIsError,
      isLoading: SonarScoreIsLoading,
      data: SonarScoreData,
    },
  } = useRateData(searchQuery, true);

  const {
    MpactDataResults: {
      isError: MpactDataIsError,
      isLoading: MpactDataIsLoading,
      data: MpactData,
      error: MpactDataError,
    },
  } = useMpactData(searchQuery);

  const {
    HubtekDataResults: {
      isError: HubtekDataIsError,
      isLoading: HubtekDataIsLoading,
      data: HubtekData,
    },
  } = useHubtekData(searchQuery, sellRateQuery);

  const { accessorialFeesResult: accessorialFees } =
    useGetAccessorialFeesData();
  let currentAccessorialFees: AccessorialFeesApiResult | undefined;
  if (accessorialFees && accessorialFees.length > 0) {
    currentAccessorialFees = accessorialFees[accessorialFees.length - 1];
  }
  // Calculate the total of the selected accessorials to add to the buy rate.
  React.useEffect(() => {
    if (currentAccessorialFees) {
      const savedAdditionalStops = currentAccessorialFees.additionalStops;
      const savedAccessorialsValues: AccessorialFeesValues = {
        driverAssist: currentAccessorialFees.driverAssist,
        hazmat: currentAccessorialFees.hazmat,
        palletJack: currentAccessorialFees.palletJack,
        ramps: currentAccessorialFees.ramps,
        sameDay: currentAccessorialFees.sameDay,
        tankerEndorsed: currentAccessorialFees.tankerEndorsed,
        tarps: currentAccessorialFees.tarps,
        team: currentAccessorialFees.team,
      };

      let totalAccessorialCost = 0;

      Object.keys(accessorialsValue).forEach((key) => {
        if (accessorialsValue[key as AccessorialFeesValuesKeys]) {
          totalAccessorialCost +=
            savedAccessorialsValues[key as AccessorialFeesValuesKeys];
        }
      });

      function stopValue(stops: number) {
        switch (stops) {
          case 1:
            return savedAdditionalStops.oneStop;
          case 2:
            return savedAdditionalStops.twoStops;
          case 3:
            return savedAdditionalStops.threeStops;
          case 4:
            return savedAdditionalStops.fourPlusStops;
          default:
            return 0;
        }
      }
      totalAccessorialCost += stopValue(stops);

      setBuyRateAccessorialAdjustment(totalAccessorialCost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialsValue, stops]);

  // Calculate the suggested buy rate and watch for changes.
  React.useEffect(() => {
    const getBuyRate = async () => {
      const originState = searchQuery?.pickupState;
      const rateAdjustmentApiResult: RateAdjustmentApiResult[] | undefined =
        rateAdjustmenResult;
      const sonarScore = SonarScoreData?.score;

      // The calculation is the same as before except with new RTS history data.
      const rtsHistoryRateAverage = loadHistoryData?.stats.avg;
      const rtsHistoryLoads = loadHistoryData?.totalResults;
      let equipmentType = searchQuery?.trailerType;

      if (
        originState === undefined ||
        rateAdjustmentApiResult === undefined ||
        rateViewAverage === undefined ||
        rateViewHigh === undefined ||
        rateViewLow === undefined ||
        equipmentType === undefined
      ) {
        return;
      }

      const region = getRegionByState(originState);

      const regionAdjustment = rateAdjustmentApiResult?.find(
        (e) => e.region === region || e.region === 'all',
      );

      let buyRateResult = await getSuggestedBuyRateNew({
        regionAdjustment,
        sonarScore,
        rateViewAverage,
        rateViewHigh,
        rateViewLow,
        rtsHistoryRateAverage,
        rtsHistoryLoads,
        equipmentType,
      });

      if (!buyRateResult) {
        return;
      }
      console.log(`Setting newBuyRate to: ${buyRateResult}`);
      buyRateResult = buyRateResult + buyRateAccessorialAdjustment;
      setNewBuyRate(buyRateResult);
    };
    getBuyRate();
  }, [
    rateAdjustmenResult,
    rateViewAverage,
    rateViewHigh,
    rateViewLow,
    searchQuery,
    SonarScoreData,
    RateViewData,
    loadHistoryData,
    rateViewFuelCostPerTrip,
    buyRateAccessorialAdjustment,
  ]);

  const isEmpty = (data: undefined | Rate | Rate[]) => {
    if (!data) {
      return false;
    }

    if (Array.isArray(data)) {
      return data.length === 0;
    }

    return Object.keys(data).length === 0;
  };

  return (
    <Container>
      <GridContainer container>
        <GridItem item sm={12}>
          <RateProToolbar
            getRatesOnClick={setSearchQuery}
            mileage={RateViewData?.mileage}
            isLoading={
              LoadHistoryIsLoading ||
              RateViewIsLoading ||
              RateCastIsLoading ||
              SonarScoreIsLoading ||
              MpactDataIsLoading ||
              HubtekDataIsLoading
            }
          />
        </GridItem>

        <Grid item xs={12} lg={4}>
          <GridItem item sm={12}>
            <Card
              contentHeight="165px"
              primaryTitle="DAT"
              secondaryTitle="RATEVIEW"
              legacyCard
            >
              {(RateViewIsError &&
                RateViewError.message === 'Rate not found.') ||
              isEmpty(RateViewData) ? (
                <NoDataText text="No DAT Rateview Available" />
              ) : (
                RateViewIsError && <ErrorText text="Failed to return rates" />
              )}
              {RateViewIsLoading && <SpinnerWithWait />}
              {!RateViewIsError && RateViewData && (
                <RateDisplay {...RateViewData} />
              )}
            </Card>
          </GridItem>
          <GridItem item sm={12}>
            <Card
              contentHeight="322px"
              primaryTitle="DAT"
              secondaryTitle="RATECAST"
              legacyCard
            >
              {(RateCastIsError &&
                RateCastError.message === 'Rate not found.') ||
              isEmpty(RateCastData) ||
              (RateViewIsError &&
                RateViewError.message === 'Rate not found.') ? (
                <NoDataText text="No DAT Ratecast Available" />
              ) : (
                RateCastIsError && <ErrorText text="Failed to return rates" />
              )}
              {RateCastIsLoading || (RateViewIsLoading && <SpinnerWithWait />)}
              {!RateCastIsError && RateCastData && !isEmpty(RateCastData) && (
                <>
                  <RateDisplay {...RateCastData[0]} />
                  <Line />
                  <RateDisplay {...RateCastData[1]} />
                </>
              )}
            </Card>
          </GridItem>
        </Grid>
        <Grid item xs={12} lg={4}>
          <GridItem item sm={12}>
            <Card
              contentHeight="165px"
              primaryTitle="RTS"
              secondaryTitle="HISTORY"
              legacyCard
            >
              <RateProErrorBoundary fallbackText={'No RTS History Available'}>
                {loadHistoryData && loadHistoryData.totalResults === 0 ? (
                  <NoDataText text="No RTS History Available" />
                ) : (
                  <>
                    {LoadHistoryIsLoading ? (
                      <SpinnerWithWait />
                    ) : (
                      <>
                        {!LoadHistoryError &&
                          loadHistoryData &&
                          searchQuery && (
                            <RtsLoadHistoryRateDisplay
                              loadHistoryData={loadHistoryData}
                            />
                          )}
                      </>
                    )}
                  </>
                )}
              </RateProErrorBoundary>
            </Card>
          </GridItem>
          <GridItem item sm={12}>
            <MpactCard
              mpactData={MpactData}
              mileage={RateViewData?.mileage}
              isError={MpactDataIsError}
              isLoading={MpactDataIsLoading}
              error={MpactDataError}
              legacyCard
            />
          </GridItem>
          <GridItem item sm={12}>
            <Card
              contentHeight="113px"
              contentPadding="16px"
              secondaryTitle="SONAR SCORE"
              legacyCard
            >
              {searchQuery?.trailerType?.toUpperCase() ===
                TrailerType.FLATBED && (
                <NoDataText text="Sonar Score Unavailable for Flatbed" />
              )}
              {SonarScoreIsError && (
                <NoDataText text="No Sonar Score Available" />
              )}
              {SonarScoreIsLoading && <SpinnerWithWait />}
              {!SonarScoreIsError && SonarScoreData && (
                <Flex>
                  <LargeText margin="0px 16px 0px 0px">
                    {SonarScoreData.score}
                  </LargeText>
                  <MediumText bold={false}>
                    {SonarScoreData.description}
                  </MediumText>
                </Flex>
              )}
            </Card>
          </GridItem>
        </Grid>
        <Grid item xs={12} lg={4}>
          <GridItem item sm={12}>
            <SuggestedbuyRateCard
              buyRate={newBuyRate}
              cardState={getSuggestedBuyRateStatus(
                SonarScoreIsLoading,
                SonarScoreIsError,
                HubtekDataIsLoading,
                newBuyRate,
              )}
              helpDialogDescription={dialogDescription || ''}
              helpDialogTitle={dialogTitle || ''}
              sellRateRecalculating={sellRateRecalculating}
              legacyCard
            />
          </GridItem>

          <GridItem item sm={12}>
            <SuggestedSellRateCard
              equiptmentType={searchQuery?.trailerType}
              hubtekData={HubtekData}
              isLoading={HubtekDataIsLoading}
              isError={HubtekDataIsError}
              sellRateRecalculating={sellRateRecalculating}
              setSellRateRecalculating={setSellRateRecalculating}
              mainSearchQuery={searchQuery}
              setSellRateQuery={setSellRateQuery}
              accessorialsValue={accessorialsValue}
              setAccessorialsValue={setAccessorialsValue}
              stops={stops}
              setStops={setStops}
              buyRate={newBuyRate}
              legacyCard
            />
          </GridItem>
        </Grid>

        <Grid item sm={12}>
          <Footer
            hasRate={RateViewData !== undefined}
            suggestedBuyRate={
              newBuyRate !== undefined ? newBuyRate.toString() : ''
            }
            isSellRateFFOn
          />
        </Grid>
      </GridContainer>
    </Container>
  );
};

export default RateProLegacy;
