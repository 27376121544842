import {
  numberToCurrencyString,
  numberToWholeCurrencyString,
} from 'utils/numberUtils';

type EscalationOriginDestinationType =
  | '3_DIGIT_ZIP'
  | 'MARKET_AREA'
  | 'EXTENDED_MARKET_AREA'
  | 'STATE'
  | 'REGION'
  | 'COUNTRY';

type RateViewEscalationTimeframe =
  | '3_DAYS'
  | '7_DAYS'
  | '15_DAYS'
  | '30_DAYS'
  | '60_DAYS'
  | '90_DAYS'
  | '180_DAYS'
  | '365_DAYS'
  | '1_MONTH';

type RateCastEscalationTimeframe = string;

export type RateViewApiResult = {
  rate: {
    mileage: number;
    reports?: number;
    companies?: number;
    perMile?: {
      rateUsd: number;
      highUsd: number;
      lowUsd: number;
    };
    averageFuelSurchargePerMileUsd?: number;
    standardDeviation: number;
    perTrip: {
      rateUsd: number;
      highUsd: number;
      lowUsd: number;
    };
    averageFuelSurchargePerTripUsd: number;
  };
  escalation: {
    timeframe?: RateViewEscalationTimeframe;
    origin: {
      name: string;
      type: EscalationOriginDestinationType;
    };
    destination: {
      name: string;
      type: EscalationOriginDestinationType;
    };
  };
};

export type RateCastApiResult = {
  rate: {
    perMile: {
      rateUsd: number;
      highUsd: number;
      lowUsd: number;
    };
  };
  escalation: {
    totalGranularity: number;
    timeframe: RateCastEscalationTimeframe;
    origin: {
      name: string;
      type: EscalationOriginDestinationType;
    };
    destination: {
      name: string;
      type: EscalationOriginDestinationType;
    };
  };
};

export type Rate = {
  timeframe: string;
  originEscalationType: string;
  destinationEscalationType: string;
  mileage: number;
  mileRate: string;
  mileRateLowHighText: string;
  tripRate: string;
  tripRateLowHighText: string;
  experiencesText: string;
};

export type SonarScoreApiResult = {
  equip_type: string;
  origin_market: string;
  destination_market: string;
  origin_kma: string;
  dest_kma: string;
  lane_score_summary:
    | 'Easiest'
    | 'Easy'
    | 'Modestly easy'
    | 'Modestly difficult'
    | 'Difficult'
    | 'Most difficult';
  origin_city: string;
  origin_state: string;
  dest_City: string;
  dest_state: string;
};

export type SonarScore = {
  score: number;
  description: string;
};

export type RateBody = {
  origin: {
    city: string;
    stateOrProvince: string;
    country: string;
  };
  destination: {
    city: string;
    stateOrProvince: string;
    country: string;
  };
  equipment: 'VAN' | 'REEFER' | 'FLATBED';
};

export type HubtekRequestBody = {
  origin_city?: string;
  origin_state?: string;
  delivery_city?: string;
  delivery_state?: string;
  trailer_type: string;
  identifiers: string[];
  extra_charges_and_restrictions: string[];
  stops_quantity: number;
};

export type RateQuery = {
  pickupLocation: string;
  deliveryLocation: string;
  trailerType: TrailerType;
  customerCode: string;
};

export interface NewRateQuery {
  pickupCity?: string;
  pickupState?: string;
  pickupPostalCode?: string;
  deliveryCity?: string;
  deliveryState?: string;
  deliveryPostalCode?: string;
  trailerType?: TrailerType;
  customerCode?: string;
}

export interface HubtekRequestQuery {
  tolerance: string;
  accessorials: string[];
  stops: number;
}

type SubmitRate = {
  reason: string;
  suggestedBuyRate: number;
  customerId: string;
  customerRate: number;
  sonarScore?: string;
};

export type RateHistoryQueryParams = {
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  trailerType: TrailerType;
  originZip?: string;
  destinationZip?: string;
};

export type SubmitRateBody = SubmitRate &
  RateBody & {
    datRate: RateViewApiResult;
  };

const getEscalationDisplay = (
  escalationType: EscalationOriginDestinationType,
) => {
  if (escalationType === '3_DIGIT_ZIP') {
    return '3 Digit';
  }
  if (escalationType === 'MARKET_AREA') {
    return 'Market';
  }
  if (escalationType === 'EXTENDED_MARKET_AREA') {
    return 'Extended Market';
  }
  if (escalationType === 'COUNTRY') {
    return 'Country';
  }
  if (escalationType === 'STATE') {
    return 'State';
  }
  if (escalationType === 'REGION') {
    return 'Region';
  }
  return '';
};

export const RateViewApiResultToDatRate = ({
  rate,
  escalation,
}: RateViewApiResult): Rate => {
  return {
    timeframe: escalation.timeframe
      ? escalation.timeframe
          .split('_')
          .join(' ')
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
      : '0',
    originEscalationType: getEscalationDisplay(escalation.origin.type),
    destinationEscalationType: getEscalationDisplay(
      escalation.destination.type,
    ),
    mileage: rate.mileage,
    mileRateLowHighText:
      rate.perMile && rate.averageFuelSurchargePerMileUsd
        ? `${numberToCurrencyString(
            rate.perMile.lowUsd + rate.averageFuelSurchargePerMileUsd,
          )} - ${numberToCurrencyString(
            rate.perMile.highUsd + rate.averageFuelSurchargePerMileUsd,
          )}`
        : '-- - --',
    mileRate:
      rate.perMile && rate.averageFuelSurchargePerMileUsd
        ? `${numberToCurrencyString(
            rate.perMile.rateUsd + rate.averageFuelSurchargePerMileUsd,
          )} RPM`
        : '--',
    tripRateLowHighText: `${numberToWholeCurrencyString(
      rate.perTrip.lowUsd + rate.averageFuelSurchargePerTripUsd,
    )} - ${numberToWholeCurrencyString(
      rate.perTrip.highUsd + rate.averageFuelSurchargePerTripUsd,
    )}`,
    tripRate: numberToWholeCurrencyString(
      rate.perTrip.rateUsd + rate.averageFuelSurchargePerTripUsd,
    ),
    experiencesText: `${rate.reports || 0} Experiences`,
  };
};

export const RateCastApiResultsToDatRate = (
  rates: RateCastApiResult[],
  mileage: number,
  fuelPerTrip: number = 0,
  fuelPerMile: number = 0,
) => {
  return rates
    ?.reduce((rateCasts: RateCastApiResult[], rateCast) => {
      const [first, second] = rateCasts;

      if (!first) {
        return [rateCast];
      }

      if (
        first &&
        first.escalation.timeframe === rateCast.escalation.timeframe &&
        first.escalation.totalGranularity > rateCast.escalation.totalGranularity
      ) {
        return [rateCast];
      }

      if (!second) {
        return [first, rateCast];
      }

      if (
        second &&
        second.escalation.timeframe === rateCast.escalation.timeframe &&
        second.escalation.totalGranularity >
          rateCast.escalation.totalGranularity
      ) {
        return [first, second];
      }

      return rateCasts;
    }, [])
    .map(({ rate, escalation }) => {
      const tripRateAverage = rate?.perMile.rateUsd * mileage;
      const tripRateHigh = rate?.perMile.highUsd * mileage;
      const tripRateLow = rate?.perMile.lowUsd * mileage;

      return {
        timeframe:
          escalation.timeframe === '1_DAYS'
            ? 'Tomorrow'
            : escalation.timeframe === '3_DAYS'
              ? 'Next 3 Days'
              : '',
        originEscalationType: getEscalationDisplay(escalation.origin.type),
        destinationEscalationType: getEscalationDisplay(
          escalation.destination.type,
        ),
        mileage,
        mileRateLowHighText: rate.perMile
          ? `${numberToCurrencyString(
              rate.perMile.lowUsd + fuelPerMile,
            )} - ${numberToCurrencyString(rate.perMile.highUsd + fuelPerMile)}`
          : '-- - --',
        mileRate: rate.perMile
          ? `${numberToCurrencyString(rate.perMile.rateUsd + fuelPerMile)} RPM`
          : '--',
        tripRateLowHighText: `${numberToWholeCurrencyString(
          tripRateLow + fuelPerTrip,
        )} - ${numberToWholeCurrencyString(tripRateHigh + fuelPerTrip)}`,
        tripRate: numberToWholeCurrencyString(tripRateAverage + fuelPerTrip),
        experiencesText: ``,
      };
    });
};

export const SonarScoreApiResultToSonarData = ({
  lane_score_summary,
}: SonarScoreApiResult): SonarScore => {
  switch (lane_score_summary) {
    case 'Easiest':
      return {
        score: -3,
        description:
          'Easiest to cover. Work available options to maximize margin. Strong decrease from average rates.',
      };
    case 'Easy':
      return {
        score: -2,
        description:
          'Easy to cover. Work available options to maximize margin. Decrease from average rates.',
      };
    case 'Modestly easy':
      return {
        score: -1,
        description:
          'Neutral/Easy. Work available options to maximize margin. Target below max-buy or average rates.',
      };
    case 'Modestly difficult':
      return {
        score: 1,
        description:
          "Neutral/Difficult. Don't hesitate long on reasonable offers. Modest increase from average rates.",
      };
    case 'Difficult':
      return {
        score: 2,
        description:
          "Difficult to cover. Tight capacity. Don't hesitate long on first offers. Increase from average rates.",
      };
    case 'Most difficult':
      return {
        score: 3,
        description:
          "Most difficult to cover. Tight capacity. Don't hesitate long on first offers. Strong increase from average rates.",
      };
  }
};

export enum TrailerType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  FLATBED = 'FLATBED',
}

export const RateQueryToRateBody = (query: NewRateQuery): RateBody => {
  const equipment = query.trailerType?.toUpperCase() as
    | 'VAN'
    | 'REEFER'
    | 'FLATBED';

  return {
    origin: {
      city: query.pickupCity || '',
      stateOrProvince: query.pickupState || '',
      country: 'USA',
    },
    destination: {
      city: query.deliveryCity || '',
      stateOrProvince: query.deliveryState || '',
      country: 'USA',
    },
    equipment,
  };
};

export const RateQueryToGreenscreenBody = (
  query: NewRateQuery,
): GreenscreenRequestBody => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    transportType: query.trailerType?.toUpperCase(),
    stops: [
      {
        order: 0,
        country: 'US',
        city: query.pickupCity,
        state: query.pickupState,
        zip: query.pickupPostalCode,
      },
      {
        order: 1,
        country: 'US',
        city: query.deliveryCity,
        state: query.deliveryState,
        zip: query.deliveryPostalCode,
      },
    ],
    currency: 'USD',
    pickupDateTime: tomorrow,
  };
};
export interface HubtekApiResponse {
  transaction_id: string;
  fail: boolean;
  original_miles: number;
  original_miles_with_stops: number;
  original_base_line_rate: number;
  original_base_line_with_stops_rate: number;
  rate_board_1: number;
  rate_board_2: number;
  rate_board_3: number;
  rate_board_4: number;
  rate_board_5: number;
  rate_board_6: number;
  rate_board_7: number;
  rate_board_8: number;
  rate_board_9: number;
  rate_board_10: number;
  rate_board_11: number;
  rate_board_12: number;
  rate_board_13: number;
  rate_board_14: number;
  rate_board_15: number;
  rate_board_16: number;
  rate_board_17: number;
  rate_board_18: number;
  rate_board_19: number;
  fixed_price_lane: number;
  events: [];
  exception_report_oid: number;
  report_oid: number;
  distance_margin: number;
  lead_time: number;
  weekend: number;
  holiday: number;
  after_hours: number;
  season: number;
  transit_time: number;
  transit_time_delivery: number;
  weight_tier: number;
  delivery_time: number;
  pickup_time: number;
  greenscreens_confidence_score: number;
  truckstop_score: number;
  origin_conditioned: number;
  origin_state_conditioned: number;
  origin_zip_conditioned: number;
  destination_conditioned: number;
  destination_state_conditioned: number;
  destination_zip_conditioned: number;
  lane_conditioned: number;
  quote_amount_threshold: number;
  quote_amount_threshold_zip: {
    origin: number;
    delivery: number;
  };
  quote_amount_threshold_city: {
    origin: number;
    delivery: number;
  };
  quote_amount_threshold_state: {
    origin: number;
    delivery: number;
  };
  conditioned_locations_pickup: number;
  conditioned_locations_delivery: number;
  conditioned_lanes_by_state: number;
  accessorials: number;
  extra_charges_and_restrictions: number;
  lane_scores: number;
  same_day_cutoff: number;
  temperature: number;
  stops: number;
  stops_quantity: number;
  truck_to_load_ratio_origin_from_to: {
    from_: number;
    to: number;
  };
  truck_to_load_ratio_destination_from_to: {
    from_: number;
    to: number;
  };
  truck_to_load_ratio_origin: number;
  truck_to_load_ratio_destination: number;
  cross_border: number;
  cross_border_origin_country: string;
  cross_border_delivery_country: string;
  international_shipment: number;
  international_shipment_origin_country: string;
  international_shipment_delivery_country: string;
  total_rate: number;
  fsc: number;
  total: number;
  margin_final: number;
  expiration: Date;
  bid_post: false;
  re_bid_minimum_total: number;
  re_bid_minimum_output: {
    transaction_id: string;
    fail: false;
    original_miles: number;
    original_miles_with_stops: number;
    original_base_line_rate: number;
    original_base_line_with_stops_rate: number;
    rate_board_1: number;
    rate_board_2: number;
    rate_board_3: number;
    rate_board_4: number;
    rate_board_5: number;
    rate_board_6: number;
    rate_board_7: number;
    rate_board_8: number;
    rate_board_9: number;
    rate_board_10: number;
    rate_board_11: number;
    rate_board_12: number;
    rate_board_13: number;
    rate_board_14: number;
    rate_board_15: number;
    rate_board_16: number;
    rate_board_17: number;
    rate_board_18: number;
    rate_board_19: number;
    fixed_price_lane: number;
    events: [];
    exception_report_oid: number;
    report_oid: number;
    distance_margin: number;
    lead_time: number;
    weekend: number;
    holiday: number;
    after_hours: number;
    season: number;
    transit_time: number;
    transit_time_delivery: number;
    weight_tier: number;
    delivery_time: number;
    pickup_time: number;
    greenscreens_confidence_score: number;
    truckstop_score: number;
    origin_conditioned: number;
    origin_state_conditioned: number;
    origin_zip_conditioned: number;
    destination_conditioned: number;
    destination_state_conditioned: number;
    destination_zip_conditioned: number;
    lane_conditioned: number;
    quote_amount_threshold: number;
    quote_amount_threshold_zip: {
      origin: number;
      delivery: number;
    };
    quote_amount_threshold_city: {
      origin: number;
      delivery: number;
    };
    quote_amount_threshold_state: {
      origin: number;
      delivery: number;
    };
    conditioned_locations_pickup: number;
    conditioned_locations_delivery: number;
    conditioned_lanes_by_state: number;
    accessorials: number;
    extra_charges_and_restrictions: number;
    lane_scores: number;
    same_day_cutoff: number;
    temperature: number;
    stops: number;
    stops_quantity: number;
    truck_to_load_ratio_origin_from_to: {
      from_: number;
      to: number;
    };
    truck_to_load_ratio_destination_from_to: {
      from_: number;
      to: number;
    };
    truck_to_load_ratio_origin: number;
    truck_to_load_ratio_destination: number;
    cross_border: number;
    cross_border_origin_country: string;
    cross_border_delivery_country: string;
    international_shipment: number;
    international_shipment_origin_country: string;
    international_shipment_delivery_country: string;
    total_rate: number;
    fsc: number;
    total: number;
    margin_final: number;
    expiration: Date;
    bid_post: false;
  };
}

export interface AccessorialsType {
  driverAssist: boolean;
  hazmat: boolean;
  palletJack: boolean;
  ramps: boolean;
  sameDay: boolean;
  tankerEndorsed: boolean;
  tarps: boolean;
  team: boolean;
}

export interface AccessorialFeesValues {
  driverAssist: number;
  hazmat: number;
  palletJack: number;
  ramps: number;
  sameDay: number;
  tankerEndorsed: number;
  tarps: number;
  team: number;
}

export type AccessorialFeesValuesKeys = keyof AccessorialFeesValues;

export type CustomerCode = string;

export type CustomerCodesApiResult = string[];

export interface GreenscreenResponse {
  id: string;
  targetBuyRate: number;
  lowBuyRate: number;
  highBuyRate: number;
  startBuyRate: number;
  fuelRate: number;
  distance: number;
  confidenceLevel: number;
  acceptanceRates: number[];
  tag: string;
  currency: string;
  notes: {
    shortHaulRateInaccurate: {
      distanceLessThan: number;
    };
    multistopDistanceOutlierRateInaccurate: {
      allowedDistanceDifferenceRange: {
        minimumPercentage: number;
        maximumPercentage: number;
      };
      singleStopDistance: number;
    };
  };
}

type Stop = {
  order: number;
  country: string;
  city?: string;
  state?: string;
  zip?: string;
};

export interface GreenscreenRequestBody {
  pickupDateTime: Date;
  transportType?: string;
  stops: Stop[];
  currency: string;
}
