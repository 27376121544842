import React from 'react';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import { Spinner } from 'shamrock-clover-ui';
import { useDispatch } from 'react-redux';
import {
  addCompanyToCreditCheckHistory,
  useLazyGetCreditQuery,
} from '../../state';
import Icon, { ImageName } from '../../../../components/Icon';
import { numberToCurrencyString } from '../../../../utils/numberUtils';
import { DnbCompany } from '../../creditRequestTypes';
import {
  StyledDialogContainer,
  StyledConfirmCreditCheckContainer,
  StyledConfirmCreditCheckButtonContainer,
  StyledConfirmCreditCheckTitle,
  StyledConfirmCreditCheckMainText,
  StyledLoadingContainer,
  StyledLoadingText,
  StyledCreditCheckContainer,
  StyledCreditCheckTitle,
  StyledCreditCheckCompanyName,
  StyledCreditCheckStatusContainer,
  StyledCreditCheckStatus,
  StyledCreditCheckRow,
  StyledCreditCheckLabel,
  StyledCreditCheckValue,
  StyledCreditCheckStatusText,
  StyledCreditCheckHr,
} from './CreditCheckModalStyles';

interface Props {
  selectedCreditCheckCompany: DnbCompany;
  setSelectedCreditCheckCompany: Function;
}

const RunCreditCheckModal: React.FC<Props> = ({
  selectedCreditCheckCompany,
  setSelectedCreditCheckCompany,
}) => {
  const dispatch = useDispatch();
  const [getCredit, { data: credit, isLoading }] = useLazyGetCreditQuery();

  const onCheckCompany = () => {
    getCredit(selectedCreditCheckCompany?.bureauIdentifierNumber);
    dispatch(
      addCompanyToCreditCheckHistory(
        selectedCreditCheckCompany?.bureauIdentifierNumber,
      ),
    );
  };

  const onCancel = () => {
    setSelectedCreditCheckCompany(undefined);
  };

  const getConfirmCreditCheckContent = () => {
    return (
      <StyledConfirmCreditCheckContainer>
        <StyledConfirmCreditCheckTitle>
          Run credit check on {`${selectedCreditCheckCompany?.businessName}`}?
        </StyledConfirmCreditCheckTitle>
        <StyledConfirmCreditCheckMainText>
          RTS has a limited number of credit checks per year. Only check
          companies that you have high confidence in.
        </StyledConfirmCreditCheckMainText>
        <StyledConfirmCreditCheckButtonContainer>
          <Button buttonStyle="outlined" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button buttonStyle="solid" onClick={() => onCheckCompany()}>
            Check company
          </Button>
        </StyledConfirmCreditCheckButtonContainer>
      </StyledConfirmCreditCheckContainer>
    );
  };

  const getLoadingContent = () => {
    return (
      <StyledLoadingContainer>
        <Spinner size="74" />
        <StyledLoadingText>Running credit check....</StyledLoadingText>
      </StyledLoadingContainer>
    );
  };

  const getCreditCheckContent = () => {
    const getCreditCheckStatusDetails = () => {
      switch (credit?.status) {
        case 'Approved':
          return {
            image: 'GreenCheckmark',
            text: 'This credit approval is valid for 90-days',
          };
        case 'Tentative':
          return {
            image: 'YellowSubtract',
            text: 'A completed and signed credit application needs to be reviewed by the credit team before extending an initial line of credit.',
          };
        case 'Declined':
        default:
          return {
            image: 'RedX',
            text: `No credit approved at this time. Recommend pre-pay.`,
          };
      }
    };

    const creditCheckDetails = getCreditCheckStatusDetails();

    return (
      <StyledCreditCheckContainer>
        <StyledCreditCheckTitle>Credit results</StyledCreditCheckTitle>
        <StyledCreditCheckCompanyName>
          {credit?.companyInfo.name}
        </StyledCreditCheckCompanyName>
        <StyledCreditCheckStatusContainer>
          <Icon
            name={creditCheckDetails.image as ImageName}
            alt={creditCheckDetails.image}
            width={24}
          />
          <StyledCreditCheckStatus status={credit?.status}>
            {credit?.status}
          </StyledCreditCheckStatus>
        </StyledCreditCheckStatusContainer>
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>Initial Credit Limit</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {!credit?.creditInfo?.creditLimit
              ? 'None'
              : numberToCurrencyString(credit?.creditInfo?.creditLimit)}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>Credit Terms</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {credit?.creditInfo?.paymentTerms}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
        <StyledCreditCheckStatusText status={credit?.status}>
          {creditCheckDetails.text}
        </StyledCreditCheckStatusText>
        <StyledCreditCheckHr />
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>D&B Rating</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {credit?.creditInfo?.dbViabilityRating || '\u2014'}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>Paydex</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {credit?.creditInfo?.paydex || '\u2014'}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>Start year</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {credit?.companyInfo?.startDate || '\u2014'}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
        <StyledCreditCheckRow>
          <StyledCreditCheckLabel>Employees</StyledCreditCheckLabel>
          <StyledCreditCheckValue>
            {credit?.companyInfo?.numberOfEmployees || '\u2014'}
          </StyledCreditCheckValue>
        </StyledCreditCheckRow>
      </StyledCreditCheckContainer>
    );
  };

  const getModalContent = () => {
    if (!isLoading && !credit) {
      return getConfirmCreditCheckContent();
    }

    if (isLoading) {
      return getLoadingContent();
    }

    if (credit) {
      return getCreditCheckContent();
    }
  };

  return (
    <StyledDialogContainer>
      <Dialog
        open={true}
        content={getModalContent()}
        width="auto"
        onClose={() => {
          onCancel();
        }}
      />
    </StyledDialogContainer>
  );
};

export default RunCreditCheckModal;
