import CreditCheckToolbar from './components/CreditCheckToolbar';
import {
  Container,
  StyledCreditCheckContainer,
  StyledInitialViewText,
  StyledInitialViewTextContainer,
} from './creditRequestStyles';
import CompanyTable from './components/CompanyTable';
import dnbCreditApi from './state';
import { useState } from 'react';
import { CreditCheckRequestParams, DnbCompany } from './creditRequestTypes';
import CreditCheckModal from './components/CreditCheckModal';

const CreditCheck = () => {
  const [initialView, setInitialView] = useState(true);
  const [requestParams, setRequestParams] = useState<CreditCheckRequestParams>({
    selectedCity: '',
    selectedState: '',
    countryCode: '',
    companyName: '',
  });
  const [selectedCreditCheckCompany, setSelectedCreditCheckCompany] =
    useState<DnbCompany>();
  const [getCompanies, { data, isFetching }] =
    dnbCreditApi.endpoints.getCompanies.useLazyQuery();

  const handleSearch = () => {
    getCompanies(requestParams);
    setInitialView(false);
  };

  return (
    <>
      <Container>
        <StyledCreditCheckContainer>
          <CreditCheckToolbar
            requestParams={requestParams}
            setRequestParams={setRequestParams}
            handleSearch={handleSearch}
          />
          {initialView ? (
            <StyledInitialViewTextContainer>
              <StyledInitialViewText>
                Search to see if a customer is eligible for credit.
              </StyledInitialViewText>
            </StyledInitialViewTextContainer>
          ) : (
            <CompanyTable
              results={data}
              isLoading={isFetching}
              setSelectedCreditCheckCompany={setSelectedCreditCheckCompany}
            />
          )}
        </StyledCreditCheckContainer>
      </Container>
      {selectedCreditCheckCompany && (
        <CreditCheckModal
          selectedCreditCheckCompany={selectedCreditCheckCompany}
          setSelectedCreditCheckCompany={setSelectedCreditCheckCompany}
        />
      )}
    </>
  );
};

export default CreditCheck;
