import { Button } from 'shamrock-clover-ui';
import { styled } from 'shamrock-clover-ui';
import { useRateProQueryParams } from '../../hooks/useQueryParams';
import { NewRateQuery } from './ratesTypes';

const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-family: Proxima Nova;
  height: 36px;
`;

type Props = {
  getRatesOnClick: React.Dispatch<
    React.SetStateAction<NewRateQuery | undefined>
  >;
  isLoading: boolean;
  hasSelectedCustomerCode: boolean;
};

const GetRatesButton = ({
  getRatesOnClick,
  isLoading,
  hasSelectedCustomerCode,
}: Props) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      pickupPostalCode,
      deliveryCity,
      deliveryState,
      deliveryPostalCode,
      trailerType,
      customerCode,
    },
  } = useRateProQueryParams();

  const onClick = () => {
    if (
      pickupCity &&
      pickupState &&
      deliveryCity &&
      deliveryState &&
      trailerType &&
      customerCode
    ) {
      getRatesOnClick({
        pickupCity,
        pickupState,
        pickupPostalCode,
        deliveryCity,
        deliveryState,
        deliveryPostalCode,
        trailerType,
        customerCode,
      });
    }
  };

  return (
    <StyledButton
      buttonStyle="outlined"
      isRounded={true}
      disabled={
        !(
          pickupCity &&
          pickupState &&
          deliveryCity &&
          deliveryState &&
          trailerType &&
          customerCode &&
          hasSelectedCustomerCode
        ) || isLoading
      }
      onClick={onClick}
    >
      Check Rates
    </StyledButton>
  );
};

export default GetRatesButton;
