export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL_V2 = process.env.REACT_APP_API_BASE_URL_V2;
export const ABLY_AUTH_SERVICE_URL =
  process.env.REACT_APP_ABLY_AUTH_SERVICE_URL;
export const FREIGHT_ADMIN_CHANNEL = 'freight-admin';
export const FEATURE_SERVICE_URL = process.env.REACT_APP_FEATURE_SERVICE_URL;
export const REACT_APP_RTSPRO_URL = process.env.REACT_APP_RTSPRO_URL;
export const ABLY_AUTH_SERVICE_URL_V2 =
  process.env.REACT_APP_ABLY_AUTH_SERVICE_URL_V2;
export const ABLY_QUOTE_CHANNEL = process.env.REACT_APP_ABLY_QUOTE_CHANNEL;
export const ABLY_QUOTE_CHANNEL_CAM_USER =
  process.env.REACT_APP_ABLY_QUOTE_CHANNEL_CAM_USER;
export const ABLY_QUOTE_CHANNEL_LOADBOARD_USER =
  process.env.REACT_APP_ABLY_QUOTE_CHANNEL_LOADBOARD_USER;
export const ENABLE_ABLY_EVENTS = process.env.REACT_APP_ENABLE_ABLY_EVENTS;
export const REACT_APP_CREDIT_SERVICE_BASE_URL =
  process.env.REACT_APP_CREDIT_SERVICE_BASE_URL;
export const REACT_APP_TRANSQUOTE_SERVICE_URL =
  process.env.REACT_APP_TRANSQUOTE_SERVICE_URL;
export const REACT_APP_MPACT_API_BASE_URL =
  process.env.REACT_APP_MPACT_API_BASE_URL;
export const REACT_APP_RTS_HISTORY_BASE_URL =
  process.env.REACT_APP_RTS_HISTORY_BASE_URL;
export const RATE_SERVICE_BASE_URL = process.env.REACT_APP_RTS_HISTORY_BASE_URL;
export const REACT_APP_RATE_SERVICE_BASE_URL =
  process.env.REACT_APP_RATE_SERVICE_BASE_URL;
export const LANE_SERVICE_URL = process.env.REACT_APP_LANE_SERVICE_URL;
