import React, { useEffect, useState } from 'react';
import { styled } from 'shamrock-clover-ui';
import { Flex } from '../../components/Flex';
import { useRateProQueryParams } from '../../hooks/useQueryParams';
import { LabelText } from './rateProStyles';
import Autosuggest, { BlurEvent } from 'react-autosuggest';
import { useGetCustomerCodesData } from './useRateData';
import { CustomerCode, CustomerCodesApiResult } from './ratesTypes';
import { Icon as CloverIcon } from 'shamrock-clover-ui';

const CustomerCodeInputContainer = styled.div<{ isError: boolean }>`
  height: 36px;

  .react-autosuggest__input {
    border-width: 0px;
    outline: 0;
    width: 206px;
    background-color: white;
    height: 34px;
    font-size: 14px;
    font-family: Proxima Nova;
    padding-left: 19px;
    padding-right: 10px;
    border-radius: 20px;
    border: ${({ isError, theme }) =>
      isError ? `1px solid ${theme.colors.red[50]}` : 'none'};
    ::placeholder {
      color: #333333;
    }
  }

  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 145px;
    padding-left: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #d8d8d8;
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
    max-height: 430px;
    overflow-y: auto;
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
`;

const StyledCloseIcon = styled(CloverIcon)`
  cursor: pointer;
`;
interface InputProps<T = string> {
  placeholder: string;
  value: T;
  onChange: (event: React.FormEvent, params: { newValue: T }) => void;
  onBlur: (event: React.FocusEvent, params?: BlurEvent<CustomerCode>) => void;
}

type Props = {
  setHasSelectedCustomerCode: React.Dispatch<React.SetStateAction<boolean>>;
  hasSelectedCustomerCode: boolean;
};

const CustomerEntry = ({
  hasSelectedCustomerCode,
  setHasSelectedCustomerCode,
}: Props) => {
  const { setQueryParams, queryParams } = useRateProQueryParams();

  const defaultOptions = ['Prospect', 'Other'];
  const { customerCodesResult } = useGetCustomerCodesData();

  const [suggestionValue, setSuggestionValue] = useState<string>('');
  const [customerCodes, setCustomerCodes] = useState<CustomerCodesApiResult>(
    [],
  );
  const [suggestions, setSuggestions] =
    useState<CustomerCodesApiResult>(defaultOptions);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialInput, setInitialInput] = useState<boolean>(true);

  useEffect(() => {
    if (customerCodesResult) {
      setCustomerCodes(customerCodesResult);
      setSuggestions(customerCodesResult);
      setIsLoading(false);
    }
  }, [customerCodesResult]);

  const getSuggestions = (value: string) => {
    const inputValue = value ? value.trim().toLowerCase() : '';

    const inputLength = inputValue.length;

    return inputLength === 0
      ? customerCodes
      : customerCodes.filter(
          (code) => code.toLowerCase().slice(0, inputLength) === inputValue,
        );
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestions(getSuggestions(value));
  };

  const getSuggestionValue = (suggestion: CustomerCode) => suggestion;

  const renderSuggestion = (suggestion: CustomerCode) => (
    <div>{suggestion}</div>
  );

  const handleInputChange = (
    _event: React.FormEvent,
    params: { newValue: string },
  ) => {
    setInitialInput(false);
    setSuggestionValue(params.newValue.toUpperCase().substring(0, 8));
    if (!suggestions.includes(params.newValue)) {
      setQueryParams({ ...queryParams, customerCode: '' });
      setHasSelectedCustomerCode(false);
    }
  };

  const onBlur = (
    _event: React.FocusEvent,
    params: { highlightedSuggestion: CustomerCode } | undefined,
  ) => {
    if (!hasSelectedCustomerCode) {
      setSuggestionValue('');
      setHasSelectedCustomerCode(false);
      setInitialInput(false);
    }
  };

  const inputProps: InputProps = {
    placeholder: '7 or 8 Digit Code',
    value: suggestionValue,
    onBlur: onBlur,
    onChange: handleInputChange,
  };

  const onSuggestionSelected = async (
    _event: any,
    { suggestion }: { suggestion: CustomerCode },
  ) => {
    setSuggestionValue(suggestion);
    setHasSelectedCustomerCode(true);
    setQueryParams({ ...queryParams, customerCode: suggestion });
  };

  const onDelete = () => {
    // Set the raw input value to empty string
    setSuggestionValue('');
    // Clear out any selected location
    setHasSelectedCustomerCode(false);
    setQueryParams({ ...queryParams, customerCode: '' });
  };

  const renderInputComponent = (inputProps: any) => (
    <div style={{ position: 'relative' }}>
      <input {...inputProps} />
      {inputProps.value && (
        <ClearButton onClick={onDelete}>
          <StyledCloseIcon icon="close" size={'12'} />
        </ClearButton>
      )}
    </div>
  );

  const shouldRenderSuggestions = () => true;

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Customer / Prospect Code</LabelText>
      <CustomerCodeInputContainer
        isError={!hasSelectedCustomerCode && !initialInput}
      >
        <Autosuggest
          suggestions={
            isLoading
              ? ['Loading...', ...defaultOptions]
              : [...suggestions, ...defaultOptions]
          }
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          onSuggestionSelected={onSuggestionSelected}
          highlightFirstSuggestion={true}
          shouldRenderSuggestions={shouldRenderSuggestions}
          multiSection={false}
        />
      </CustomerCodeInputContainer>
    </Flex>
  );
};

export default CustomerEntry;
