export type CreditRequestApiResultBody = {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  state_id: string;
  zip_code: string;
  contact_name: string;
  fax: number;
  email: string;
  bridge_id: string;
  collections_id: string;
  credit_rate: string;
  credit_limit: string;
  balance: string;
  unbilled: string;
};

export type CreditRequestAutoPopulateType = {
  customerId: string;
  customerName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  bridge: string;
  collectorId: string;
  creditRateCode: string;
  creditLimit: string;
  currentReceivables: string;
};

export type CreditRequestApiResult = {
  data: CreditRequestApiResultBody[];
  limit: number;
  page: number;
  count: number;
};

export type CreditRequestSearchQuery = {
  customerName: string;
  customerId: string;
  page: number;
};

type FormDataField = {
  errorMessage?: string;
  value: string;
};
export type CustomerInfoFormData = {
  customerId: FormDataField;
  customerName: FormDataField;
  addressLine1: FormDataField;
  addressLine2: FormDataField;
  city: FormDataField;
  state: FormDataField;
  zip: FormDataField;
  bridge: FormDataField;
  collectorId: FormDataField;
  creditRateCode: FormDataField;
};

export type NewAdjustmentFormData = {
  contactName: FormDataField;
  jobTitle: FormDataField;
  faxNumber: FormDataField;
  phoneNumber: FormDataField;
  email: FormDataField;
  businessObtained: FormDataField;
  businessObtainedAdditional: FormDataField;
  immediateCreditNeed: FormDataField;
  singleLoadAmount: FormDataField;
  multipleLoadAmount: FormDataField;
  potentialLongtermBusiness: FormDataField;
  comments: FormDataField;
};

export type AdjustmentFormData = {
  currentCreditLimit: FormDataField;
  currentReceivables: FormDataField;
  numberOfAdditionalLoads: FormDataField;
  additionalCreditRequested: FormDataField;
  singleLoadAmount: FormDataField;
  multipleLoadAmount: FormDataField;
};

export type AdjustmentCreditInfoFormData = {
  creditType: FormDataField;
  creditReason: FormDataField;
  creditComments: FormDataField;
};

export type CreditInfoFormData = {
  creditType: FormDataField;
  reason: FormDataField;
  creditComments: FormDataField;
};

export type CargoInfoFormData = {
  commodities: FormDataField;
  averageValue: FormDataField;
  maxExpectedValue: FormDataField;
  modeOfTransportation: FormDataField;
};

export type CreditRequestEmailData = {
  customerId: string;
  customerName: string;
  commodities: string;
  averageValue: string;
  maxExpectedValue: string;
  modeOfTransportation: string;
  singleLoadAmount: string;
  multipleLoadAmount: string;
  bridge: string;
  collectorId: string;
  creditRateCode: string;
};

export interface NewCreditRequestEmailData extends CreditRequestEmailData {
  contactName: string;
  jobTitle: string;
  faxNumber: string;
  phoneNumber: string;
  email: string;
  businessObtained: string;
  businessObtainedAdditional: string;
  immediateCreditNeed: string;
  potentialLongtermBusiness: string;
  comments: string;
}

export interface AdjustmentCreditRequestEmailData
  extends CreditRequestEmailData {
  currentCreditLimit: string;
  currentReceivables: string;
  numberOfAdditionalLoads: string;
  additionalCreditRequested: string;
  creditType: string;
  creditReason: string;
  creditComments: string;
}

export interface CreditCheckRequestParams {
  selectedCity: string;
  selectedState: string;
  countryCode: string;
  companyName: string;
}

export type DnbCompany = {
  address: {
    city: string;
    country: string;
    state: string;
    street: string;
    zipCode: string;
  };
  bureauIdentifierNumber: string;
  bureauName: string;
  businessName: string;
  locationIndicator: string;
  locationIndicatorExplanation: string;
  matchScore: number;
  telephone: string;
};

export interface DNBCompanyResponse {
  name: string;
  address: string;
  city: string;
  stateCode: USStateAbbreviation | CanadianProvinceAbbreviation;
  zipCode: string;
  country: CountryCode;
  headquarters: boolean;
  startDate?: string;
  controlDate?: string;
  numberOfEmployees?: number;
  publiclyOwned?: boolean;
  collectionsFound?: boolean;
  bankruptcyFound?: boolean;
  outOfBusiness?: boolean;
}

export interface DNBCreditInfo {
  dunsNumber: string;
  domesticUltimateDunsNumber?: string;
  globalUltimateDunsNumber?: string;
  dateOfDecisioning: Date;
  creditLimit?: number;
  paymentTerms?: string;
  dbRating?: string;
  paydex?: number;
  highCredit?: number;
  netWorth?: number;
  netProfitLoss?: number;
  revenue?: number;
  tangibleNetWorth?: number;
  dbViabilityRating?: string;
  delinquencyScore?: number;
  failureScore?: number;
  currentRatio?: number;
  debtToEquityRatio?: number;
  tradeExperiences?: number;
  negativePaymentTradeExperiencePercentage?: number;
}

export interface DNBCreditResponse {
  applicationId: string;
  status: string;
  companyInfo: DNBCompanyResponse;
  creditInfo: DNBCreditInfo;
}

export enum USStateAbbreviation {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum CanadianProvinceAbbreviation {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NS = 'NS',
  NT = 'NT',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}
