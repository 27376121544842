import * as React from 'react';
import RateProToolbar from './RateProToolbar';
import Card from 'components/Card';
import { useHubtekData, useMpactData, useRateData } from './useRateData';
import {
  HubtekRequestQuery,
  NewRateQuery,
  TrailerType,
  Rate,
  AccessorialsType,
  AccessorialFeesValuesKeys,
  AccessorialFeesValues,
} from './ratesTypes';
import { Flex } from 'components/Flex';
import SpinnerWithWait from 'components/Spinner';
import RateDisplay from './RateDisplay';
import { getSuggestedBuyRateNew } from './suggestedBuyRates/suggestedBuyRate';

import {
  GridContainer,
  Container,
  GridItem,
  ErrrorText,
  NoRateText,
  LargeText,
  MediumText,
  Line,
  GridColumn,
  LinksContainer,
} from './rateProStyles';
import {
  useGetAccessorialFeesData,
  useGetRateAdjustmentData,
} from '../rateProAdmin/useRateAdminData';
import { MpactCard } from './cards/MpactCard';
import { fetchContentfulData } from './contentfulApi';
import { RtsLoadHistoryRateDisplay } from './loadHistory/RtsLoadHistoryRateDisplay';
import { RateProErrorBoundary } from './loadHistory/RTSHistoryErrorBoundary';
import { useLoadHistoryData } from './loadHistory/hooks/useLoadHistoryData';
import { useState } from 'react';
import {
  SuggestedbuyRateCard,
  getSuggestedBuyRateStatus,
} from './cards/SuggestedBuyRateCard';
import { getRegionByState } from 'features/rateProAdmin/rateProAdminRegions';
import { SuggestedSellRateCard } from './cards/SuggestedSellRateCard';
import {
  AccessorialFeesApiResult,
  RateAdjustmentApiResult,
} from 'features/rateProAdmin/rateAdminTypes';
import FooterLinks from './Footer/FooterLinks';
import GreenscreenDisplay from './GreenscreenDisplay';

import Grid from '@material-ui/core/Grid';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as FirebaseUtils from '../../utils/firebaseUtils';

export const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 568, md: 775, lg: 1360, xl: 1610 },
  },
});

export const ErrorText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <ErrrorText>{text}</ErrrorText>
  </Flex>
);

export const NoDataText = ({ text }: { text: string }) => (
  <Flex height="100%" alignItems="center" justifyContent="center">
    <NoRateText>{text}</NoRateText>
  </Flex>
);

const RatePro = () => {
  const { rateAdjustmenResult } = useGetRateAdjustmentData();
  const [newBuyRate, setNewBuyRate] = useState<number>();
  const [searchQuery, setSearchQuery] = useState<NewRateQuery>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [dialogDescription, setDialogDescription] = useState<string>();

  const [sellRateQuery, setSellRateQuery] = useState<HubtekRequestQuery>();
  const [sellRateRecalculating, setSellRateRecalculating] =
    useState<boolean>(false);
  const [accessorialsValue, setAccessorialsValue] = useState<AccessorialsType>({
    driverAssist: false,
    hazmat: false,
    palletJack: false,
    ramps: false,
    sameDay: false,
    tankerEndorsed: false,
    tarps: false,
    team: false,
  });
  const [stops, setStops] = useState<number>(0);
  const [buyRateAccessorialAdjustment, setBuyRateAccessorialAdjustment] =
    useState<number>(0);

  React.useEffect(() => {
    const fetchData = async () => {
      const { title, description } = await fetchContentfulData();
      if (title) {
        setDialogTitle(title);
      }
      if (description) {
        setDialogDescription(description);
      }
    };

    fetchData();
  }, []);

  const {
    LoadHistoryResults: {
      isLoading: LoadHistoryIsLoading,
      data: loadHistoryData,
      error: LoadHistoryError,
    },
  } = useLoadHistoryData(searchQuery);

  const {
    RateViewResults: {
      isError: RateViewIsError,
      isLoading: RateViewIsLoading,
      data: RateViewData,
      rateViewAverage,
      rateViewHigh,
      rateViewLow,
      error: RateViewError,
      rateViewFuelCostPerTrip,
    },
    RateCastResults: {
      isError: RateCastIsError,
      isLoading: RateCastIsLoading,
      data: RateCastData,
      error: RateCastError,
    },
    SonarScoreResults: {
      isError: SonarScoreIsError,
      isLoading: SonarScoreIsLoading,
      data: SonarScoreData,
    },
    GreenscreenRTResults: {
      data: GreenscreenRTData,
      isError: GreenscreenRTIsError,
      error: GreenscreenRTError,
      isLoading: GreenscreenRTIsLoading,
    },
    GreenscreenNetworkResults: {
      data: GreenscreenNetworkData,
      isError: GreenscreenNetworkIsError,
      error: GreenscreenNetworkError,
      isLoading: GreenscreenNetworkIsLoading,
    },
    mileage,
    FirebaseLogsStatus: {
      isRateViewFirebaseReady,
      isGreenscreenRTFirebaseReady,
      isGreenscreenNetworkFirebaseReady,
    },
  } = useRateData(searchQuery);

  const {
    MpactDataResults: {
      isError: MpactDataIsError,
      isLoading: MpactDataIsLoading,
      data: MpactData,
      error: MpactDataError,
    },
  } = useMpactData(searchQuery);

  const {
    HubtekDataResults: {
      isError: HubtekDataIsError,
      isLoading: HubtekDataIsLoading,
      data: HubtekData,
    },
  } = useHubtekData(searchQuery, sellRateQuery);

  const { accessorialFeesResult: accessorialFees } =
    useGetAccessorialFeesData();
  let currentAccessorialFees: AccessorialFeesApiResult | undefined;
  if (accessorialFees && accessorialFees.length > 0) {
    currentAccessorialFees = accessorialFees[accessorialFees.length - 1];
  }
  // Calculate the total of the selected accessorials to add to the buy rate.
  React.useEffect(() => {
    if (currentAccessorialFees) {
      const savedAdditionalStops = currentAccessorialFees.additionalStops;
      const savedAccessorialsValues: AccessorialFeesValues = {
        driverAssist: currentAccessorialFees.driverAssist,
        hazmat: currentAccessorialFees.hazmat,
        palletJack: currentAccessorialFees.palletJack,
        ramps: currentAccessorialFees.ramps,
        sameDay: currentAccessorialFees.sameDay,
        tankerEndorsed: currentAccessorialFees.tankerEndorsed,
        tarps: currentAccessorialFees.tarps,
        team: currentAccessorialFees.team,
      };

      let totalAccessorialCost = 0;

      Object.keys(accessorialsValue).forEach((key) => {
        if (accessorialsValue[key as AccessorialFeesValuesKeys]) {
          totalAccessorialCost +=
            savedAccessorialsValues[key as AccessorialFeesValuesKeys];
        }
      });

      function stopValue(stops: number) {
        switch (stops) {
          case 1:
            return savedAdditionalStops.oneStop;
          case 2:
            return savedAdditionalStops.twoStops;
          case 3:
            return savedAdditionalStops.threeStops;
          case 4:
            return savedAdditionalStops.fourPlusStops;
          default:
            return 0;
        }
      }
      totalAccessorialCost += stopValue(stops);

      setBuyRateAccessorialAdjustment(totalAccessorialCost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialsValue, stops]);

  // Calculate the suggested buy rate and watch for changes.
  React.useEffect(() => {
    const getBuyRate = async () => {
      const originState = searchQuery?.pickupState;
      const rateAdjustmentApiResult: RateAdjustmentApiResult[] | undefined =
        rateAdjustmenResult;
      const sonarScore = SonarScoreData?.score;

      // The calculation is the same as before except with new RTS history data.
      const rtsHistoryRateAverage = loadHistoryData?.stats.avg;
      const rtsHistoryLoads = loadHistoryData?.totalResults;
      let equipmentType = searchQuery?.trailerType;

      if (
        originState === undefined ||
        rateAdjustmentApiResult === undefined ||
        rateViewAverage === undefined ||
        rateViewHigh === undefined ||
        rateViewLow === undefined ||
        equipmentType === undefined
      ) {
        return;
      }

      const region = getRegionByState(originState);

      const regionAdjustment = rateAdjustmentApiResult?.find(
        (e) => e.region === region || e.region === 'all',
      );

      let buyRateResult = await getSuggestedBuyRateNew({
        regionAdjustment,
        sonarScore,
        rateViewAverage,
        rateViewHigh,
        rateViewLow,
        rtsHistoryRateAverage,
        rtsHistoryLoads,
        equipmentType,
      });

      if (!buyRateResult) {
        return;
      }

      buyRateResult = buyRateResult + buyRateAccessorialAdjustment;
      setNewBuyRate(buyRateResult);
    };
    getBuyRate();
  }, [
    rateAdjustmenResult,
    rateViewAverage,
    rateViewHigh,
    rateViewLow,
    searchQuery,
    SonarScoreData,
    RateViewData,
    loadHistoryData,
    rateViewFuelCostPerTrip,
    buyRateAccessorialAdjustment,
  ]);

  const isEmpty = (data: undefined | Rate | Rate[]) => {
    if (!data) {
      return false;
    }

    if (Array.isArray(data)) {
      return data.length === 0;
    }

    return Object.keys(data).length === 0;
  };

  const getRateViewContent = () => {
    if (RateViewIsLoading) {
      return <SpinnerWithWait />;
    }

    if (
      (RateViewIsError && RateViewError.message === 'Rate not found.') ||
      isEmpty(RateViewData)
    ) {
      return <NoDataText text="No DAT Rateview Available" />;
    }

    if (RateViewIsError) {
      return <ErrorText text="Failed to return rates" />;
    }

    if (RateViewData) {
      return <RateDisplay {...RateViewData} />;
    }

    return null;
  };

  const getRateCastContent = () => {
    if (RateViewIsLoading || RateCastIsLoading) {
      return <SpinnerWithWait />;
    }

    if (
      (RateCastIsError && RateCastError.message === 'Rate not found.') ||
      isEmpty(RateCastData) ||
      (RateViewIsError && RateViewError.message === 'Rate not found.') ||
      isEmpty(RateViewData)
    ) {
      return <NoDataText text="No DAT Ratecast Available" />;
    }

    if (!mileage || mileage === 0) {
      return <NoDataText text="No DAT Ratecast Available" />;
    }

    if (RateCastIsError) {
      return <ErrorText text="Failed to return rates" />;
    }

    if (RateCastData) {
      return (
        <>
          <RateDisplay {...RateCastData[0]} />
          <Line />
          <RateDisplay {...RateCastData[1]} />
        </>
      );
    }

    return null;
  };

  const getGreenscreenRTContent = () => {
    if (GreenscreenRTIsLoading) {
      return <SpinnerWithWait />;
    }

    if (
      GreenscreenRTIsError &&
      GreenscreenRTError.message === 'Prediction not found.'
    ) {
      return <NoDataText text="No Greenscreen Prediction Available" />;
    }

    if (GreenscreenRTIsError) {
      return <ErrorText text="Failed to return rates" />;
    }

    if (GreenscreenRTData) {
      return <GreenscreenDisplay {...GreenscreenRTData} />;
    }

    return null;
  };

  const getGreenscreenNetworkContent = () => {
    if (GreenscreenNetworkIsLoading) {
      return <SpinnerWithWait />;
    }

    if (
      GreenscreenNetworkIsError &&
      GreenscreenNetworkError.message === 'Prediction not found.'
    ) {
      return <NoDataText text="No Greenscreen Prediction Available" />;
    }

    if (GreenscreenNetworkIsError) {
      return <ErrorText text="Failed to return rates" />;
    }

    if (GreenscreenNetworkData) {
      return <GreenscreenDisplay {...GreenscreenNetworkData} />;
    }

    return null;
  };

  const getSonarScoreContent = () => {
    if (searchQuery?.trailerType?.toUpperCase() === TrailerType.FLATBED) {
      return <NoDataText text="Sonar Score Unavailable for Flatbed" />;
    }

    if (SonarScoreIsLoading) {
      return <SpinnerWithWait />;
    }

    if (SonarScoreIsError) {
      return <NoDataText text="No Sonar Score Available" />;
    }

    if (SonarScoreData) {
      return (
        <Flex>
          <LargeText margin="0px 16px 0px 0px">
            {SonarScoreData.score}
          </LargeText>
          <MediumText bold={false} margin="auto 0">
            {SonarScoreData.description}
          </MediumText>
        </Flex>
      );
    }

    return null;
  };

  React.useEffect(() => {
    if (
      isRateViewFirebaseReady &&
      isGreenscreenNetworkFirebaseReady &&
      isGreenscreenRTFirebaseReady
    ) {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.SEARCH,
        FirebaseUtils.FirebaseModules.RATE_PRO,
        FirebaseUtils.FirebasePages.DAT_RATEVIEW,
        {
          pickupLocation: `${searchQuery?.pickupCity}, ${searchQuery?.pickupState}`,
          deliveryLocation: `${searchQuery?.deliveryCity}, ${searchQuery?.deliveryState}`,
          originZip: searchQuery?.pickupPostalCode,
          destinZip: searchQuery?.deliveryPostalCode,
          trailerType: searchQuery?.trailerType,
          customerCode: searchQuery?.customerCode,
          mileage: mileage,
          ryanLow: GreenscreenRTData?.lowBuyRate,
          ryanAve: GreenscreenRTData?.targetBuyRate,
          ryanHigh: GreenscreenRTData?.highBuyRate,
          ryanConfidence: GreenscreenRTData?.confidenceLevel,
          networkLow: GreenscreenNetworkData?.lowBuyRate,
          networkAve: GreenscreenNetworkData?.targetBuyRate,
          networkHigh: GreenscreenNetworkData?.highBuyRate,
          networkConfidence: GreenscreenNetworkData?.confidenceLevel,
          DATlowUsd: RateViewData?.mileRateLowHighText
            .split(' - ')[0]
            .replace(/[^\d.]/g, ''),
          DATaveUsd: RateViewData?.mileRate.replace(/[^\d.]/g, ''),
          DAThighUsd: RateViewData?.mileRateLowHighText
            .split(' - ')[1]
            .replace(/[^\d.]/g, ''),
          searchDate: new Date(),
          description: 'Check Rates - Rate Pro Results',
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isRateViewFirebaseReady,
    isGreenscreenNetworkFirebaseReady,
    isGreenscreenRTFirebaseReady,
  ]);

  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <GridContainer container>
          <GridItem item xs={12}>
            <RateProToolbar
              getRatesOnClick={setSearchQuery}
              mileage={mileage}
              isLoading={
                LoadHistoryIsLoading ||
                RateViewIsLoading ||
                RateCastIsLoading ||
                SonarScoreIsLoading ||
                MpactDataIsLoading ||
                HubtekDataIsLoading
              }
            />
          </GridItem>

          <GridColumn container xs={12} lg={6} xl={3}>
            <GridItem item xs={12}>
              <Card
                contentHeight="168px"
                primaryTitle="DAT"
                secondaryTitle="RATEVIEW"
              >
                {getRateViewContent()}
              </Card>
            </GridItem>
            <GridItem item xs={12}>
              <Card
                contentHeight="299px"
                primaryTitle="DAT"
                secondaryTitle="RATECAST"
              >
                {getRateCastContent()}
              </Card>
            </GridItem>
          </GridColumn>

          <GridColumn container xs={12} lg={6}>
            <Grid container xl={5}>
              <GridItem item xs={12} md={6} xl={12}>
                <Card
                  contentHeight="152px"
                  contentPadding="24px 16px"
                  primaryTitle="GREENSCREENS"
                  secondaryTitle="RYAN TRANS PREDICTION"
                >
                  {getGreenscreenRTContent()}
                </Card>
              </GridItem>
              <GridItem item xs={12} md={6} xl={12}>
                <Card
                  contentHeight="152px"
                  contentPadding="24px 16px"
                  primaryTitle="GREENSCREENS"
                  secondaryTitle="NETWORK PREDICTION"
                >
                  {getGreenscreenNetworkContent()}
                </Card>
              </GridItem>
            </Grid>
            <Grid container lg={12} xl={7}>
              <GridItem item xs={12} md={6} xl={12}>
                <Card
                  contentHeight="152px"
                  contentPadding="24px 16px"
                  primaryTitle="RTS"
                  secondaryTitle="HISTORY"
                >
                  <RateProErrorBoundary
                    fallbackText={'No RTS History Available'}
                  >
                    {loadHistoryData && loadHistoryData.totalResults === 0 ? (
                      <NoDataText text="No RTS History Available" />
                    ) : (
                      <>
                        {LoadHistoryIsLoading ? (
                          <SpinnerWithWait />
                        ) : (
                          <>
                            {!LoadHistoryError &&
                              loadHistoryData &&
                              searchQuery && (
                                <RtsLoadHistoryRateDisplay
                                  loadHistoryData={loadHistoryData}
                                />
                              )}
                          </>
                        )}
                      </>
                    )}
                  </RateProErrorBoundary>
                </Card>
              </GridItem>
              <GridItem item xs={12} md={6} xl={12}>
                <MpactCard
                  mpactData={MpactData}
                  mileage={mileage}
                  isError={MpactDataIsError}
                  isLoading={MpactDataIsLoading}
                  error={MpactDataError}
                />
              </GridItem>
            </Grid>
            <GridItem item xs={12}>
              <Card
                contentHeight="39px"
                contentPadding="24px 16px"
                secondaryTitle="SONAR SCORE"
              >
                {getSonarScoreContent()}
              </Card>
            </GridItem>
          </GridColumn>

          <GridColumn container xs={12} xl={3}>
            <GridItem item xs={12} lg={6} xl={12}>
              <SuggestedbuyRateCard
                buyRate={newBuyRate}
                cardState={getSuggestedBuyRateStatus(
                  SonarScoreIsLoading,
                  SonarScoreIsError,
                  HubtekDataIsLoading,
                  newBuyRate,
                )}
                helpDialogDescription={dialogDescription || ''}
                helpDialogTitle={dialogTitle || ''}
                sellRateRecalculating={sellRateRecalculating}
              />
            </GridItem>
            <GridItem item xs={12} lg={6} xl={12}>
              <SuggestedSellRateCard
                equiptmentType={searchQuery?.trailerType}
                hubtekData={HubtekData}
                isLoading={HubtekDataIsLoading}
                isError={HubtekDataIsError}
                sellRateRecalculating={sellRateRecalculating}
                setSellRateRecalculating={setSellRateRecalculating}
                mainSearchQuery={searchQuery}
                setSellRateQuery={setSellRateQuery}
                accessorialsValue={accessorialsValue}
                setAccessorialsValue={setAccessorialsValue}
                stops={stops}
                setStops={setStops}
                buyRate={newBuyRate}
              />
            </GridItem>
          </GridColumn>

          <Grid item xs={12}>
            <LinksContainer>
              <FooterLinks />
            </LinksContainer>
          </Grid>
        </GridContainer>
      </Container>
    </MuiThemeProvider>
  );
};

export default RatePro;
