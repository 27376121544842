import { useState } from 'react';
import CheckHistoryToolbar from './components/CheckHistoryToolbar';
import { Container, StyledCreditCheckContainer } from './creditRequestStyles';
import dayjs, { Dayjs } from 'dayjs';

const getHistoryType = (type: string) => {
  switch (type) {
    case 'My history':
    default:
      return 'USER';
    case 'All credit history':
      return 'ALL';
  }
};

const CheckHistory = () => {
  const defaultStartDate = dayjs().subtract(90, 'day');
  const defaultEndDate = dayjs();

  const [selectedHistoryFilter, setSelectedHistoryFilter] = useState({
    optionName: 'My history',
  });
  const [companyValue, setCompanyValue] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(defaultEndDate);

  const handleViewClick = async () => {
    let startDateForQuery = startDate?.format('YYYY-MM-DD');
    let endDateForQuery = endDate?.format('YYYY-MM-DD');

    // if the user clicks View without selecting a date, set the default date
    if (startDate === null) {
      startDateForQuery = defaultStartDate.format('YYYY-MM-DD');
      setStartDate(defaultStartDate);
    }

    if (endDate === null) {
      endDateForQuery = defaultEndDate.format('YYYY-MM-DD');
      setEndDate(defaultEndDate);
    }

    const getAuditRequestParams = {
      startDate: startDateForQuery,
      endDate: endDateForQuery,
      type: getHistoryType(selectedHistoryFilter.optionName),
      ...(companyValue && { company: companyValue }),
    };

    console.log(getAuditRequestParams);
    // TODO: implement call to `getAudit` endpoint to populate table with lazy query
  };

  const handleDownloadClick = () => {
    // TODO: Wire up download button
    console.log('Download button clicked');
  };

  return (
    <Container>
      <StyledCreditCheckContainer>
        <CheckHistoryToolbar
          selectedHistoryFilter={selectedHistoryFilter}
          companyValue={companyValue}
          startDate={startDate}
          endDate={endDate}
          setSelectedHistoryFilter={setSelectedHistoryFilter}
          setCompanyValue={setCompanyValue}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleViewClick={handleViewClick}
          handleDownloadClick={handleDownloadClick}
        />
      </StyledCreditCheckContainer>
      {/* TODO: Render getAudit Results Table Here */}
    </Container>
  );
};

export default CheckHistory;
