import { Flex } from 'components/Flex';
import {
  CloverIcon,
  LabelText,
  StyledCreditComponentRow,
  StyledCreditToolbarContainer,
  StyledDropdownContainer,
  StyledInput,
  StyledInputPillContainer,
  StyledSearchButton,
} from '../creditRequestStyles';
import { Icon, SelectInput } from 'shamrock-clover-ui';
import { MenuOption } from 'shamrock-clover-ui/dist/clover/components/Menus/SingleSelectMenu';
import { StyledMenuItemText } from 'features/carrierCapacity/components/Drawer/DetailsTableStyles';
import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  LocalizationProvider,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

const filterOptions: MenuOption[] = [
  { optionName: 'My history' },
  { optionName: 'All credit history' },
];

type CheckHistoryToolbarProps = {
  selectedHistoryFilter: MenuOption;
  companyValue: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  setSelectedHistoryFilter: (option: MenuOption) => void;
  setCompanyValue: (value: string) => void;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  handleViewClick: () => void;
  handleDownloadClick: () => void;
};

const CheckHistoryToolbar: FC<CheckHistoryToolbarProps> = ({
  selectedHistoryFilter,
  companyValue,
  startDate,
  endDate,
  setSelectedHistoryFilter,
  setCompanyValue,
  setStartDate,
  setEndDate,
  handleViewClick,
  handleDownloadClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // History only goes back 24 months
  const minDate = dayjs().subtract(24, 'month');

  const updateDates = (dates: DateRange<Dayjs>) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  const handleClearDates = () => {
    updateDates([null, null]);
  };

  const handleSelectedHistoryFilter = (option?: MenuOption) => {
    // The user must have a selected option
    if (option && option.optionName !== '') {
      setSelectedHistoryFilter(option);
    }
  };

  const updateCompany = (value: string) => {
    setCompanyValue(value);
  };

  const handleClearSearch = () => {
    setCompanyValue('');
  };

  return (
    <StyledCreditToolbarContainer>
      <StyledCreditComponentRow>
        <Flex
          width="150px"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LabelText>Filter</LabelText>
          <StyledDropdownContainer width="120px">
            <SelectInput
              onOptionSelected={handleSelectedHistoryFilter}
              value={{ optionName: `${selectedHistoryFilter.optionName}` }}
              options={filterOptions}
              renderOption={(option: MenuOption) => (
                <Flex alignItems="center">
                  <StyledMenuItemText>{option.optionName}</StyledMenuItemText>
                  {option.optionName === selectedHistoryFilter.optionName && (
                    <CloverIcon icon="check" size={'20'} />
                  )}
                </Flex>
              )}
            />
          </StyledDropdownContainer>
        </Flex>
        <Flex
          width="290px"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LabelText>Date Range</LabelText>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledInputPillContainer width="260px">
              <Icon icon="calendar" size="16" onClick={() => setIsOpen(true)} />
              <MuiDateRangePicker
                value={[dayjs(startDate), dayjs(endDate)]}
                onChange={updateDates}
                onOpen={() => {
                  setIsOpen(true);
                }}
                onClose={() => setIsOpen(false)}
                open={isOpen}
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{
                  leftArrowIcon: { sx: { width: '24px', height: '24px' } },
                  rightArrowIcon: { sx: { width: '24px', height: '24px' } },
                  day: {
                    sx: {
                      '.MuiDateRangePickerDay-day': { fontSize: '14px' },
                    },
                  },
                  layout: {
                    sx: {
                      '& .MuiDayCalendar-weekDayLabel': {
                        fontSize: '12px',
                        height: '20px',
                      },
                    },
                  },
                  calendarHeader: {
                    sx: {
                      '.MuiTypography-root': {
                        fontSize: '18px !important',
                        fontWeight: '700',
                      },
                    },
                  },
                  field: {
                    onClear: () => {
                      setIsOpen(true);
                    },
                    sx: {
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& .clearButton': {
                          opacity: 1,
                          height: '30px',
                          width: '30px',
                        },
                        fontSize: '14px',
                        color: '#333333',
                        '& fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiInputAdornment-root': {
                        '& svg': {
                          width: '24px',
                        },
                      },
                      '& .MuiInputBase-input': {
                        '&:placeholder': {
                          fontSize: '14px !important',
                        },
                      },
                    },
                    clearable: false,
                  },
                }}
                disableFuture
                minDate={minDate}
              />
              {(dayjs.isDayjs(startDate) || dayjs.isDayjs(endDate)) && (
                <Icon icon="close" size="16" onClick={handleClearDates} />
              )}
            </StyledInputPillContainer>
          </LocalizationProvider>
        </Flex>
        <Flex
          width="258px"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <LabelText>Company</LabelText>
          <StyledInputPillContainer width="225px">
            <StyledInput
              type="text"
              placeholder="Search by company name or DUNS#"
              value={companyValue}
              onChange={(e) => updateCompany(e.target.value)}
            />
            {companyValue.length > 0 && (
              <Icon icon="close" size="16" onClick={handleClearSearch} />
            )}
          </StyledInputPillContainer>
        </Flex>
        <div>
          <StyledSearchButton
            width="63px"
            height="36px"
            isRounded={true}
            onClick={handleViewClick}
          >
            View
          </StyledSearchButton>
        </div>
      </StyledCreditComponentRow>

      <Icon icon="download" onClick={handleDownloadClick} />
    </StyledCreditToolbarContainer>
  );
};

export default CheckHistoryToolbar;
