import {
  StyledCurrencyFormatTextInput,
  StyledFeesCard,
  StyledFeesCardContainer,
  StyledFeesCardHeader,
  StyledFeesText,
} from '../rateAdjustmentStyles';
import { LinearProgress } from 'shamrock-clover-ui';

interface Props {
  AccessoryType:
    | 'HAZMAT'
    | 'DRIVER ASSIST'
    | 'PALLET JACK'
    | 'RAMPS'
    | 'SAME DAY'
    | 'TARPS'
    | 'TANKER ENDORSED'
    | 'TEAM';
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const AccessorialFeesCard: React.FC<React.PropsWithChildren<Props>> = ({
  AccessoryType,
  value,
  onChange,
  isLoading,
}) => {
  return (
    <StyledFeesCard width="200px" height="131px">
      <StyledFeesCardHeader>
        <StyledFeesText>{AccessoryType}</StyledFeesText>
      </StyledFeesCardHeader>
      {isLoading && <LinearProgress />}
      <StyledFeesCardContainer>
        <StyledCurrencyFormatTextInput
          allowNegative={false}
          label="Flat fee"
          value={value}
          onChange={onChange}
          numberFormatProps={{
            fixedDecimalScale: true,
            decimalScale: 2,
          }}
          paddingTop={'16px'}
          isLoading={isLoading}
        />
      </StyledFeesCardContainer>
    </StyledFeesCard>
  );
};

export default AccessorialFeesCard;
