import React from 'react';
import { SecondaryNavigation, CloverThemeProvider } from 'shamrock-clover-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { StyledSecondaryNavigationContainer } from '../creditRequestStyles';

const getStartingIndexFromPath = (path: string): number => {
  switch (path) {
    case '/credit':
      return 0;
    case '/credit/checkhistory':
      return 1;
    case '/credit/creditrequest':
      return 2;
    default:
      return 0;
  }
};

const CreditSubHeader: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Use memo to prevent extra re-rendering
  const startingIndex = React.useMemo(
    () => getStartingIndexFromPath(location.pathname.toLowerCase()),
    [location.pathname],
  );

  const handleCreditCheckClicked = () => {
    navigate(`/credit`);
  };

  const handleCheckHistoryClicked = () => {
    navigate(`/credit/checkHistory`);
  };

  const handleCreditRequestClicked = () => {
    navigate(`/credit/creditRequest`);
  };

  const tabs = [
    {
      title: 'Credit Check',
      onClick: handleCreditCheckClicked,
    },
    {
      title: 'Check History',
      onClick: handleCheckHistoryClicked,
    },
    {
      title: 'Credit Request',
      onClick: handleCreditRequestClicked,
    },
  ];

  return (
    <CloverThemeProvider>
      <StyledSecondaryNavigationContainer className="secondaryNavigation">
        <SecondaryNavigation
          tabs={tabs}
          startingIndex={startingIndex}
          key={startingIndex}
        />
      </StyledSecondaryNavigationContainer>
    </CloverThemeProvider>
  );
};

export default CreditSubHeader;
