import * as React from 'react';
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  EquipmentContainer,
} from '../rateAdjustmentStyles';

import FlatbedSonarCard from './FlatbedSonarCard';
import FlatbedHistoryCard from './FlatbedHistoryCard';

const FlatbedRow = () => {
  return (
    <StyledCard height="auto">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          Flatbed loads
        </RegionTitleText>
        <EquipmentContainer flexDirection="row" flexWrap="wrap">
          <FlatbedSonarCard />
          <FlatbedHistoryCard />
        </EquipmentContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default FlatbedRow;
