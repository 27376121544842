import usStates from './usStates.json';
import canadaStates from './canadaStates.json';
import { CountryCode } from 'features/creditRequest/creditRequestTypes';

export const getStateOrProvinceAbbreviation = (
  stateOrProvince: string,
): string => {
  const usState = usStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  if (usState) {
    return usState;
  }
  const caProvince = canadaStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  if (caProvince) {
    return caProvince;
  }
  // If neither US state nor CA province is found, return an empty string
  return '';
};

export const getStateOrProvinceName = (stateOrProvince: string) => {
  const usState = usStates.find(
    (state) => state.abbreviation === stateOrProvince,
  )?.name;
  if (usState) {
    return usState;
  }
  const caProvince = canadaStates.find(
    (state) => state.abbreviation === stateOrProvince,
  )?.name;
  if (caProvince) {
    return caProvince;
  }
  // If neither US state nor CA province is found, return an empty string
  return '';
};

export const getCountryCodeForStateOrProvince = (
  stateOrProvince: string,
): CountryCode | '' => {
  const usState = usStates.find(
    (state) => state.abbreviation === stateOrProvince,
  );
  if (usState) {
    return CountryCode.US;
  }
  const caProvince = canadaStates.find(
    (state) => state.abbreviation === stateOrProvince,
  );
  if (caProvince) {
    return CountryCode.CA;
  }
  return '';
};
