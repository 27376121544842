import { Flex } from 'components/Flex';
import { styled } from 'shamrock-clover-ui';
import QuotingCard from './QuotingCard';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import QuotingDrawerEnhamcements from './QuotingDrawerEnhancements';
import SpinnerWithWait from '../../../components/LoadingSpinner';
import { InitialState } from '../hooks/useQuoteData';
import { useContext } from 'react';
import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import { useLocation } from 'react-router';
import { sortQuotes } from '../utils/quoteUtils';
import { Snackbar } from '@material-ui/core';
import PausedUserSelector from 'components/PausedUserSelector';
import { Quote } from '../utils/quoteTypes';

const ErrorAlert = styled.p`
  background-color: red;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 5px;
`;

const Container = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  overflow: hidden;
`;

const QuotingCardsContainer = styled(Flex)`
  overflow-y: auto;
  width: 600px;
`;

const QuotingEnhancement = ({ filteredType }: { filteredType: string }) => {
  // TODO: Thoughts about using a prop instead of reading from pathname?
  const {
    quotes: allQuotes,
    loading,
    loadingDrawer,
    errorHandler,
    setErrorHandler,
  } = useContext(QuotesEnhancementsContext);

  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const type = !path ? 'new' : path;

  const quotes = allQuotes[type as keyof InitialState] || [];
  quotes.sort((aQuote, bQuote) => {
    return sortQuotes(type, aQuote, bQuote);
  });

  const {
    queryParams: { orderId, quoteId },
  } = useQuotingQueryParams();

  const selectedQuote = quotes.find(
    (x: Quote) => x.orderId === orderId && x.quotes[0]._id === quoteId,
  );

  const handleOnClose = () => setErrorHandler(false);

  return (
    <>
      {<PausedUserSelector />}
      <Container height="calc(100vh - 198px)">
        {loading && quotes.length === 0 ? (
          <SpinnerWithWait useWait={false} />
        ) : quotes.length !== 0 ? (
          <>
            <QuotingCardsContainer
              flexDirection="column"
              padding="20px 16px 0px 16px"
            >
              {quotes.map((x: any) => (
                <QuotingCard key={x._id} quote={x} type={type} />
              ))}
            </QuotingCardsContainer>
            {loadingDrawer ? (
              <Flex
                flexDirection="column"
                width="calc(100% - 633px)"
                alignItems="center"
                justifyContent="center"
              >
                <SpinnerWithWait useWait={false} />
              </Flex>
            ) : (
              <Flex
                flexDirection="column"
                width="calc(100% - 633px)"
                alignItems="center"
                justifyContent="center"
              >
                <QuotingDrawerEnhamcements quote={selectedQuote} />
              </Flex>
            )}
          </>
        ) : (
          <>
            <QuotingCardsContainer
              flexDirection="column"
              padding="20px 16px 0px 16px"
            />
            <Flex
              flexDirection="column"
              width="calc(100% - 633px)"
              alignItems="center"
              justifyContent="center"
            >
              <QuotingDrawerEnhamcements quote={selectedQuote} />
            </Flex>
          </>
        )}
      </Container>
      <Snackbar
        open={errorHandler}
        autoHideDuration={3000}
        onClose={handleOnClose}
      >
        <ErrorAlert>Quote Already Taken</ErrorAlert>
      </Snackbar>
    </>
  );
};

export default QuotingEnhancement;
