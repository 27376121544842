import * as React from 'react';
import {
  CreditRequestApiResult,
  CreditRequestAutoPopulateType,
} from './creditRequestTypes';
import Toolbar from './CreditRequestHeader/Toolbar';
import SearchTable from './SearchTable';
import { useEffect, useState } from 'react';
import { creditRequestService } from './creditRequestHttp';
import Spinner from 'components/Spinner';
import CreditRequestPanelContainer from './creditRequestPanel/container/CreditRequestPanelContainer';
import {
  Container,
  SpinnerContainer,
  NoSearchText,
} from './creditRequestStyles';
import useSnackbar from '../../hooks/useSnackbar';

import { useCreditRequestQueryParams } from '../../hooks/useQueryParams';

const CreditRequest = () => {
  const { showSnackbar } = useSnackbar();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [creditRequestData, setCreditRequestData] =
    useState<CreditRequestApiResult>();
  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [isPanelOpen, toggleIsPanelOpen] = useState<boolean>(false);
  const [autoPopulateData, setAutoPopulateData] =
    useState<CreditRequestAutoPopulateType>({
      customerName: '',
      customerId: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      bridge: '',
      collectorId: '',
      creditRateCode: '',
      creditLimit: '',
      currentReceivables: '',
    });

  const {
    queryParams: { customerName, customerId, requestType },
  } = useCreditRequestQueryParams();

  const onSearch = async (isPageChange?: boolean) => {
    toggleIsLoading(true);
    setCreditRequestData(undefined);
    var name = customerName ? customerName : '';
    var id = customerId ? customerId : '';
    try {
      const response = await creditRequestService.getCreditRequestData({
        customerName: name,
        customerId: id,
        page: isPageChange ? pageNumber : 1,
      });
      setCreditRequestData(response);
    } catch (e) {
      console.log('error: ' + e);
    }

    toggleIsLoading(false);
  };

  useEffect(() => {
    onSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <Container>
      <Toolbar onSearch={onSearch} />
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : creditRequestData ? (
        <>
          <SearchTable
            creditRequestData={creditRequestData}
            setPageNumber={setPageNumber}
            toggleIsPanelOpen={toggleIsPanelOpen}
            setAutoPopulateData={setAutoPopulateData}
          />
        </>
      ) : (
        <NoSearchText>Search for a customer</NoSearchText>
      )}
      <CreditRequestPanelContainer
        toggleIsPanelOpen={toggleIsPanelOpen}
        isPanelVisible={isPanelOpen}
        autoPopulateData={autoPopulateData}
        showSnackbar={showSnackbar}
        requestType={requestType ? requestType : 'new'}
      />
    </Container>
  );
};

export default CreditRequest;
