import * as React from 'react';
import { FormControl, Select, MenuItem, withStyles } from '@material-ui/core';
import { Flex } from '../../components/Flex';
import { useRateProQueryParams } from '../../hooks/useQueryParams';
import { LabelText } from './rateProStyles';

const trailerTypes = ['Van', 'Reefer', 'Flatbed'];

const StyledFormControl = withStyles({
  root: {
    width: '126px',
    height: '36px',
    background: 'white',
    borderRadius: '20px',
  },
})(FormControl);

const StyledMenuItem = withStyles({
  root: {
    fontSize: '14px',
  },
})(MenuItem);

const StyledSelect = withStyles({
  root: {
    fontSize: '14px',
  },
  select: {
    fontSize: '14px',
    paddingLeft: '15px',
    paddingTop: '10px',
    fontFamily: 'Proxima Nova',
  },
})(Select);

const TrailerPicker = () => {
  const { addQueryParams, queryParams } = useRateProQueryParams();

  const selectedTrailer = queryParams.trailerType
    ? (queryParams.trailerType as string)
    : 'placeholder';

  const onChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: any }>,
  ) => {
    addQueryParams({ trailerType: event.target.value });
  };

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-evenly">
      <LabelText>Trailer</LabelText>
      <StyledFormControl>
        <StyledSelect
          MenuProps={{ variant: 'menu' }}
          disableUnderline
          value={selectedTrailer}
          onChange={onChange}
        >
          <StyledMenuItem disabled value="placeholder">
            Trailer Type
          </StyledMenuItem>
          {trailerTypes.map((trailer) => (
            <StyledMenuItem key={trailer} value={trailer}>
              {trailer}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Flex>
  );
};

export default TrailerPicker;
