import Card from '../../../components/Card';
import {
  LargeText,
  MediumText,
  Text,
  VerticalLine,
  NoRateText,
  RateType,
  RateTypeDivider,
} from '../rateProStyles';
import { MpactApiResult } from '../mpact/mpactTypes';
import { Flex } from 'components/Flex';
import { numberToWholeCurrencyString } from 'utils/numberUtils';
import SpinnerWithWait from 'components/Spinner';

type Props = {
  mpactData?: MpactApiResult;
  mileage?: number;
  isError?: boolean;
  isLoading?: boolean;
  error?: Error;

  legacyCard?: boolean;
};

export const MpactCard = ({
  mpactData,
  mileage,
  isError,
  isLoading,
  error,
  legacyCard,
}: Props) => {
  const getContent = () => {
    if (isLoading) {
      return <SpinnerWithWait />;
    }

    if (
      isError ||
      (mpactData &&
        (!mpactData?.buyData?.average ||
          !mpactData?.buyData?.minimum ||
          !mpactData?.buyData?.maximum ||
          !mpactData?.sellData?.average ||
          !mpactData?.sellData?.minimum ||
          !mpactData?.sellData?.maximum))
    ) {
      return (
        <Flex height="100%" alignItems="center" justifyContent="center">
          <NoRateText>No Mpact Rates Available</NoRateText>
        </Flex>
      );
    }

    // Default state when the page loads and we haven't fetched rates yet
    if (!mpactData || !mileage) {
      return null;
    }

    return (
      <Flex flexDirection="row">
        <Flex flexDirection="column" style={{ flexGrow: 1 }}>
          <Flex flexDirection="row">
            <LargeText margin="0px 0px 0px 0px">
              {mpactData?.buyData?.average && mileage
                ? numberToWholeCurrencyString(
                    mpactData?.buyData?.average * mileage,
                  )
                : ''}
            </LargeText>
            <MediumText margin="18px 0px 0px 5px" bold={true}>
              CARRIER
            </MediumText>
          </Flex>
          <Text>{`${
            mpactData?.buyData?.minimum && mileage
              ? numberToWholeCurrencyString(
                  mpactData?.buyData?.minimum * mileage,
                )
              : ''
          } - ${
            mpactData?.buyData?.maximum && mileage
              ? numberToWholeCurrencyString(
                  mpactData?.buyData?.maximum * mileage,
                )
              : ''
          }`}</Text>
          {mpactData?.buyData?.sourceInfo?.days &&
          mpactData?.buyData?.sourceInfo?.orders &&
          mpactData?.buyData?.sourceInfo?.orders > 0 ? (
            <Flex margin="10px 0px 0px 0px">
              <RateType selected={true} alignContent="center" flexWrap="wrap">
                {mpactData.buyData?.sourceInfo?.days} Days
              </RateType>
              <RateTypeDivider alignItems="center" justifyContent="center">
                |
              </RateTypeDivider>
              <RateType
                selected={false}
                alignItems="center"
                justifyContent="center"
              >
                {mpactData.buyData?.sourceInfo?.orders} Experiences
              </RateType>
            </Flex>
          ) : null}
        </Flex>
        <VerticalLine style={{ flexShrink: 0 }} />
        <Flex flexDirection="column" style={{ flexGrow: 1 }}>
          <Flex flexDirection="row">
            <LargeText margin="0px 0px 0px 0px">
              {mpactData?.sellData?.average && mileage
                ? numberToWholeCurrencyString(
                    mpactData?.sellData?.average * mileage,
                  )
                : ''}
            </LargeText>
            <MediumText margin="18px 0px 0px 5px" bold={true}>
              CUSTOMER
            </MediumText>
          </Flex>
          <Text>{`${
            mpactData?.sellData?.minimum && mileage
              ? numberToWholeCurrencyString(
                  mpactData?.sellData?.minimum * mileage,
                )
              : ''
          } - ${
            mpactData?.sellData?.maximum && mileage
              ? numberToWholeCurrencyString(
                  mpactData?.sellData?.maximum * mileage,
                )
              : ''
          }`}</Text>
          {mpactData?.sellData?.sourceInfo?.days &&
          mpactData?.sellData?.sourceInfo?.days > 0 &&
          mpactData?.sellData?.sourceInfo?.orders &&
          mpactData?.sellData?.sourceInfo?.orders > 0 ? (
            <Flex margin="10px 0px 0px 0px">
              <RateType selected={true} alignContent="center" flexWrap="wrap">
                {mpactData.sellData?.sourceInfo?.days} Days
              </RateType>
              <RateTypeDivider alignItems="center" justifyContent="center">
                |
              </RateTypeDivider>
              <RateType
                selected={false}
                alignItems="center"
                justifyContent="center"
              >
                {mpactData.sellData?.sourceInfo?.orders} Experiences
              </RateType>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Card
        contentHeight={legacyCard ? '113px' : '152px'}
        contentPadding={legacyCard ? '16px' : '24px 16px'}
        secondaryTitle="MPACT PRO RATES"
        legacyCard={legacyCard}
      >
        {getContent()}
      </Card>
    </>
  );
};
