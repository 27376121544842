import { Tooltip } from 'shamrock-clover-ui';
import {
  StyledCompanyNotFoundContainer,
  StyledCompanyNotFoundIcon,
} from '../creditRequestStyles';
import { FC } from 'react';

const CompanyNotFoundButton: FC = () => {
  const emailBody = `Credit Request for: \nCompany: \nAddress: \nURL: \n\nExpect an email response within 2 business days.`;
  const mailtoLink =
    'mailto:prechecks@ryantrans.com?subject=' +
    encodeURIComponent('Manual Credit Request') +
    '&body=' +
    encodeURIComponent(emailBody);

  return (
    <Tooltip
      title="Send an email for a manual request."
      placement="top"
      style={{ transitionDelay: '.8s', transitionDuration: '0s' }}
    >
      <StyledCompanyNotFoundContainer
        onClick={() => window.location.assign(mailtoLink)}
      >
        <div>Company not found</div>
        <StyledCompanyNotFoundIcon
          icon="faq"
          size="25"
          className="companyNotFoundIcon"
        />
      </StyledCompanyNotFoundContainer>
    </Tooltip>
  );
};

export default CompanyNotFoundButton;
