import { Flex } from 'components/Flex';
import {
  LargeText,
  MediumText,
  SmallText,
  RateType,
  RateTypeDivider,
} from '../rateProStyles';
import { RtsHistoryDialog } from './RtsHistoryDialog';
import React from 'react';
import {
  numberToCurrencyString,
  numberToWholeCurrencyString,
} from 'utils/numberUtils';
import { RtsLoadHistoryApiResponse } from './types';

const TIMEFRAME = '30 Days';

type RtsLoadHistoryProps = {
  loadHistoryData: RtsLoadHistoryApiResponse;
};

export const RtsLoadHistoryRateDisplay: React.FC<
  React.PropsWithChildren<RtsLoadHistoryProps>
> = ({ loadHistoryData }) => {
  const experiencesText = loadHistoryData?.totalResults
    ? `${loadHistoryData?.totalResults} Experiences`
    : undefined;
  const mileRate = loadHistoryData.stats.avgPerMile;
  const mileRateLow = loadHistoryData.stats.minPerMile;
  const mileRateHigh = loadHistoryData.stats.maxPerMile;
  const tripRate = loadHistoryData.stats.avg;
  const tripRateLow = loadHistoryData.stats.min;
  const tripRateHigh = loadHistoryData.stats.max;

  const tripRateLowHighText = `${numberToWholeCurrencyString(
    tripRateLow,
  )} - ${numberToWholeCurrencyString(tripRateHigh)}`;
  const mileRateLowHighText = `${numberToCurrencyString(
    mileRateLow,
  )} - ${numberToCurrencyString(mileRateHigh)}`;
  const tripRateText = `${numberToWholeCurrencyString(tripRate)}`;
  const mileRateText = `${numberToCurrencyString(mileRate)} RPM`;

  return (
    <div>
      <Flex width="100%" justifyContent="space-between">
        <Flex>
          <LargeText>{tripRateText}</LargeText>
          <MediumText margin="18px 0px 0px 5px" bold={true}>
            BUY
          </MediumText>
        </Flex>
        <MediumText margin="18px 0px 0px 0px" bold={true}>
          {mileRateText}
        </MediumText>
      </Flex>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        margin="-5px 0px 0px 0px"
      >
        <MediumText bold={false}>{tripRateLowHighText}</MediumText>
        <SmallText>{mileRateLowHighText}</SmallText>
      </Flex>
      <Flex margin="14px 0px 0px 0px">
        <RateType selected={true} alignItems="center" justifyContent="center">
          {TIMEFRAME}
        </RateType>
        {experiencesText && (
          <RateTypeDivider alignItems="center" justifyContent="center">
            |
          </RateTypeDivider>
        )}
        <RateType alignItems="center" justifyContent="center">
          {experiencesText}
        </RateType>
        <>
          <RateTypeDivider alignItems="center" justifyContent="center">
            |
          </RateTypeDivider>
          <RateType alignItems="center" justifyContent="center">
            <RtsHistoryDialog loadHistoryData={loadHistoryData} />
          </RateType>
        </>
      </Flex>
    </div>
  );
};
