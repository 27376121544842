import {
  RtsLoadHistoryApiResponse,
  RtsLoadHistoryRow,
} from 'features/ratePro/loadHistory/types';
import { REACT_APP_RTS_HISTORY_BASE_URL } from 'utils/constants';

import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

export class RatesService extends Service {
  constructor() {
    super(REACT_APP_RTS_HISTORY_BASE_URL as string);
  }

  async getCarrierLoadHistory(carrierId: string): Promise<RtsLoadHistoryRow[]> {
    const LOOKBACK_DAYS = '90';
    const url = `/rates/history?carrier=${carrierId}&lookbackDays=${LOOKBACK_DAYS}`;

    const response: AxiosResponse<RtsLoadHistoryApiResponse> =
      await this.get(url);
    return response.data.data;
  }
}
export const ratesService = new RatesService();
