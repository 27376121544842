import { Flex } from 'components/Flex';
import { styled } from 'shamrock-clover-ui';
import Grid from '@material-ui/core/Grid';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
`;

export const GridContainer = styled(Grid)`
  height: 100%;
  padding: 16px 64px;
  max-width: 1920px;
`;

const GridWithCustomSizing = ({ ...other }) => {
  const gridClass = `rate-pro-grid`;
  return <Grid className={gridClass} {...other} />;
};

export const GridItem = styled(Grid)`
  padding: 8px;
`;
export const GridColumn = styled(GridWithCustomSizing)`
  max-height: fit-content;
`;

export const GridArea = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
`;

export const Text = styled(Flex)<{ italic?: boolean }>`
  font-style: ${({ italic }) => italic && 'italic'};
  font-size: ${({ theme }) => theme.font.size.small};
  color: ${({ theme }) => theme.colors.gray[90]};
`;

export const FuelText = styled(Text)`
  float: right;
  margin-top: 10px;
`;

export const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.gray[70]};
  margin: 0px 16px;
`;

export const Link = styled.a`
  text-decoration: none;
  color: #0091ea;
`;

export const LabelText = styled.div`
  padding-left: 16px;
  margin-bottom: 4px;
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray[70]};
  text-transform: uppercase;
`;

export const ErrrorText = styled.div`
  color: ${({ theme }) => theme.colors.red[50]};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const NoRateText = styled.div`
  color: ${({ theme }) => theme.colors.gray[50]};
  font-size: ${({ theme }) => theme.font.size.small};
`;

export const LargeText = styled.div<{ margin?: string; fontSize?: string }>`
  font-size: ${({ fontSize, theme }) =>
    fontSize ? fontSize : theme.font.size.xlarge};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.gray[90]};
  margin: ${({ margin }) => margin && margin};
`;

export const MediumText = styled.div<{ bold: boolean; margin?: string }>`
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.font.weight.bold : theme.font.weight.medium};
  color: ${({ theme }) => theme.colors.gray[90]};
  margin: ${({ margin }) => margin && margin};
`;

export const SmallText = styled.div<{ margin?: string }>`
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: ${({ theme }) => theme.colors.gray[90]};
  margin: ${({ margin }) => margin && margin};
`;

export const RateType = styled(Flex)<{ selected?: boolean }>`
  padding: 0px 8px;
  color: ${({ theme }) => theme.colors.gray[90]};
  background: ${({ theme, selected }) => selected && theme.colors.gray[20]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  height: 25px;
`;

export const RateTypeDivider = styled(Flex)`
  padding: 0px 3px;
  color: ${({ theme }) => theme.colors.gray[30]};
`;

export const CalculatedText = styled(Flex)`
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: ${({ theme }) => theme.colors.gray[50]};
  cursor: pointer;
`;

export const Line = styled.div`
  background-color: #d8d8d8;
  height: 1px;
  margin: 12px 0px;
  width: 100%;
`;

export const VerticalLine = styled.div`
  background-color: #d8d8d8;
  margin: 0px 12px;
  width: 1px;
`;

export const ConfidenceScore = styled.div`
  padding: 0px;
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  height: 25px;
`;

export const LinksContainer = styled.div<{ margin?: string }>`
  @media (max-width: 1610px) {
    margin-top: 0px;
  }
  margin-top: -100px;
  width: 600px;
  margin-left: 10px;
`;
