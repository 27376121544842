import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import {
  clearLocalStorageAuth,
  getToken,
  redirectToLogout,
} from '../utils/authUtils';

export default class Service {
  protected static axios: AxiosInstance;
  protected baseURL: string;

  public static getAxiosInstance() {
    return this.axios;
  }

  constructor(url: string) {
    if (!Service.axios) this.createAxios();
    this.baseURL = url;
  }

  private createAxios(): void {
    let token;
    let headers;

    try {
      token = getToken();
    } catch (e) {}

    if (token) headers = { Authorization: `Bearer ${token}` };
    Service.axios = Axios.create({ headers });
  }

  public static async addInterceptors(
    isAuth0Auth: boolean,
    logout: () => Promise<void>,
    tokenGenerator?: () => Promise<string>,
  ) {
    Service.axios.interceptors.request.use(
      async (
        config: InternalAxiosRequestConfig,
      ): Promise<InternalAxiosRequestConfig> => {
        config.headers = config.headers || {};
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        } else if (tokenGenerator) {
          const generatedToken = await tokenGenerator();
          config.headers.Authorization = `Bearer ${generatedToken}`;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );

    Service.axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if ([401, 403].includes(error?.response?.status)) {
          const token = getToken();
          if (!token && isAuth0Auth && logout) {
            await logout();
          } else {
            clearLocalStorageAuth();
            redirectToLogout(isAuth0Auth, logout);
          }
        }
        return Promise.reject(new Error(error));
      },
    );
  }

  async get(path: string, config?: AxiosRequestConfig<unknown>) {
    return Service.axios.get(`${this.baseURL}${path}`, config);
  }

  async post(
    path: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
  ) {
    return Service.axios.post(`${this.baseURL}${path}`, data, config);
  }

  async patch(
    path: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
  ) {
    return Service.axios.patch(`${this.baseURL}${path}`, data, config);
  }

  async delete(path: string, config?: AxiosRequestConfig<unknown>) {
    return Service.axios.delete(`${this.baseURL}${path}`, config);
  }
  async put(
    path: string,
    data?: unknown,
    config?: AxiosRequestConfig<unknown>,
  ) {
    return Service.axios.put(`${this.baseURL}${path}`, data, config);
  }

  async request(path: string, method: string, body: unknown) {
    return Service.axios.request({
      url: `${this.baseURL}${path}`,
      method,
      data: body,
    });
  }
}
