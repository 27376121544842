import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { quoteService } from '../utils/quotesHttp';
import { QuoteHistoryItem, Quote } from '../utils/quoteTypes';

export const updateHistory = (
  quotes: Quote[],
  newHistoryItem: QuoteHistoryItem,
  userId: string,
  orderId: string,
) => {
  return quotes.map((x) => {
    if (x.orderId === orderId) {
      const newQuotes = x.quotes.map((y) => {
        if (y.userId === userId) {
          return { ...y, history: [...y.history, newHistoryItem] };
        }
        return y;
      });
      return {
        ...x,
        quotes: newQuotes,
      };
    }
    return x;
  });
};

export interface InitialState {
  new: Quote[];
  negotiating: Quote[];
  verifying: Quote[];
  booked: Quote[];
  dismissed: Quote[];
}

interface QuoteDataQuery {
  status?: {};
}
export const useQuoteDataEnhancements = (query: QuoteDataQuery | null) => {
  const queryClient = useQueryClient();
  const [quotes, setQuotes] = useState<InitialState>({
    new: [],
    negotiating: [],
    verifying: [],
    booked: [],
    dismissed: [],
  });
  const status = query?.status || 'new';
  const queryKey = ['enhancements', status];
  const requestQuote = useQuery(
    queryKey,
    () => quoteService.getQuotes({ status }),
    {
      refetchInterval: 10000,
    },
  );
  const { isSuccess, data, isLoading } = requestQuote;

  useEffect(() => {
    if (isSuccess) {
      setQuotes((prevQuotes) => ({
        ...prevQuotes,
        [status as keyof InitialState]: data,
      }));
    }
  }, [isSuccess, status, data]);

  const { mutate: setAdminAssingMutation, status: isLoadingsetAdminAssing } =
    useMutation(quoteService.setAdminQuote, {
      onSuccess: (updatedData) => {
        queryClient.invalidateQueries(queryKey);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    });

  const { mutateAsync: acceptQuoteMutation, isLoading: isLoadingAcceptQuote } =
    useMutation(quoteService.acceptQuote, {
      onSuccess: (updatedData) => {
        if (quotes[status as keyof InitialState].length !== 0) {
          requestQuote.isLoading = isLoadingAcceptQuote;
          setQuotes({ ...quotes, [status as keyof InitialState]: updatedData });
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    });

  return {
    ...quotes,
    isLoadingsetAdminAssing,
    isLoading: isLoading,
    setAdminQuote: async (orderId: string) => {
      const result = await setAdminAssingMutation({ orderId });
      return result;
    },
    acceptQuote: async (orderId: string, userId: string) => {
      await acceptQuoteMutation({ orderId, userId });
    },
  };
};

export const useQuoteData = (obj: object | null) => {
  const queryClient = useQueryClient();
  const quotesResult = useQuery('quotes', () => quoteService.getQuotes(obj!));

  const { mutateAsync: acceptQuoteMutation, isLoading: isLoadingAcceptQuote } =
    useMutation(quoteService.acceptQuote, {
      onSuccess: (data, { orderId, userId }) => {
        if (quotesResult.data) {
          queryClient.setQueryData(
            'quotes',
            updateHistory(quotesResult.data, data, userId, orderId),
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries('quotes');
      },
    });

  const {
    mutateAsync: counterQuoteMutation,
    isLoading: isLoadingCounterQuote,
  } = useMutation(quoteService.counterQuote, {
    onSuccess: (data, { orderId, userId }) => {
      if (quotesResult.data) {
        queryClient.setQueryData(
          'quotes',
          updateHistory(quotesResult.data, data, userId, orderId),
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('quotes');
    },
  });

  const {
    mutateAsync: declineQuoteMutation,
    isLoading: isLoadingDeclineQuote,
  } = useMutation(quoteService.declineQuote, {
    onSuccess: (data, { orderId, userId }) => {
      if (quotesResult.data) {
        queryClient.setQueryData(
          'quotes',
          updateHistory(quotesResult.data, data, userId, orderId),
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('quotes');
    },
  });

  const {
    mutateAsync: cancelCounterQuoteMutation,
    isLoading: isLoadingCancelQuote,
  } = useMutation(quoteService.cancelQuote, {
    onSuccess: (data, { userId, orderId }) => {
      if (quotesResult.data) {
        queryClient.setQueryData(
          'quotes',
          updateHistory(quotesResult.data, data, userId, orderId),
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('quotes');
    },
  });

  const {
    mutateAsync: completeQuoteMutation,
    isLoading: isLoadingCompleteQuote,
  } = useMutation(quoteService.markAsComplete, {
    onSuccess: (data, { userId, orderId }) => {
      if (quotesResult.data) {
        queryClient.setQueryData(
          'quotes',
          updateHistory(quotesResult.data, data, userId, orderId),
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('quotes');
    },
  });

  const {
    mutateAsync: discardQuoteMutation,
    isLoading: isLoadingDiscardQuote,
  } = useMutation(quoteService.discardQuote, {
    onSuccess: (_data, { orderId }) => {
      if (quotesResult.data) {
        queryClient.setQueryData(
          'quotes',
          quotesResult.data.filter((x) => x.orderId !== orderId),
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries('quotes');
    },
  });

  return {
    ...quotesResult,
    isCancelling: isLoadingCancelQuote,
    isAccepting: isLoadingAcceptQuote,
    isCountering: isLoadingCounterQuote,
    isDeclining: isLoadingDeclineQuote,
    isDiscarding: isLoadingDiscardQuote,
    isComplete: isLoadingCompleteQuote,
    acceptQuote: async (orderId: string, userId: string) => {
      await acceptQuoteMutation({ orderId, userId });
    },
    counterQuote: async (
      orderId: string,
      userId: string,
      amount: number,
      comment?: string,
    ) => {
      await counterQuoteMutation({ orderId, userId, amount, comment });
    },
    declineQuote: async (
      orderId: string,
      userId: string,
      doNotSendNotification: boolean,
      comment?: string,
    ) => {
      await declineQuoteMutation({
        orderId,
        userId,
        doNotSendNotification,
        comment,
      });
    },
    completeQuote: async (orderId: string, userId: string) => {
      await completeQuoteMutation({ orderId, userId });
    },
    cancelCounterQuote: async (
      orderId: string,
      userId: string,
      comment?: string,
    ) => {
      await cancelCounterQuoteMutation({ orderId, userId, comment });
    },
    discardQuote: async (orderId: string) => {
      await discardQuoteMutation({ orderId });
    },
    getQuoteByOrderId: (orderId?: string) => {
      return quotesResult.data?.find((x) => x.orderId === orderId);
    },
    getQuoteDataByOrderIdUserId: (orderId: string, userId: string) => {
      const foundQuote = quotesResult.data?.find((x) => x.orderId === orderId);
      return foundQuote?.quotes.find((x) => x.userId === userId);
    },
  };
};

export const useQuoteDataById = (orderId?: string) => {
  const { getQuoteByOrderId } = useQuoteData(null);

  const quote = getQuoteByOrderId(orderId);

  return { data: quote };
};

export const useQuoteDataByIdUserId = (orderId: string, userId: string) => {
  const {
    getQuoteDataByOrderIdUserId,
    counterQuote,
    acceptQuote,
    declineQuote,
    cancelCounterQuote,
    completeQuote,
    data,
    ...rest
  } = useQuoteData(null);

  const quoteData = getQuoteDataByOrderIdUserId(orderId, userId);

  return {
    data: quoteData,
    counterQuote: (amount: number, comment?: string) =>
      counterQuote(orderId, userId, amount, comment),
    acceptQuote: () => acceptQuote(orderId, userId),
    completeQuote: () => completeQuote(orderId, userId),
    declineQuote: (doNotSendNotification: boolean, comment?: string) =>
      declineQuote(orderId, userId, doNotSendNotification, comment),
    cancelCounterQuote: (comment?: string) =>
      cancelCounterQuote(orderId, userId, comment),
    ...rest,
  };
};
