import { useContext, createContext } from 'react';
import { initAbly } from 'http/ablyHttp';
import * as Ably from 'ably';

let ably: Ably.Types.RealtimePromise;

const AblyContext = createContext<any | undefined>(undefined);

export const AblyProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  if (!ably) {
    ably = initAbly();
  }

  return <AblyContext.Provider value={ably}>{children}</AblyContext.Provider>;
};

const useAbly = (): Ably.Types.RealtimePromise => {
  const context = useContext(AblyContext);
  if (context === undefined) {
    throw new Error('use ably must be used within an AblyProvider');
  }
  return context;
};

export default useAbly;
