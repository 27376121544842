import {
  RateViewApiResult,
  RateBody,
  RateCastApiResult,
  SonarScoreApiResult,
  SubmitRateBody,
  HubtekRequestBody,
  CustomerCodesApiResult,
  GreenscreenRequestBody,
  GreenscreenResponse,
  HubtekApiResponse,
} from './ratesTypes';
import Service from 'utils/Service';
import {
  API_BASE_URL_V2,
  REACT_APP_RATE_SERVICE_BASE_URL,
} from 'utils/constants';
import { AxiosResponse } from 'axios';

class RateProService extends Service {
  constructor() {
    super(API_BASE_URL_V2 as string);
  }

  async getDATRateview(body: RateBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<RateViewApiResult> = await this.post(
      `/rates/rateView`,
      body,
    );
    return response.data;
  }

  async getRateCast(body: RateBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<RateCastApiResult[]> = await this.post(
      `/rates/rateCast`,
      body,
    );
    return response.data;
  }

  async getRateSonarScore(body: RateBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<SonarScoreApiResult> = await this.post(
      `/rates/sonarScore`,
      body,
    );
    return response.data;
  }

  async submitRate(body: SubmitRateBody) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<{ rateId: string }> = await this.post(
      `/rates/submitRate`,
      body,
    );
    return response.data;
  }
}

class RateService extends Service {
  constructor() {
    super(REACT_APP_RATE_SERVICE_BASE_URL as string);
  }
  async getHubtekRate(body: HubtekRequestBody) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<HubtekApiResponse> = await this.post(
      `/rates/hubtek`,
      body,
    );
    return response.data;
  }

  async getCustomerCodes() {
    const response: AxiosResponse<CustomerCodesApiResult> =
      (await this.get(`/rates/customerCodes`)) || [];
    return response.data;
  }

  async getGreenscreenRTPrediction(body: GreenscreenRequestBody | undefined) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<GreenscreenResponse> = await this.post(
      `/rates/greenscreen/rtPrediction`,
      body,
    );
    return response.data;
  }

  async getGreenscreenNetworkPrediction(
    body: GreenscreenRequestBody | undefined,
  ) {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<GreenscreenResponse> = await this.post(
      `/rates/greenscreen/networkPrediction`,
      body,
    );
    return response.data;
  }
}

export const rateProService = new RateProService();
export const rateService = new RateService();
