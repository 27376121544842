import { REACT_APP_TRANSQUOTE_SERVICE_URL } from 'utils/constants';
import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

export interface DashboardUser {
  _id: string;
  fullName: string;
  email: string;
  pausedUser: boolean;
}

export interface InactiveUsers {
  _id: string;
  userEmail: string;
  assignTo: string;
  assignToId: string;
  createdAt: string;
}

export interface CAMUserStatus {
  userEmail: string;
  userId: string;
  assignTo: string;
  assignToId: string;
  isInactive: boolean;
}

export class TransQuoteService extends Service {
  constructor() {
    super(REACT_APP_TRANSQUOTE_SERVICE_URL as string);
  }

  async getUsersByPermissions(): Promise<DashboardUser[]> {
    const response: AxiosResponse<DashboardUser[]> = await this.get(
      '/admin/get-users-by-permissions',
    );
    return response.data;
  }

  async getCAMPausedUsers(): Promise<InactiveUsers[]> {
    const response: AxiosResponse<InactiveUsers[]> =
      await this.get('/admin/camUsersGet');
    return response.data;
  }

  async postCamUserStatus(body: CAMUserStatus): Promise<{ message: any }> {
    if (!body) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<{ message: any }> = await this.post(
      '/admin/CamUserSetStatus',
      { camUserBody: body },
    );
    return response.data;
  }
}

export const transQuoteService = new TransQuoteService();
