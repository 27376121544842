import { FC } from 'react';
import { styled, Button } from 'shamrock-clover-ui';
import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';
import { StyledIcon } from '../CarrierCapacityStyles';

const StyledButton = styled(Button)`
  margin-top: 18px;
`;

const SearchCapacityButton: FC<{ handleOnClick: () => void }> = ({
  handleOnClick,
}) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      deliveryCity,
      deliveryState,
      equipmentType,
    },
  } = useSearchCapacityQueryParams();

  return (
    <StyledButton
      width="64px"
      height="36px"
      isRounded={true}
      disabled={
        !(
          pickupCity &&
          pickupState &&
          deliveryCity &&
          deliveryState &&
          equipmentType
        )
      }
      onClick={handleOnClick}
    >
      <StyledIcon
        icon="search"
        color="white"
        disabled={
          !(
            pickupCity &&
            pickupState &&
            deliveryCity &&
            deliveryState &&
            equipmentType
          )
        }
      />
    </StyledButton>
  );
};

export default SearchCapacityButton;
