import { HttpMethods } from 'utils/httpUtils';
import {
  Carrier,
  EquipmentTypes,
  LaneAndCapacityForm,
  LaneObj,
  LanesAndCapacity,
  LoadDetailsType,
  RateType,
  SearchCapacityQuery,
} from '../CapacityTypes';
import { LANE_SERVICE_URL } from 'utils/constants';
import { getLocationCoordinates } from './capacityUtils';

import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

class LaneService extends Service {
  constructor() {
    super(LANE_SERVICE_URL as string);
  }

  async searchLanesCarrier(
    queryParams: SearchCapacityQuery,
  ): Promise<LanesAndCapacity[]> {
    const response: AxiosResponse<LanesAndCapacity[]> = await this.get(
      `/carrier/lanes`,
      { params: queryParams },
    );
    return response.data;
  }

  async carrierSearch(searchString: string): Promise<Carrier[]> {
    const queryString = `?q=${encodeURIComponent(searchString)}`;
    const response: AxiosResponse<Carrier[]> = await this.get(
      `/carriers${queryString}`,
    );
    return response.data;
  }

  async getCarrierLanes(mcNumber: string): Promise<LaneObj[]> {
    const response: AxiosResponse<LaneObj[]> = await this.get(
      `/carrier/${mcNumber}/lanes`,
    );
    return response.data;
  }

  async addLaneAndCapacity(lane: Partial<LaneObj>): Promise<LaneObj> {
    const response: AxiosResponse<LaneObj> = await this.post('/lane', lane);
    return response.data;
  }

  async editLaneAndCapacity(
    path: string,
    method: HttpMethods,
    body?: Partial<LaneObj>,
  ): Promise<unknown> {
    const response = await this.request(path, method, body);
    return response.data;
  }

  async buildLaneAndCapacityRequest(
    formObj: LaneAndCapacityForm,
    mcNumber?: string,
    mcleodCarrierId?: string,
    addCapacity?: boolean,
  ): Promise<Partial<LaneObj>> {
    const {
      pickup,
      locationPickup,
      delivery,
      locationDelivery,
      trailers,
      truckQuantity,
      loadsPerTimeFrame,
      timeFrame,
      trailerType,
      rateAmount,
      selectedRateType,
      additionalServices,
      commentValue,
    } = formObj;

    let pickupCoordinates: number[] = [];
    let deliveryCoordinates: number[] = [];
    // check if pickupType is city/zip
    if (
      pickup.optionSelected?.option === 'City' &&
      locationPickup.optionSelected?.optionName
    ) {
      // if it is, use google maps api to get the coordinates for the city/zip
      pickupCoordinates = await getLocationCoordinates(
        locationPickup.optionSelected.optionName,
      );
    }

    // check if deliveryType is city/zip
    if (
      delivery.optionSelected?.option === 'City' &&
      locationDelivery.optionSelected?.optionName
    ) {
      // if it is, use google maps api to get the coordinates for the city/zip
      deliveryCoordinates = await getLocationCoordinates(
        locationDelivery.optionSelected.optionName,
      );
    }

    // get selected trailers
    const selectedTrailers: EquipmentTypes[] = (trailers.multiSelect || [])
      .filter((x) => x.checked)
      .map((option) => option.text as EquipmentTypes);

    const AddLaneRequestBody = {
      lane: {
        mcNumber,
        mcleodCarrierId,
        pickupType: pickup.optionSelected?.option as 'City' | 'State',
        ...(pickup.optionSelected?.option === 'City' && {
          pickupCity: {
            cityName: locationPickup.optionSelected?.optionName,
            coordinates: pickupCoordinates,
            type: 'Point',
          },
        }),
        ...(pickup.optionSelected?.option === 'State' && {
          pickupState: locationPickup.optionSelected?.optionName,
        }),
        deliveryType: delivery.optionSelected?.option as 'City' | 'State',
        ...(delivery.optionSelected?.option === 'City' && {
          deliveryCity: {
            cityName: locationDelivery.optionSelected?.optionName,
            coordinates: deliveryCoordinates,
            type: 'Point',
          },
        }),
        ...(delivery.optionSelected?.option === 'State' && {
          deliveryState: locationDelivery.optionSelected?.optionName,
        }),
        trailerTypes: selectedTrailers,
      },
      // if the form has capacity, add the capacity to the request body
      ...(addCapacity && {
        capacity: {
          trucks: parseInt(truckQuantity?.optionSelected?.optionName || '0'),
          loadsPerTimeFrame: parseInt(
            loadsPerTimeFrame?.optionSelected?.optionName || '0',
          ),
          frequencyTimeFrame: timeFrame?.optionSelected?.optionName || '',
          ...(additionalServices?.multiSelect &&
            additionalServices?.multiSelect?.filter((x) => x.checked).length >
              0 && {
              loadDetailServices: additionalServices.multiSelect
                .filter((x) => x.checked)
                .map((x) => x.text as LoadDetailsType),
            }),
          ...(commentValue?.optionSelected?.optionName && {
            loadDetailNotes: commentValue?.optionSelected?.optionName,
          }),
          rate: [
            {
              trailerType: trailerType?.optionSelected
                ?.optionName as EquipmentTypes,
              ...(rateAmount?.optionSelected?.optionName &&
                rateAmount.optionSelected.optionName.length > 1 && {
                  amount:
                    (selectedRateType?.optionSelected
                      ?.optionName as RateType) === 'Flat rate'
                      ? parseInt(
                          rateAmount.optionSelected.optionName.replace(
                            /[^0-9]/g,
                            '',
                          ),
                        )
                      : Number(
                          rateAmount.optionSelected.optionName.replace(
                            /[^0-9.]/g,
                            '',
                          ),
                        ),
                }),
              rateType: selectedRateType?.optionSelected
                ?.optionName as RateType,
            },
          ],
        },
      }),
    };

    return AddLaneRequestBody;
  }
}

export const laneService = new LaneService();
