import { REACT_APP_CREDIT_SERVICE_BASE_URL } from 'utils/constants';
import {
  AdjustmentCreditRequestEmailData,
  CreditRequestApiResult,
  CreditRequestSearchQuery,
  NewCreditRequestEmailData,
} from './creditRequestTypes';
import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

class CreditRequestService extends Service {
  constructor() {
    super(REACT_APP_CREDIT_SERVICE_BASE_URL as string);
  }

  parseQuery(query: CreditRequestSearchQuery) {
    let buildQuery: string = '?';

    for (const key in query) {
      if (query[key as keyof CreditRequestSearchQuery] !== '') {
        buildQuery += `${key}=${query[key as keyof CreditRequestSearchQuery]}&`;
      }
    }
    return buildQuery;
  }

  async getCreditRequestData(
    query: CreditRequestSearchQuery,
  ): Promise<CreditRequestApiResult> {
    const queryString: string = this.parseQuery(query);
    const response: AxiosResponse<CreditRequestApiResult> = await this.get(
      `/logs${queryString}`,
    );
    return response.data;
  }

  async postNewCreditRequestEmail(
    data: NewCreditRequestEmailData,
  ): Promise<{ message: string }> {
    if (!data) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<{ message: string }> = await this.post(
      '/newEmail',
      { emailNewRequestBody: data },
    );
    return response.data;
  }

  async postAdjustmentCreditRequestEmail(
    data: AdjustmentCreditRequestEmailData,
  ): Promise<{ message: string }> {
    if (!data) return Promise.reject(new Error('Invalid request body'));
    const response: AxiosResponse<{ message: string }> = await this.post(
      '/adjustmentEmail',
      { emailAdjustmentRequestBody: data },
    );
    return response.data;
  }
}

export const creditRequestService = new CreditRequestService();
