import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { CurrentUser, getUserLocalStorage, isCAMUser } from 'utils/authUtils';
import { quoteService } from '../utils/quotesHttp';
import {
  CounterParams,
  DeclinedParams,
  InitialEnhancementsState,
  QuoteTypes,
  QuoteEventParams,
  TypeStatus,
} from '../utils/quoteTypes';
import { getStatusFromPath } from '../utils/quoteUtils';
import quoteEnhancementsReducer from './quoteEnhancementsReducer';
import useAbly from '../../../hooks/useAbly';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import {
  ABLY_QUOTE_CHANNEL,
  ABLY_QUOTE_CHANNEL_CAM_USER,
} from 'utils/constants';
import {
  transQuoteService,
  CAMUserStatus,
  DashboardUser,
  InactiveUsers,
} from '../utils/camUserServices';
import * as Ably from 'ably';

export interface QuoteContextType {
  quotes: InitialEnhancementsState;
  loading: boolean;
  loadingDrawer: boolean;
  loadingByFilter: boolean;
  loadingCountered: boolean;
  adminAssing: string;
  errorHandler: boolean;
  inactiveUsers: InactiveUsers[];
  selectedUsers: DashboardUser[];
  camUsers: DashboardUser[];
  selectedUser: string[];
  setErrorHandler: (error: boolean) => void;
  filterByUser: (types: QuoteTypes) => void;
  getQuote: (type: string) => void;
  adminQuote: (quoteId: string, orderId: string) => void;
  verifyQuote: (orderId: string, userId: string) => void;
  getCurrentUser: () => CurrentUser | null;
  counteredQuote: (counterParams: CounterParams) => void;
  declinedQuote: (declinedParams: DeclinedParams) => void;
  markQuoteAsComplete: (params: QuoteEventParams) => void;
  cancellAceeptance: (params: QuoteEventParams) => void;
  getStatusFromQuoteContext: (orderId: string) => TypeStatus | undefined;
  getUsersbyPermission: () => Promise<DashboardUser[]>;
  getPausedUsers: () => Promise<any>;
  setPausedUserStatus: (body: CAMUserStatus) => Promise<any>;
  setSelectedUser: Dispatch<SetStateAction<string[]>>;
  setSelectedUsers: Dispatch<DashboardUser[]>;
  setCamUsers: Dispatch<DashboardUser[]>;
  getUsers: (currentUser?: CurrentUser) => Promise<void>;
}

export const QuotesEnhancementsContext = createContext<QuoteContextType>(
  {} as QuoteContextType,
);

const QuotesEnhancementsWrapper: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { pathname, search } = useLocation();
  const initialState: InitialEnhancementsState = {
    new: [],
    negotiating: [],
    verifying: [],
    booked: [],
    dismissed: [],
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDrawer, setLoadingDrawer] = useState<boolean>(false);
  const [loadingCountered, setLoadingCountered] = useState<boolean>(false);
  const [loadingByFilter, setLoadingByFilter] = useState<boolean>(false);
  const [adminAssing, setAdminAssing] = useState<string>('');
  const [errorHandler, setErrorHandler] = useState<boolean>(false);
  const [inactiveUsers, setInactiveUsers] = useState<InactiveUsers[]>([]);
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<DashboardUser[]>([]);
  const [camUsers, setCamUsers] = useState<DashboardUser[]>([]);

  const [state, dispatch] = useReducer(quoteEnhancementsReducer, initialState);

  const getCurrentUser = () => getUserLocalStorage();
  const ably = useAbly();

  const { setPathAndQuery } = useQuotingQueryParams();

  const getPausedUsers = async () => {
    const users = await transQuoteService.getCAMPausedUsers();
    setInactiveUsers(users);
  };

  const setPausedUserStatus = async (body: CAMUserStatus) => {
    return await transQuoteService.postCamUserStatus(body).then(async () => {
      await getPausedUsers();
    });
  };

  useEffect(() => {
    getPausedUsers().catch(console.error);
  }, []);

  const getQuote = useCallback(
    async (status: string, adminAssigned?: string): Promise<void> => {
      const filter = adminAssigned ? { status, adminAssigned } : { status };
      try {
        setLoading(true);
        const result = await quoteService.getQuotes(filter);
        if (result.length >= 0) {
          setLoading(false);
        }
        dispatch({
          type: 'GET_QUOTE',
          payload: {
            status: status,
            quote: result,
          },
        });
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    [dispatch, setLoading],
  );

  const selectedUserRef = useRef(selectedUser);
  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  //getUsers for UserSelector
  const getUsers = async (currentUser?: CurrentUser) => {
    if (currentUser?.email) {
      filterByUser({
        types: ['new', 'negotiating', 'verifying', 'booked', 'dismissed'],
        adminAssigned: currentUser.email,
      });
      const camUsers = await getUsersbyPermission();
      const allUsersOption: DashboardUser = {
        _id: 'all',
        fullName: 'All Users',
        email: '',
        pausedUser: false,
      };
      const curUserInCamUsers = camUsers.filter(
        (x) => x._id === currentUser._id,
      );
      if (curUserInCamUsers.length > 0) {
        setSelectedUsers(curUserInCamUsers);
        setCamUsers([allUsersOption, ...camUsers]);
      } else {
        const _currentUser = [
          {
            _id: currentUser._id,
            fullName: `${currentUser.firstname} ${currentUser.lastname}`,
            email: currentUser.email,
            pausedUser: false,
          },
        ];
        setSelectedUsers(_currentUser);
        setCamUsers([allUsersOption, ..._currentUser, ...camUsers]);
      }
    }
  };

  const refreshSelector = async () => {
    const allUsersOption: DashboardUser = {
      _id: 'all',
      fullName: 'All Users',
      email: '',
      pausedUser: false,
    };
    const camUsers = await getUsersbyPermission();

    const findSelectedUsers = camUsers.filter((x) =>
      selectedUserRef.current.includes(x.email),
    );
    getPausedUsers();
    setCamUsers([allUsersOption, ...camUsers]);
    setSelectedUsers(findSelectedUsers);
  };

  const handleSubscription = useCallback((event: Ably.Types.Message) => {
    console.log('Event>>>>>>>>>>>>', event);
    console.log('selectedUser :>> ', selectedUserRef.current);

    if (!window.location.href.includes('/quotes')) {
      console.log('- Event Not processed -');
      return;
    }

    const getSelectedUsersAsString = selectedUserRef.current.join(',');
    if (event.data.data[0] === 'User Selector') {
      refreshSelector();
      return;
    }
    for (const type of event.data.type) {
      getQuote(type, getSelectedUsersAsString);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const sessionChannel = isCAMUser()
      ? ABLY_QUOTE_CHANNEL_CAM_USER
      : ABLY_QUOTE_CHANNEL;
    console.log('sessionChannel inside Ably UseEffect ', sessionChannel);
    const channel = ably.channels.get(sessionChannel as string);
    channel.subscribe((event) => {
      if (event) {
        handleSubscription(event);
      }
    });

    // eslint-disable-next-line
  }, [ably]);

  /**
   * AdminQuote Function, set the adminAssinged to a pending Quote
   * @param quoteId
   * @param orderId
   */
  const adminQuote = async (
    quoteId: string,
    orderId: string,
  ): Promise<void> => {
    // const channel = ably.channels.get(sessionChannel as string);
    setLoadingDrawer(true);
    if (state.negotiating.length === 0) {
      setLoading(true);
    }
    try {
      const getQuoteFromState: any = state.new.find(
        (x) => x.orderId === orderId,
      );
      const filterNewQuotes = state.new.filter((x) => x.orderId !== orderId);
      dispatch({
        type: 'GET_QUOTE',
        payload: {
          status: 'new',
          quote: filterNewQuotes,
        },
      });
      const result = await quoteService.setAdminQuote({ orderId });
      // channel.publish('User Assigned', { type: ['new'], data: result });
      getQuoteFromState.quotes[0].adminAssigned =
        result[result.length - 1].adminAssigned;

      setLoading(false);
      setLoadingDrawer(false);
      dispatch({
        type: 'SET_QUOTE',
        payload: {
          status: !result[result.length - 1].bookItRequest
            ? 'negotiating'
            : 'verifying',
          quote: [getQuoteFromState],
        },
      });
    } catch (error) {
      console.log('error from AdminQuote:>> ', error);
      setErrorHandler(true);
      setPathAndQuery('new');
      setLoading(false);
      setLoadingDrawer(false);
    }
  };

  /**
   * Refetch everytime we change from bucket
   */
  useEffect(() => {
    const type = getStatusFromPath(pathname);
    const assignedUser = selectedUser.join(',');
    if (search === '') {
      if (assignedUser !== '') {
        getQuote(type, assignedUser);
      } else {
        getQuote(type);
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  /**
   * VerifyQuote: call acceptQuote Function and pass the status quote to Approved
   * @param orderId
   * @param userId
   */
  const verifyQuote = async (
    orderId: string,
    userId: string,
  ): Promise<void> => {
    setLoadingDrawer(true);
    if (state.verifying.length === 0) {
      setLoading(true);
    }
    try {
      await quoteService.acceptQuote({ userId, orderId });

      await getQuote('verifying', adminAssing);
      await getQuote('negotiating', adminAssing);
      setLoadingDrawer(false);
      setLoading(false);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const counteredQuote = async (
    counterParams: CounterParams,
  ): Promise<void> => {
    setLoadingCountered(true);
    const statusType = getStatusFromPath(pathname);
    const { userId, orderId, amount, comment } = counterParams;
    try {
      await quoteService.counterQuote({
        userId,
        orderId,
        amount: parseInt(amount),
        comment,
      });
      await getQuote(statusType, adminAssing);
      await getQuote('negotiating', adminAssing);
      setLoadingCountered(false);
    } catch (error) {
      console.log('error counteredQuote:>> ', error);
    }
  };

  const declinedQuote = async (
    declinedParams: DeclinedParams,
  ): Promise<void> => {
    if (state.dismissed.length === 0) {
      setLoading(true);
    }
    try {
      setLoadingDrawer(true);

      await quoteService.declineQuote(declinedParams);
      await getQuote('negotiating', adminAssing);
      await getQuote('booked', adminAssing);
      await getQuote('verifying', adminAssing);
      await getQuote('dismissed', adminAssing);

      setLoadingDrawer(false);
      setLoading(false);
    } catch (error) {
      console.log('error declinedQuote:>> ', error);
    }
  };

  const filterByUser = useCallback(
    async (types: QuoteTypes): Promise<void> => {
      const usersSelected = types.adminAssigned?.split(',') as string[];
      if (types.types && types.adminAssigned) {
        setAdminAssing(types.adminAssigned);
        setSelectedUser(usersSelected);
        setLoadingByFilter(true);
        for (const type of types.types) {
          try {
            const getQuote = await quoteService.getQuotes({
              status: type,
              adminAssigned: types.adminAssigned,
            });
            dispatch({
              type: 'GET_QUOTE',
              payload: {
                status: type,
                quote: getQuote,
              },
            });
            setLoading(false);
          } catch (error) {
            console.log('error :>> ', error);
          }
        }
        setLoadingByFilter(false);
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [],
  );

  const markQuoteAsComplete = async (params: QuoteEventParams) => {
    if (state.booked.length === 0) {
      setLoading(true);
    }
    try {
      setLoadingDrawer(true);
      await quoteService.markAsComplete({
        userId: params.userId,
        orderId: params.orderId,
      });
      await getQuote('negotiating', adminAssing);
      await getQuote('booked', adminAssing);
      await getQuote('verifying', adminAssing);
      await getQuote('dismissed', adminAssing);

      setLoadingDrawer(false);
      setLoading(false);
    } catch (error) {
      console.log('error markQuoteAsComplete:>> ', error);
    }
  };

  const cancellAceeptance = async (params: QuoteEventParams): Promise<void> => {
    const { userId, orderId } = params;
    const currentUser = getUserLocalStorage();
    const statusType = getStatusFromPath(pathname);
    if (state.negotiating.length === 0) {
      setLoading(true);
    }

    try {
      const result = await quoteService.cancelQuote({
        userId,
        orderId,
      });
      const getQuoteFromState: any = state[
        statusType as keyof InitialEnhancementsState
      ].find((x) => x.orderId === orderId);
      const filterQuotes = state[
        statusType as keyof InitialEnhancementsState
      ].filter((x) => x.orderId !== orderId);

      dispatch({
        type: 'GET_QUOTES',
        payload: {
          status: statusType,
          quote: filterQuotes,
        },
      });

      getQuoteFromState.quotes[0].history = [
        ...getQuoteFromState.quotes[0].history,
        result,
      ];
      getQuoteFromState.quotes[0].adminAssigned =
        currentUser?.email || getQuoteFromState.quotes[0].adminAssigned;

      dispatch({
        type: 'SET_QUOTE',
        payload: {
          status: 'negotiating',
          quote: [getQuoteFromState],
        },
      });

      setLoadingDrawer(false);
      setLoading(false);
    } catch (error) {
      console.log('error cancellAceeptance:>> ', error);
    }
  };

  const getStatusFromQuoteContext = (
    orderId: string,
  ): TypeStatus | undefined => {
    const quotes: InitialEnhancementsState = state;
    if (quotes.new.some((x) => x.orderId === orderId)) {
      return 'new';
    } else if (quotes.negotiating.some((x) => x.orderId === orderId)) {
      return 'negotiating';
    } else if (quotes.verifying.some((x) => x.orderId === orderId)) {
      return 'verifying';
    } else if (quotes.booked.some((x) => x.orderId === orderId)) {
      return 'booked';
    } else if (quotes.dismissed.some((x) => x.orderId === orderId)) {
      return 'dismissed';
    }
  };

  const getUsersbyPermission = async (): Promise<DashboardUser[]> => {
    const users = await transQuoteService.getUsersByPermissions();
    return users;
  };

  return (
    <QuotesEnhancementsContext.Provider
      value={{
        quotes: state,
        loading,
        loadingDrawer,
        loadingByFilter,
        loadingCountered,
        adminAssing,
        errorHandler,
        inactiveUsers,
        selectedUsers,
        selectedUser,
        camUsers,
        setErrorHandler,
        getQuote,
        adminQuote,
        verifyQuote,
        getCurrentUser,
        filterByUser,
        counteredQuote,
        declinedQuote,
        markQuoteAsComplete,
        cancellAceeptance,
        getStatusFromQuoteContext,
        getUsersbyPermission,
        getPausedUsers,
        setPausedUserStatus,
        setSelectedUser,
        setSelectedUsers,
        setCamUsers,
        getUsers,
      }}
    >
      {children}
    </QuotesEnhancementsContext.Provider>
  );
};

export default QuotesEnhancementsWrapper;
