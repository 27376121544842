import * as React from 'react';
import {
  RegionTitleText,
  StyledCard,
  CardContainer,
  EquipmentContainer,
} from '../rateAdjustmentStyles';

import EquipmentCard from './EquipmentCard';
import EquipmentHistory from './EquipmentHistory';

interface Props {
  title: string;
}

const EquipmentRow: React.FC<React.PropsWithChildren<Props>> = ({ title }) => {
  return (
    <StyledCard height="auto" borderBottom="1px solid #CCCCCC">
      <CardContainer flexDirection="column">
        <RegionTitleText
          fontSize="24px"
          lineHeight="29px"
          width="450px"
          height="29px"
          padding="0px"
        >
          {`${title} Loads`}
        </RegionTitleText>
        <EquipmentContainer flexDirection="row" flexWrap="wrap">
          <EquipmentCard trailerType={title} datRateValue="LOW" />
          <EquipmentCard trailerType={title} datRateValue="AVERAGE" />
          <EquipmentCard trailerType={title} datRateValue="HIGH" />
          <EquipmentHistory
            trailerType={title.toLowerCase() as 'van' | 'reefer'}
          />
        </EquipmentContainer>
      </CardContainer>
    </StyledCard>
  );
};

export default EquipmentRow;
