import * as React from 'react';

import {
  StyledEquipmentCard,
  StyledEquipmentCardHeader,
  StyleDatText,
  StyledEquipmentCardContainer,
  StyledSonarContainer,
  StyledSonarFormControl,
  SonarSeperator,
  StyledMenuItem,
} from '../rateAdjustmentStyles';

import { Select } from '@material-ui/core';
import { useRateAdjustmentQueryParams } from '../../../hooks/useQueryParams';

interface Props {
  datRateValue: string;
  trailerType: string;
}

const items = ['-', '-3', '-2', '-1', '1', '2', '3'];

const EquipmentCard: React.FC<React.PropsWithChildren<Props>> = ({
  datRateValue,
  trailerType,
}) => {
  const { addQueryParams, queryParams } = useRateAdjustmentQueryParams();

  const getParamName = (
    suffix: 'Beginning' | 'End',
  ):
    | 'averageReeferSonarBeginning'
    | 'averageReeferSonarEnd'
    | 'highReeferSonarBeginning'
    | 'highReeferSonarEnd'
    | 'lowVanSonarBeginning'
    | 'lowVanSonarEnd'
    | 'averageVanSonarBeginning'
    | 'averageVanSonarEnd'
    | 'highVanSonarBeginning'
    | 'highVanSonarEnd'
    | 'lowReeferSonarBeginning'
    | 'lowReeferSonarEnd' => {
    if (trailerType.toLowerCase() === 'van') {
      if (datRateValue.toLowerCase() === 'low') {
        return `lowVanSonar${suffix}`;
      } else if (datRateValue.toLowerCase() === 'average') {
        return `averageVanSonar${suffix}`;
      } else {
        return `highVanSonar${suffix}`;
      }
    } else {
      if (datRateValue.toLowerCase() === 'low') {
        return `lowReeferSonar${suffix}`;
      } else if (datRateValue.toLowerCase() === 'average') {
        return `averageReeferSonar${suffix}`;
      } else {
        return `highReeferSonar${suffix}`;
      }
    }
  };

  const sonoarBeginning = queryParams[getParamName('Beginning')]
    ? queryParams[getParamName('Beginning')]
    : '';

  const sonoarEnd = queryParams[getParamName('End')]
    ? queryParams[getParamName('End')]
    : '';

  const disableItem = (sonarScore: string) => {
    if (trailerType.toLowerCase() === 'van') {
      if (queryParams.lowVanSonarBeginning && queryParams.lowVanSonarEnd) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.lowVanSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.lowVanSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'low') {
            return false;
          }
          return true;
        }
        if (
          datRateValue.toLowerCase() !== 'low' &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.lowVanSonarBeginning as string)
        ) {
          return true;
        }
      }
      if (
        queryParams.averageVanSonarBeginning &&
        queryParams.averageVanSonarEnd
      ) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.averageVanSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.averageVanSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'average') {
            return false;
          }
          return true;
        } else {
          if (
            datRateValue.toLowerCase() !== 'average' &&
            parseFloat(sonarScore) >=
              parseFloat(queryParams.lowVanSonarEnd as string) &&
            parseFloat(sonarScore) <=
              parseFloat(queryParams.averageVanSonarEnd as string)
          ) {
            return true;
          }
        }
      }
      if (queryParams.highVanSonarBeginning && queryParams.highVanSonarEnd) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.highVanSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.highVanSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'high') {
            return false;
          }
          return true;
        }
      }
    } else if (trailerType.toLowerCase() === 'reefer') {
      if (
        queryParams.lowReeferSonarBeginning &&
        queryParams.lowReeferSonarEnd
      ) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.lowReeferSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.lowReeferSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'low') {
            return false;
          }
          return true;
        }
        if (
          datRateValue.toLowerCase() !== 'low' &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.lowReeferSonarBeginning as string)
        ) {
          return true;
        }
      }
      if (
        queryParams.averageReeferSonarBeginning &&
        queryParams.averageReeferSonarEnd
      ) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.averageReeferSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.averageReeferSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'average') {
            return false;
          }
          return true;
        } else {
          if (
            datRateValue.toLowerCase() !== 'average' &&
            parseFloat(sonarScore) >=
              parseFloat(queryParams.lowReeferSonarEnd as string) &&
            parseFloat(sonarScore) <=
              parseFloat(queryParams.averageReeferSonarEnd as string)
          ) {
            return true;
          }
        }
      }
      if (
        queryParams.highReeferSonarBeginning &&
        queryParams.highReeferSonarEnd
      ) {
        if (
          parseFloat(sonarScore) >=
            parseFloat(queryParams.highReeferSonarBeginning as string) &&
          parseFloat(sonarScore) <=
            parseFloat(queryParams.highReeferSonarEnd as string)
        ) {
          if (datRateValue.toLowerCase() === 'high') {
            return false;
          }
          return true;
        }
      }
    }
    return false;
  };

  const getOptions = () => {
    return items.map((e) => {
      return (
        <StyledMenuItem disabled={disableItem(e)} value={e}>
          {e}
        </StyledMenuItem>
      );
    });
  };

  const onChangeBeginning = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const param: any = {};
    param[getParamName('Beginning')] = event.target.value as string;
    addQueryParams(param);
  };

  const onChangeEnd = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    const param: any = {};
    param[getParamName('End')] = event.target.value as string;
    addQueryParams(param);
  };

  return (
    <StyledEquipmentCard width={'324px'} height={'156px'}>
      <StyledEquipmentCardHeader>
        <StyleDatText
          marginLeft="16px"
          fontWeight="400"
          marginTop="0px"
          padding="12px 0px 12px 0px"
        >
          DAT RATE:
        </StyleDatText>
        <StyleDatText marginLeft="4px" fontWeight="800" marginTop="0px">
          {datRateValue}
        </StyleDatText>
      </StyledEquipmentCardHeader>
      <StyledEquipmentCardContainer flexDirection="column">
        <StyleDatText marginLeft="16px" fontWeight="800" marginTop="0px">
          SONAR SCORE
        </StyleDatText>
        <StyledSonarContainer>
          <StyleDatText marginLeft="16px" fontWeight="400" marginTop="25px">
            Between
          </StyleDatText>
          <StyledSonarFormControl size="small">
            <Select
              id="sonar-simple-select"
              MenuProps={{ variant: 'menu' }}
              onChange={onChangeBeginning}
              value={sonoarBeginning}
              disableUnderline
            >
              {getOptions()}
            </Select>
            <SonarSeperator width="90px" />
          </StyledSonarFormControl>
          <StyleDatText marginLeft="16px" fontWeight="400" marginTop="25px">
            and
          </StyleDatText>
          <StyledSonarFormControl size="small">
            <Select
              id="sonar-simple-select"
              MenuProps={{ variant: 'menu' }}
              onChange={onChangeEnd}
              value={sonoarEnd}
              disableUnderline
            >
              {getOptions()}
            </Select>
            <SonarSeperator width="90px" />
          </StyledSonarFormControl>
        </StyledSonarContainer>
      </StyledEquipmentCardContainer>
    </StyledEquipmentCard>
  );
};

export default EquipmentCard;
