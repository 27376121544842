import { RtsLoadHistoryApiResponse } from '../types';
import { REACT_APP_RTS_HISTORY_BASE_URL } from 'utils/constants';
import {
  NewRateQuery,
  TrailerType,
  RateHistoryQueryParams,
} from 'features/ratePro/ratesTypes';

import Service from 'utils/Service';
import type { AxiosResponse } from 'axios';

class LoadHistoryService extends Service {
  constructor() {
    super(REACT_APP_RTS_HISTORY_BASE_URL as string);
  }

  async getRtsLoadHistory(
    searchQuery: NewRateQuery,
  ): Promise<RtsLoadHistoryApiResponse> {
    if (
      !searchQuery ||
      !searchQuery.pickupCity ||
      !searchQuery.pickupState ||
      !searchQuery.deliveryCity ||
      !searchQuery.deliveryState ||
      !searchQuery.trailerType
    ) {
      return Promise.reject('missing search query params');
    }

    const url = `/rates/history`;
    const requestParams: RateHistoryQueryParams = {
      originCity: searchQuery.pickupCity || '',
      originState: searchQuery.pickupState || '',
      destinationCity: searchQuery.deliveryCity || '',
      destinationState: searchQuery.deliveryState || '',
      trailerType: searchQuery.trailerType || TrailerType.VAN,
    };
    const response: AxiosResponse<RtsLoadHistoryApiResponse> = await this.get(
      url,
      { params: requestParams },
    );
    return response.data;
  }
}

export const loadHistoryService = new LoadHistoryService();
