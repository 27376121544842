import { useContext } from 'react';
import { Flex, FlexItem } from 'components/Flex';
import { useQuotingQueryParams } from 'hooks/useQueryParams';
import { styled } from 'shamrock-clover-ui';
import {
  formatWithTimezone,
  monthDayTimeFormat,
  timeFormat,
} from 'utils/dateUtils';
import Icon from 'components/Icon';
import { Quote, QuoteData } from '../utils/quoteTypes';
import * as FirebaseUtils from 'utils/firebaseUtils';

import {
  currentAndPreviousOffer,
  getBookedHistoryItem,
  getTimerDisplay,
  isCarrierDeclined,
  reverseHistory,
} from '../utils/quoteUtils';

import { QuotesEnhancementsContext } from '../context/QuotesEnhancementsContext';
import QuoteCardHeader from './QuoteCardHeader';
import { useBookedLoadsAlertFeatureFlag } from 'hooks/useFeatureFlag';

const QuoteCardContainer = styled(Flex)<{ isSelected: boolean }>`
  border-radius: 2px;
  background-color: ${({ isSelected }) => (isSelected ? '#EEEEEE' : '#ffffff')};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  min-height: auto;
`;

const PrimaryText = styled.div`
  color: ${({ theme }) => theme.colors.gray[80]};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

const SecondaryText = styled.div`
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: ${({ theme }) => theme.font.size.small};
`;

const CardStatusContainer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[40]};
  padding-top: 10px;
`;

const BookedText = styled.div`
  color: ${({ theme }) => theme.colors.blue[50]};
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

const ExtraSmallGrayText = styled.div`
  color: ${({ theme }) => theme.colors.gray[60]};
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

const BookedByText = styled.div`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.xxsmall};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[40]};
  height: 10px;
  margin: 0px 7.5px;
  width: 1px;
`;

const OrderStatusText = styled.span`
  color: ${({ theme }) => theme.colors.gray[90]};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

interface QuoteCardProps {
  quote: Quote;
  type: string;
}

export const getOrderStatus = (status: string) => {
  if (status === 'A') {
    return 'AVAILABLE';
  }
  if (status === 'C') {
    return 'COVERED';
  }
  if (status === 'P') {
    return 'PROGRESS';
  }
  if (status === 'V') {
    return 'VOID';
  }
  if (status === 'D') {
    return 'DELIVERED';
  }
  return 'UNKNOWN STATUS';
};

export const BookedQuoteStatus = ({ quotes }: { quotes: QuoteData[] }) => {
  const bookedHistoryItem = getBookedHistoryItem(quotes);

  return bookedHistoryItem ? (
    <CardStatusContainer
      justifyContent="space-between"
      margin="auto 0px 0px 0px"
    >
      <Flex alignItems="center">
        <BookedText>
          Booked {formatWithTimezone(bookedHistoryItem.createdAt)}
        </BookedText>
        <Divider />
        <BookedText>${bookedHistoryItem.amount.toLocaleString()}</BookedText>
      </Flex>
      <BookedByText>Booked By: {bookedHistoryItem.adminUser}</BookedByText>
    </CardStatusContainer>
  ) : null;
};

const QuoteCard: React.FunctionComponent<
  React.PropsWithChildren<QuoteCardProps>
> = ({ quote, type }) => {
  const {
    setQueryParams,
    setPathAndQuery,
    queryParams: { orderId, quoteId },
  } = useQuotingQueryParams();
  const origin = quote.stops[0];
  const destination = quote.stops[quote.stops.length - 1];

  const {
    adminQuote,
    getCurrentUser,
    declinedQuote,
    getStatusFromQuoteContext,
    inactiveUsers,
  } = useContext(QuotesEnhancementsContext);

  const currentUser = getCurrentUser();

  const { isBookedLoadsAlertEnabled } = useBookedLoadsAlertFeatureFlag();

  const carrierCancelled =
    (isCarrierDeclined(quote.quotes[0].history) && type !== 'dismissed') ||
    quote.quotes[0].removeQuote;

  const adminUserEmail = quote.quotes[quote.quotes.length - 1].adminAssigned;

  const onClick = (id: string) => {
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.DETAIL_CLICK,
      FirebaseUtils.FirebaseModules.QUOTING_ADMIN,
      FirebaseUtils.FirebasePages.LIST,
      {
        orderNumber: id,
        status: getStatusFromQuoteContext(quote.orderId),
        description: 'View Quote',
        adminUser: adminUserEmail || 'Unassigned',
        timer: getTimerDisplay(quote.quotes[0].history),
      },
    );
    const nextStatus = !quote.quotes[0].bookItRequest
      ? 'negotiating'
      : 'verifying';
    if (type === 'new') {
      const quoteId = quote.quotes[0]._id;
      const idTouched = quote.orderId;
      adminQuote(quoteId, idTouched);
      setPathAndQuery(nextStatus, { orderId: id });
    }
    const quoteId = quote.quotes[0]._id;
    const idTouched = quote.orderId;
    setQueryParams({ orderId: idTouched, quoteId });
  };

  const reverse = reverseHistory(quote.quotes[0].history);

  const historyOffer = currentAndPreviousOffer(reverse);
  return (
    <>
      <Flex justifyContent="space-between">
        <QuoteCardHeader
          adminAssigned={
            adminUserEmail === currentUser?.email
              ? `${currentUser.firstname!} ${currentUser.lastname!}`
              : adminUserEmail || 'Unassigned'
          }
          cancelledButton={carrierCancelled}
          orderId={quote.orderId}
          declineQuote={declinedQuote}
          quote={quote}
          inactiveAssignedUser={
            inactiveUsers.some(
              (inactive) => inactive.userEmail === adminUserEmail,
            ) || false
          }
        />
      </Flex>
      <QuoteCardContainer
        margin="0px 0px 16px 0px"
        padding="8px 16px"
        flexDirection="column"
        isSelected={
          orderId === quote.orderId && quote.quotes[0]._id === quoteId
        }
        onClick={() => onClick(quote.orderId)}
      >
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" margin="0px 0px 10px 0px">
            <PrimaryText>ORDER {quote.orderId}</PrimaryText>
            <SecondaryText>
              {quote.customerId} - {quote.customerName}
            </SecondaryText>
          </Flex>
          <OrderStatusText>{getOrderStatus(quote.status)}</OrderStatusText>
        </Flex>
        <Flex>
          <FlexItem flex={2} flexDirection="column" margin="0px 0px 10px 0px">
            <PrimaryText>
              {origin.city}, {origin.state} {origin.zip}
            </PrimaryText>
            <SecondaryText>
              {monthDayTimeFormat(origin.scheduledArriveEarly)}
              {origin.scheduledArriveLate
                ? `-${timeFormat(
                    origin.scheduledArriveLate,
                    origin.scheduledArriveEarly,
                  )}`
                : ''}
            </SecondaryText>
          </FlexItem>
          <FlexItem height="20px" padding="0px 5px 0px 0px">
            <Icon name="DestinationArrow" alt="right arrow" />
          </FlexItem>
          <FlexItem flex={2} flexDirection="column" margin="0px 0px 10px 0px">
            <PrimaryText>
              {destination.city}, {destination.state} {destination.zip}
            </PrimaryText>
            <SecondaryText>
              {monthDayTimeFormat(destination.scheduledArriveEarly)}
              {destination.scheduledArriveLate
                ? `-${timeFormat(
                    destination.scheduledArriveLate,
                    destination.scheduledArriveEarly,
                  )}`
                : ''}
            </SecondaryText>
          </FlexItem>
        </Flex>
        {type === 'new' && historyOffer.previouslyOffer.length !== 0 ? (
          <>
            <CardStatusContainer
              justifyContent="space-between"
              margin="auto 0px 0px 0px"
            >
              <Flex>
                <BookedText>Previously Offered</BookedText>
              </Flex>
            </CardStatusContainer>
          </>
        ) : null}
        {type !== 'new' && type !== 'booked' && type !== 'dismissed' ? (
          <>
            <CardStatusContainer
              justifyContent="space-between"
              margin="auto 0px 0px 0px"
            >
              <Flex>
                {historyOffer.currentOffer[0].adminUser === '' ||
                (isBookedLoadsAlertEnabled &&
                  historyOffer.currentOffer[0].isInternalUser &&
                  historyOffer.currentOffer[0].status === 'approved') ? (
                  <BookedText>Action Required</BookedText>
                ) : (
                  <ExtraSmallGrayText>Waiting on Carrier</ExtraSmallGrayText>
                )}
              </Flex>
            </CardStatusContainer>
          </>
        ) : null}

        {type === 'booked' && <BookedQuoteStatus quotes={quote.quotes} />}
      </QuoteCardContainer>
    </>
  );
};

export default QuoteCard;
